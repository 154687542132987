import { useTheme } from "@mui/material/styles"
import ErrorRounded from "@mui/icons-material/ErrorRounded"
import WarningRounded from "@mui/icons-material/WarningRounded"
import InfoRounded from "@mui/icons-material/InfoRounded"
import FlagRounded from "@mui/icons-material/FlagRounded"
import OutlinedFlagRounded from "@mui/icons-material/OutlinedFlagRounded"

import { t } from "@lingui/macro"

import { WarningFlags } from "src/views/community-overview/types"
import { CommunitiesOverviewViewStore } from "src/views/community-overview/store"
import { useStore } from "src/store/lib/useStore"
import { convertHexToRgba } from "src/helpers/convertHexToRgba"

const useWarningFlags = (): WarningFlags => {
    const store = useStore(CommunitiesOverviewViewStore)
    const { palette } = useTheme()

    return {
        security_risk: {
            icon: <ErrorRounded />,
            color: palette.error.main,
            lightColor: convertHexToRgba(palette.error.main),
            value: store.totalFlags?.security_risk ?? 0,
            label: t`community.flags.security_risk`,
        },
        community_guidelines_violation: {
            icon: <WarningRounded />,
            color: palette.utility.clementine.main,
            lightColor: convertHexToRgba(palette.utility.clementine.main),
            value: store.totalFlags?.community_guidelines_violation ?? 0,
            label: t`community.flags.community_guidelines_violation`,
        },
        needs_admin_attention: {
            icon: <InfoRounded />,
            color: palette.info.main,
            lightColor: convertHexToRgba(palette.info.main),
            value: store.totalFlags?.needs_admin_attention ?? 0,
            label: t`community.flags.needs_admin_attention`,
        },
        reported_by_admin: {
            icon: <FlagRounded />,
            color: palette.error.main,
            lightColor: palette.common.white,
            value: store.totalFlags?.reported_by_admin ?? 0,
            label: t`community.flags.reported_by_admin`,
        },
        reported_by_tenant: {
            icon: <OutlinedFlagRounded />,
            color: palette.utility.clementine.main,
            lightColor: palette.common.white,
            value: store.totalFlags?.reported_by_tenant ?? 0,
            label: t`community.flags.reported_by_tenant`,
        },
    }
}

export default useWarningFlags
