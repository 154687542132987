import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback } from "react"
import { Stack, Typography } from "@mui/material"

import { FormFields } from "src/modals/navigation-items-detail/components/formFields"
import { FormFieldSet } from "src/components/FormFieldSet"
import { SwitchInput } from "src/components/Inputs"
import AssetLibraryPicker from "src/components/AssetLibraryPicker"
import { useStore } from "src/store/lib/useStore"
import { NavigationItemDetailStore } from "src/modals/navigation-items-detail/store"
import { AccessGroup } from "src/components/ModalAccessGroup/AccessGroup"
import { persistFiles } from "src/lib/file"
import { SETTINGS_READ_GUIDELINES } from "src/modals/navigation-items-detail/constants"

import { IContentTypes } from "src/modals/navigation-items-detail/types/settingsSectionProps"

const contentTypes: IContentTypes[] = [
    { id: "module", label: "Module" },
    { id: "embed", label: "Embed" },
]

export const SettingsSection = observer(() => {
    const navDetailStore = useStore(NavigationItemDetailStore)

    const handleIconChange = useCallback(
        async (images: IFile[]) => {
            const icon = images[0]
            if (Object.keys(icon).length > 0) {
                const images = await persistFiles([icon as ILocalFile], "image")

                if (images.length > 0) {
                    navDetailStore.form.set("iconUrl", images[0].url)
                }
            }
        },
        [navDetailStore.form],
    )

    const handleIconDelete = useCallback(() => {
        navDetailStore.form.set("iconUrl", "")
    }, [navDetailStore.form])

    return (
        <Stack spacing={2}>
            <AccessGroup
                accessGroupId={navDetailStore.accessGroupId as number}
                errorMessage={navDetailStore.form.error("accessGroupID") ?? ""}
                disabled={navDetailStore.isEditMode}
            />
            <FormFields.NavigationItemTextInput
                label={t`navigation-items-view.setting-section.internal-name`}
                key={"internalName"}
                rowLength={1}
                disabled={navDetailStore.isReadOnly}
                maxLength={255}
                value={navDetailStore.form.get("internalName")}
                helperText={navDetailStore.form.error("internalName") ?? ""}
                handleTextInputChange={(event) => {
                    navDetailStore.form.set("internalName", event.target.value)
                }}
            />
            <FormFields.NavigationItemDropdown
                optionArray={contentTypes}
                selectedValue={navDetailStore.form.get("entity")}
                onSelectionChange={(value) => {
                    navDetailStore.form.set("entity", `${value}`)
                    navDetailStore.form.set("entityID", 0)
                    void navDetailStore.featureTargetList(
                        navDetailStore.accessGroupId,
                    )
                }}
                errorMessage={navDetailStore.form.error("entity") ?? ""}
                disabled={navDetailStore.isReadOnly}
                label={t`navigation-items-view.setting-section.content-type`}
            />
            {navDetailStore.form.get("entity") !== "" && (
                <FormFields.NavigationItemDropdown
                    optionArray={navDetailStore.featureTargetLists}
                    selectedValue={navDetailStore.form.get("entityID")}
                    onSelectionChange={(value) =>
                        navDetailStore.form.set("entityID", Number(value))
                    }
                    errorMessage={
                        Boolean(navDetailStore.form.get("entityID"))
                            ? navDetailStore.form.error("entityID") ?? ""
                            : t`errors.required`
                    }
                    disabled={navDetailStore.isReadOnly}
                    label={t`navigation-items-view.setting-section.target-feature`}
                />
            )}
            <FormFieldSet
                header={t`navigation-items-view.setting-section.where-should-displayed`}
            >
                <SwitchInput
                    checked={navDetailStore.form.get("showInMainNavigation")}
                    label={t`navigation-items-view.setting-section.navigation`}
                    onSwitchChange={(value) => {
                        navDetailStore.form.set("showInMainNavigation", value)
                    }}
                    disabled={navDetailStore.isReadOnly}
                />
                <br />
                <SwitchInput
                    checked={navDetailStore.form.get("showInOverview")}
                    label={t`navigation-items-view.setting-section.overview`}
                    onSwitchChange={(value) => {
                        navDetailStore.form.set("showInOverview", value)
                    }}
                    disabled={navDetailStore.isReadOnly}
                />
            </FormFieldSet>
            <Typography variant="h6">{t`navigation-items-view.setting-section.svg-icon`}</Typography>
            <FormFieldSet>
                <Typography>
                    {t`navigation-items-view.setting-section.upload-icon-text`}{" "}
                    <FormFields.LinkGuideline
                        link={SETTINGS_READ_GUIDELINES}
                        text={t`navigation-items-view.setting-section.read-guidelines`}
                    />
                </Typography>
                <br />
                <AssetLibraryPicker
                    value={navDetailStore.form.get("iconUrl")}
                    onIconDelete={() => handleIconDelete()}
                    onIconChange={(images: IFile[]) => handleIconChange(images)}
                    errorMessage={navDetailStore.form.error("iconUrl") ?? ""}
                    acceptedFormats={["image/svg+xml"]}
                    setErrorMessage={(error) =>
                        navDetailStore.form.setError("iconUrl", error)
                    }
                    aspectRatio="1/1"
                    disabled={navDetailStore.isReadOnly}
                    activeAssetType={"icons"}
                />
            </FormFieldSet>
        </Stack>
    )
})
