/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { batch_CommunitiesAndTimePeriodRequest } from "../models/batch_CommunitiesAndTimePeriodRequest"
import type { batch_CommunitiesRequest } from "../models/batch_CommunitiesRequest"
import type { batch_CommunityScore } from "../models/batch_CommunityScore"
import type { batch_CountResponse } from "../models/batch_CountResponse"
import type { batch_GetFlagsResponse } from "../models/batch_GetFlagsResponse"
import type { batch_RateResponse } from "../models/batch_RateResponse"
import type { batch_TimestampResponse } from "../models/batch_TimestampResponse"
import type { communityinsights_GetTopicsResponse } from "../models/communityinsights_GetTopicsResponse"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class CommunityInsightsAdminService {
    /**
     * Get monthly scores by community for a given topic
     * Returns the monthly scores for the given communities and time period
     * @returns batch_CommunityScore OK
     * @throws ApiError
     */
    public static postV1AdminCommunityInsightsInsightsMonthlyCommunityScores({
        request,
    }: {
        /** communities: [optional], end_date: [optional, default: time.now()]. Timeformat: RFC3339:2006-01-02T15:04:05Z07:00 **/
        request: batch_CommunitiesAndTimePeriodRequest
    }): CancelablePromise<Array<batch_CommunityScore>> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community-insights/insights/monthly-community-scores",
            body: request,
        })
    }

    /**
     * Get community topics for property owner
     * Returns the community topics that the property owner has access to
     * @returns communityinsights_GetTopicsResponse OK
     * @throws ApiError
     */
    public static getV1AdminCommunityInsightsInsightsTopic(): CancelablePromise<communityinsights_GetTopicsResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/community-insights/insights/topic",
        })
    }

    /**
     * Get engagement rate for the given communities and time period
     * Returns an engagement rate (= nbr engaged tenants/total nbr of tenants) for the given communities and time period
     * @returns batch_RateResponse OK
     * @throws ApiError
     */
    public static postV1AdminCommunityInsightsOverviewEngagementRate({
        request,
    }: {
        /** communities: [optional], end_date: [optional, default: time.now()]. Timeformat: RFC3339:2006-01-02T15:04:05Z07:00 **/
        request: batch_CommunitiesAndTimePeriodRequest
    }): CancelablePromise<batch_RateResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community-insights/overview/engagement-rate",
            body: request,
        })
    }

    /**
     * Get latest admin activity in communities
     * Returns the latest admin activity for the given communities
     * @returns batch_TimestampResponse OK
     * @throws ApiError
     */
    public static postV1AdminCommunityInsightsOverviewLatestAdminActivity({
        request,
    }: {
        /** communities: [optional] **/
        request: batch_CommunitiesRequest
    }): CancelablePromise<batch_TimestampResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community-insights/overview/latest-admin-activity",
            body: request,
        })
    }

    /**
     * Get latest tenant activity in communities
     * Returns the latest tenant activity for the given communities
     * @returns batch_TimestampResponse OK
     * @throws ApiError
     */
    public static postV1AdminCommunityInsightsOverviewLatestTenantActivity({
        request,
    }: {
        /** communities: [optional] **/
        request: batch_CommunitiesRequest
    }): CancelablePromise<batch_TimestampResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community-insights/overview/latest-tenant-activity",
            body: request,
        })
    }

    /**
     * Get created comments for communities and time period
     * Returns the total number of comments created for the given communities and time period.
     * @returns batch_CountResponse OK
     * @throws ApiError
     */
    public static postV1AdminCommunityInsightsOverviewNbrComments({
        request,
    }: {
        /** communities: [optional], end_date: [optional, default: time.now()]. Timeformat: RFC3339:2006-01-02T15:04:05Z07:00 **/
        request: batch_CommunitiesAndTimePeriodRequest
    }): CancelablePromise<batch_CountResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community-insights/overview/nbr-comments",
            body: request,
        })
    }

    /**
     * Get the flag distribution for the given communities and time period
     * Returns a list where each list represents a flag distribution for a community
     * @returns batch_GetFlagsResponse OK
     * @throws ApiError
     */
    public static postV1AdminCommunityInsightsOverviewNbrFlags({
        request,
    }: {
        /** communities: [optional], end_date: [optional, default: time.now()]. Timeformat: RFC3339:2006-01-02T15:04:05Z07:00 **/
        request: batch_CommunitiesAndTimePeriodRequest
    }): CancelablePromise<batch_GetFlagsResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community-insights/overview/nbr-flags",
            body: request,
        })
    }

    /**
     * Get created posts for communities and time period
     * Returns the total number of posts created for the given communities and time period.
     * @returns batch_CountResponse OK
     * @throws ApiError
     */
    public static postV1AdminCommunityInsightsOverviewNbrPosts({
        request,
    }: {
        /** communities: [optional], end_date: [optional, default: time.now()]. Timeformat: RFC3339:2006-01-02T15:04:05Z07:00 **/
        request: batch_CommunitiesAndTimePeriodRequest
    }): CancelablePromise<batch_CountResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community-insights/overview/nbr-posts",
            body: request,
        })
    }
}
