/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { admin_CreateIntegrationConnectorRequest } from "../models/admin_CreateIntegrationConnectorRequest"
import type { admin_CreateIntegrationConnectorResponse } from "../models/admin_CreateIntegrationConnectorResponse"
import type { admin_GetIntegrationTypeResponse } from "../models/admin_GetIntegrationTypeResponse"
import type { admin_UpdateIntegrationConnectorRequest } from "../models/admin_UpdateIntegrationConnectorRequest"
import type { avy_api_pkg_common_domain_IntegrationWithType } from "../models/avy_api_pkg_common_domain_IntegrationWithType"
import type { avy_api_pkg_common_domain_IntegrationWithTypeAndSegments } from "../models/avy_api_pkg_common_domain_IntegrationWithTypeAndSegments"
import type { avy_api_pkg_segment_PublishResourceRequest } from "../models/avy_api_pkg_segment_PublishResourceRequest"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class ConnectorAdminService {
    /**
     * Get All Integration
     * Get All Integrations
     * @returns avy_api_pkg_common_domain_IntegrationWithType OK
     * @throws ApiError
     */
    public static getV1AdminConnectorIntegration({
        type,
    }: {
        /** Integration type filter **/
        type?: string
    }): CancelablePromise<
        Array<avy_api_pkg_common_domain_IntegrationWithType>
    > {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/connector/integration",
            query: {
                type: type,
            },
        })
    }

    /**
     * Create Integration Connector
     * Create Integration Connector
     * @returns admin_CreateIntegrationConnectorResponse OK
     * @throws ApiError
     */
    public static postV1AdminConnectorIntegration({
        request,
    }: {
        /** Request body create Integration **/
        request: admin_CreateIntegrationConnectorRequest
    }): CancelablePromise<admin_CreateIntegrationConnectorResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/connector/integration",
            body: request,
        })
    }

    /**
     * Get Integration Connector
     * Get Integration Connector by Integration ID
     * @returns avy_api_pkg_common_domain_IntegrationWithTypeAndSegments OK
     * @throws ApiError
     */
    public static getV1AdminConnectorIntegration1({
        integrationId,
    }: {
        /** ID of integration **/
        integrationId: number
    }): CancelablePromise<avy_api_pkg_common_domain_IntegrationWithTypeAndSegments> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/connector/integration/{integration_id}",
            path: {
                integration_id: integrationId,
            },
        })
    }

    /**
     * Update Integration Connector
     * Update Integration Connector
     * @returns void
     * @throws ApiError
     */
    public static patchV1AdminConnectorIntegration({
        request,
        integrationId,
    }: {
        /** Request body Integration **/
        request: admin_UpdateIntegrationConnectorRequest
        /** Integration ID **/
        integrationId: number
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: "PATCH",
            url: "/v1/admin/connector/integration/{integration_id}",
            path: {
                integration_id: integrationId,
            },
            body: request,
        })
    }

    /**
     * Publish integration
     * Publish (unpublish) an integration to segments
     * @returns any OK
     * @throws ApiError
     */
    public static putV1AdminConnectorIntegrationPublish({
        integrationId,
        request,
    }: {
        /** ID of integration **/
        integrationId: number
        /** Request body to set where integration is published **/
        request: avy_api_pkg_segment_PublishResourceRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/connector/integration/{integration_id}/publish",
            path: {
                integration_id: integrationId,
            },
            body: request,
        })
    }

    /**
     * Get All IntegrationTypes
     * Get All IntegrationTypes
     * @returns admin_GetIntegrationTypeResponse OK
     * @throws ApiError
     */
    public static getV1AdminConnectorIntegrationType(): CancelablePromise<
        Array<admin_GetIntegrationTypeResponse>
    > {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/connector/integration_type",
        })
    }
}
