import { batch_ClusterWithSummary } from "src/api"

export interface IDateRangeFilter {
    from: Date
    to: Date
}

export interface ICommunity {
    id: number
    name: string
}

export type TCommunityPeriod = {
    value: number
    warningScore: number
    period: string
    clusters: batch_ClusterWithSummary[]
}

export interface ICommunitiesInsight {
    id: number
    name: string
    periods: TCommunityPeriod[]
}

export type IHeaders = {
    key: string
    name: string
    hidden?: boolean
}[]

export enum CommunityInsightsSeverityLevel {
    NONE = "none",
    REDUCED = "reduced",
    LOW = "low",
    MODERATED = "moderated",
    MEDIUM = "medium",
    HIGH = "high",
    SEVERE = "severe",
}
