import {
    IDynamicFieldsListItemProps,
    IExternalDynamicFieldsListItemProps,
} from "src/modals/contact-forms-form-builder/types/fieldsListItem"

export const isBinButtonDisabled = (
    array:
        | IDynamicFieldsListItemProps[]
        | IExternalDynamicFieldsListItemProps[],
) => array.length === 1
