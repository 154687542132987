import { t } from "@lingui/macro"
import { Stack } from "@mui/material"
import { observer } from "mobx-react"

import { FormPanel } from "src/components/FormPanel"
import { SegmentPickerField } from "src/components/SegmentPickerField"
import { ChatbotDispatchesStore } from "src/modals/chatbot-dispatches/store"
import { useStore } from "src/store/lib/useStore"

export const ChatbotDispatchesPublishSettingsSection = observer(() => {
    const store = useStore(ChatbotDispatchesStore)

    return (
        <FormPanel
            sections={[
                {
                    header: t`chatbot-dispatches-modal.section.publish`,
                    content: (
                        <Stack spacing={2}>
                            <SegmentPickerField
                                value={store.getPublishedSegments()}
                                accessGroupId={store.getAccessGroupIdForSegmentPicker()}
                                onChange={store.setPublishedSegments}
                                disabled={
                                    store.formFields.get("access_type") ===
                                    "READ"
                                }
                            />
                        </Stack>
                    ),
                },
            ]}
        />
    )
})
