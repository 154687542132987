/* eslint-disable import/no-relative-parent-imports */
import { useState } from "react"

import {
    IDynamicFieldsListItemProps,
    IExternalDynamicFieldsListItemProps,
} from "../types/fieldsListItem"
import {
    initialExternalFieldsState,
    initialInternalFieldsState,
} from "../constants/initialFieldState"

const useFieldList = () => {
    const [dynamicFieldsList, setDynamicFieldsList] = useState<
        IDynamicFieldsListItemProps[]
    >([initialInternalFieldsState])
    const [externalDynamicFieldsList, setExternalDynamicFieldsList] = useState<
        IExternalDynamicFieldsListItemProps[]
    >([initialExternalFieldsState])

    return {
        dynamicFieldsList,
        externalDynamicFieldsList,
        setDynamicFieldsList,
        setExternalDynamicFieldsList,
    }
}

export default useFieldList
