/* eslint-disable import/no-relative-parent-imports */
import { t } from "@lingui/macro"
import { Stack, TextField } from "@mui/material"
import { observer } from "mobx-react"

import { FormDetailStore } from "../store"

import { ConnectorDropdown } from "./ConnectorDropdown"

import { handleEmailFieldChange } from "../helper/handleEmailFieldChange"

import { useStore } from "src/store/lib/useStore"

export const IntegrationSection = observer(() => {
    const store = useStore(FormDetailStore)

    const handleSelectionChange = async (value: number) => {
        store.formFields.set("selectedConnectorId", value)
        const response = await store.getAllSources(value)
        store.formFields.set("externalFieldList", response)
    }

    const handleExternalFieldIdChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        store.formFields.set("externalFormId", event.target.value)
    }

    const handleEmailChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        handleEmailFieldChange(event, store.formFields)
    }

    return (
        <Stack spacing={2}>
            <ConnectorDropdown
                connectors={store.formFields.get("connectorList") ?? []}
                selectedValue={String(
                    store.formFields.get("selectedConnectorId"),
                )}
                onSelectionChange={handleSelectionChange}
                errorMessage={
                    store.formFields.error("selectedConnectorId") as string
                }
                disabled={store.isEditMode}
            />
            <TextField
                label={t`contact-form-forms-detail-modal.form-detail-section.external-formId-label`}
                value={store.formFields.get("externalFormId")}
                onChange={handleExternalFieldIdChange}
                helperText={store.formFields.error("externalFormId")}
                error={Boolean(store.formFields.error("externalFormId"))}
            />
            <TextField
                label={t`contact-form-forms-detail-modal.form-detail-section.fallback-email-label`}
                value={store.formFields.get("email")}
                onChange={handleEmailChange}
                helperText={store.formFields.error("email")}
                error={Boolean(store.formFields.error("email"))}
            />
        </Stack>
    )
})
