import { observer } from "mobx-react"
import { useCallback } from "react"

import { ChatbotDetailStore } from "src/modals/chatbot/store"

import { StoreProvider } from "src/store/lib/StoreProvider"
import { IChatMessage } from "src/types/chat-message/chatMessage"

import { chatbot_MessageInfoSource } from "src/api"
import { AvyChat } from "src/components/Chat"
import { environment } from "src/config"
import { EAvyChatVariant } from "src/components/Chat/types/chat"

interface IProps {
    messages: IChatMessage[]
}

export const View = observer((props: IProps) => {
    const openLink = useCallback((url: string) => {
        window.open(url, "_blank", "")
    }, [])

    const handleLinkClick = useCallback(
        (item?: chatbot_MessageInfoSource) => {
            if (
                item === undefined ||
                (item !== undefined && item.object_type !== "information_item")
            ) {
                openLink("")
                return
            }

            const url =
                environment.BASE_URL +
                "/library/information-items/" +
                item.object_id

            openLink(url)
        },
        [openLink],
    )

    return (
        <AvyChat
            variant={EAvyChatVariant.Transcript}
            messages={props.messages}
            boxWrapperStyles={{
                padding: "16px",
                boxSizing: "border-box",
            }}
            handleSourceClick={handleLinkClick}
        />
    )
})

export const Transcript = (props: IProps) => (
    <StoreProvider Store={ChatbotDetailStore}>
        <View {...props} />
    </StoreProvider>
)
