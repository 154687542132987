/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { internal_api_http_admin_omnidomain_CheckDNSRecordsResponse } from "../models/internal_api_http_admin_omnidomain_CheckDNSRecordsResponse"
import type { omnidomain_CreateOmniDomainRequest } from "../models/omnidomain_CreateOmniDomainRequest"
import type { omnidomain_GetOmniDomainResponse } from "../models/omnidomain_GetOmniDomainResponse"
import type { omnidomain_OmniDomain } from "../models/omnidomain_OmniDomain"
import type { omnidomain_OmniDomainListRequest } from "../models/omnidomain_OmniDomainListRequest"
import type { omnidomain_OmniDomainListResponse } from "../models/omnidomain_OmniDomainListResponse"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class OmniDomainAdminService {
    /**
     * Create OmniDomain
     * Create a new OmniDomain. If the domain is a subdomain of the managed domain, it will be created as a managed domain regardless of the IsManaged flag.
     * @returns omnidomain_OmniDomain OK
     * @throws ApiError
     */
    public static postV1AdminOmnidomain({
        request,
    }: {
        /** Request body for creating OmniDomain **/
        request: omnidomain_CreateOmniDomainRequest
    }): CancelablePromise<omnidomain_OmniDomain> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/omnidomain",
            body: request,
        })
    }

    /**
     * List OmniDomains
     * List OmniDomains with pagination and filtering
     * @returns omnidomain_OmniDomainListResponse OK
     * @throws ApiError
     */
    public static postV1AdminOmnidomainList({
        request,
    }: {
        /** OmniDomainListRequest **/
        request: omnidomain_OmniDomainListRequest
    }): CancelablePromise<omnidomain_OmniDomainListResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/omnidomain/list",
            body: request,
        })
    }

    /**
     * Get one Omni domain entry
     * Get one Omni domain entry
     * @returns omnidomain_GetOmniDomainResponse OK
     * @throws ApiError
     */
    public static getV1AdminOmnidomain({
        omniDomainId,
    }: {
        /** OmniDomain ID **/
        omniDomainId: number
    }): CancelablePromise<omnidomain_GetOmniDomainResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/omnidomain/{omni_domain_id}",
            path: {
                omni_domain_id: omniDomainId,
            },
        })
    }

    /**
     * Delete OmniDomain
     * Delete an OmniDomain
     * @returns void
     * @throws ApiError
     */
    public static deleteV1AdminOmnidomain({
        omniDomainId,
    }: {
        /** OmniDomain ID **/
        omniDomainId: number
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/omnidomain/{omni_domain_id}",
            path: {
                omni_domain_id: omniDomainId,
            },
        })
    }

    /**
     * Check DNS Records
     * Check DNS records for an OmniDomain
     * @returns internal_api_http_admin_omnidomain_CheckDNSRecordsResponse OK
     * @throws ApiError
     */
    public static getV1AdminOmnidomainCheckDns({
        omniDomainId,
    }: {
        /** OmniDomain ID **/
        omniDomainId: number
    }): CancelablePromise<internal_api_http_admin_omnidomain_CheckDNSRecordsResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/omnidomain/{omni_domain_id}/check-dns",
            path: {
                omni_domain_id: omniDomainId,
            },
        })
    }
}
