import { t } from "@lingui/macro"
import { styled } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"

import { ListPage } from "src/components/ListPage"
import { Time } from "src/components/Time"
import { TruncatedText } from "src/components/TruncatedText"
import { formatInteger } from "src/lib/number"
import { CommunityPostDetailModal } from "src/modals/community-post-detail"
import { ConfirmModal } from "src/modals/confirm"
import { ViewCommunityPostModal } from "src/modals/view-community-post"
import { GlobalStore } from "src/store"

import { AccessTypeHeader } from "src/components/AccessTypeHeader"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { CommunityPostsStore } from "src/views/community-posts/store"

import { DataGridProTable } from "src/components/Table/DataGridPro"

import { FilterModel, IColumn, SortModel } from "src/types/data-grid-pro"
import { Repository } from "src/types/channel"
import {
    getFilterModelForRepository,
    getSortModelForRepository,
} from "src/lib/data-grid-pro"
import { IPostItem } from "src/views/community-posts/type"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"

const NoWrap = styled("span")({
    whiteSpace: "nowrap",
})

const repository: Repository = "community-posts"

const View = observer(() => {
    const vstore = useStore(CommunityPostsStore)
    const gstore = useStore(GlobalStore)

    const advanceQuery = useMemo(() => {
        const filter = getFilterModelForRepository(
            repository,
            gstore.session.dataGridFilterModel,
        )
        const sort = getSortModelForRepository(
            repository,
            gstore.session.dataGridSortModel,
        )

        return { sort, filter }
    }, [gstore.session.dataGridSortModel, gstore.session.dataGridFilterModel])

    useEffect(() => {
        ;(async () => {
            await vstore.init(gstore.session.accessGroupId, advanceQuery)
        })()
        return () => vstore.dispose()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vstore, gstore.session.accessGroupId])

    const handleFilterChange = useCallback(
        async (model: FilterModel) => {
            const sort = getSortModelForRepository(
                repository,
                gstore.session.dataGridSortModel,
            )

            await vstore.query({ sort, filter: model })
        },
        [vstore, gstore.session.dataGridSortModel],
    )

    const handleSortChange = useCallback(
        async (model: SortModel) => {
            const filter = getFilterModelForRepository(
                repository,
                gstore.session.dataGridFilterModel,
            )

            await vstore.query({ filter, sort: model })
        },
        [vstore, gstore.session.dataGridFilterModel],
    )

    const createDeletePostConfirmHandler = useCallback(
        (id: number) => async (confirmed: boolean) => {
            if (confirmed) {
                await vstore.deletePost(id)
            }
        },
        [vstore],
    )

    const createDeletePostConfirmModalHandler = useCallback(
        (id: number) => () => {
            gstore.modals.open(
                () => (
                    <ConfirmModal
                        onConfirm={createDeletePostConfirmHandler(id)}
                        title={t`community-posts-view.delete-post-confirm-modal.title`}
                        content={t`community-posts-view.delete-post-confirm-modal.content`}
                    />
                ),
                { variant: "slide-up-w600" },
            )
        },
        [gstore.modals, createDeletePostConfirmHandler],
    )

    const createOpenPostDetailModalHandler = useCallback(
        (id: number) => () => {
            trackModuleEvent("Community | Edit Post", {
                [MixpanelProperties.CommunityName]: gstore.session.user?.name,
                [MixpanelProperties.ItemID]: id,
                [MixpanelProperties.AccessGroupName]:
                    gstore.session.accessGroup?.name,
            })
            gstore.modals.open(() => <CommunityPostDetailModal id={id} />)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [gstore.modals],
    )

    const openDetailModalHandler = useCallback(
        (item) => {
            gstore.modals.open(() => <ViewCommunityPostModal id={item.id} />)
        },
        [gstore.modals],
    )

    const columns: IColumn<IPostItem>[] = [
        {
            field: "text",
            headerName: t`community-posts-view.description-header`,
            renderCell: (params) => (
                <NoWrap>
                    <TruncatedText text={params.value ?? ""} max={20} />
                </NoWrap>
            ),
            type: "string",
            minWidth: 110,
            flex: 2,
        },
        {
            field: "author_name",
            headerName: t`community-posts-view.author-header`,
            renderCell: (params) => (
                <NoWrap>
                    <TruncatedText text={params.value ?? ""} max={20} />
                </NoWrap>
            ),
            type: "string",
            minWidth: 110,
            flex: 2,
        },
        {
            field: "author.author_name",
            headerName: t`community-posts-view.creator-header`,
            renderCell: (params) => (
                <NoWrap>
                    <TruncatedText text={params.value ?? ""} max={20} />
                </NoWrap>
            ),
            type: "string",
            minWidth: 110,
            flex: 2,
        },
        {
            field: "community_name",
            headerName: t`community-posts-view.community-breadcrumb`,
            renderCell: (params) => (
                <NoWrap>
                    <TruncatedText text={params.value ?? ""} max={20} />
                </NoWrap>
            ),
            type: "string",
            minWidth: 110,
            flex: 2,
        },
        {
            field: "internal_apartment_id",
            headerName: t`community-posts-view.internal-apartment-id-header`,
            renderCell: (params) => params.value,
            type: "string",
        },
        {
            field: "apartment_no",
            headerName: t`community-posts-view.apartment-id-header`,
            renderCell: (params) => params.value,
            type: "string",
        },
        {
            field: "likeCount",
            headerName: t`community-posts-view.likes-header`,
            filterable: false,
            sortable: false,
            renderCell: (params) => formatInteger(params.value),
        },
        {
            field: "commentCount",
            headerName: t`community-posts-view.comments-header`,
            filterable: false,
            sortable: false,
            renderCell: (params) => formatInteger(params.value),
        },
        {
            field: "created_at",
            headerName: t`community-posts-view.created-header`,
            renderCell: (params) => <Time date={params.value} />,
            type: "date",
            minWidth: 110,
        },
        {
            field: "access_type",
            headerName: t`community-view.access-type`,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <AccessTypeHeader accessType={params.value} />
            ),
        },
        {
            field: "updated_at",
            headerName: t`community-comments-view.last-update-header`,
            renderCell: (params) => <Time date={params.value} />,
            type: "date",
            minWidth: 110,
        },
    ]

    return (
        <ListPage
            header={{
                header: t`community-posts-view.header`,
                breadcrumbs: [
                    t`community-posts-view.community-breadcrumb`,
                    t`community-posts-view.posts-breadcrumb`,
                ],
                createOptions: {
                    onClick() {
                        gstore.modals.open(() => <CommunityPostDetailModal />)
                    },
                    item: t`community-posts-view.create-community-post`,
                },
            }}
            loading={
                gstore.loading.is(CommunityPostsStore.LoadingKeys.init) ||
                !vstore.posts.meta.initialized
            }
        >
            <DataGridProTable
                paginator={vstore.posts}
                data={vstore.posts.items}
                columns={columns}
                advancedOperations={{
                    pagination: "server",
                    filtering: "server",
                    sorting: "server",
                }}
                onRowClickEvent={openDetailModalHandler}
                repository={repository}
                rowActionsRenderer={(item) => [
                    {
                        text: t`community-posts-view.edit-post-button`,
                        onClick: createOpenPostDetailModalHandler(item.id),
                        hidden: !vstore.canEditPost(
                            item.id,
                            gstore.session.user?.adminId,
                        ),
                    },
                    {
                        text: t`community-posts-view.delete-post-button`,
                        destructive: true,
                        onClick: createDeletePostConfirmModalHandler(item.id),
                    },
                ]}
                loading={gstore.loading.is(
                    CommunityPostsStore.LoadingKeys.loading,
                )}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
            />
        </ListPage>
    )
})

export const CommunityPostsView = () => (
    <StoreProvider Store={CommunityPostsStore}>
        <View />
    </StoreProvider>
)
