/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { avy_api_pkg_tenants_domain_TenantWithContracts } from "../models/avy_api_pkg_tenants_domain_TenantWithContracts"
import type { leasecontract_GetLeaseContractsRequest } from "../models/leasecontract_GetLeaseContractsRequest"
import type { leasecontract_GetLeaseContractsResponse } from "../models/leasecontract_GetLeaseContractsResponse"
import type { notification_NotificationMethodResponse } from "../models/notification_NotificationMethodResponse"
import type { tenant_GetTenantsRequest } from "../models/tenant_GetTenantsRequest"
import type { tenant_GetTenantsResponse } from "../models/tenant_GetTenantsResponse"
import type { tenant_MagicLinkResponse } from "../models/tenant_MagicLinkResponse"
import type { tenant_UpdateTenantRequest } from "../models/tenant_UpdateTenantRequest"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class TenantAdminService {
    /**
     * Get lease contracts
     * Get tenants and their contract data based on some criteria (searching, segments, status, dates)
     * @returns leasecontract_GetLeaseContractsResponse OK
     * @throws ApiError
     */
    public static postV1AdminLeaseContractList({
        request,
        provider,
    }: {
        /** Filters for how to get tenant contracts **/
        request: leasecontract_GetLeaseContractsRequest
        /** Provider (possible values: db, es) **/
        provider?: string
    }): CancelablePromise<leasecontract_GetLeaseContractsResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/lease-contract/list",
            query: {
                provider: provider,
            },
            body: request,
        })
    }

    /**
     * Get tenants
     * Get tenants based on some criteria (searching, segments, status, dates)
     * @returns tenant_GetTenantsResponse OK
     * @throws ApiError
     */
    public static postV1AdminTenantsList({
        request,
        provider,
    }: {
        /** Filters for how to get tenants **/
        request: tenant_GetTenantsRequest
        /** Provider (possible values: db, es) **/
        provider?: string
    }): CancelablePromise<tenant_GetTenantsResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/tenants/list",
            query: {
                provider: provider,
            },
            body: request,
        })
    }

    /**
     * Returns magic link for tenant impersonation
     * Get Tenant Magic Link
     * @returns tenant_MagicLinkResponse OK
     * @throws ApiError
     */
    public static getV1AdminTenantsMagicLink({
        tenantId,
        propertyObjectId,
    }: {
        /** Tenant to be impersonated **/
        tenantId: number
        /** Apartment on which to impersonate tenant **/
        propertyObjectId: number
    }): CancelablePromise<tenant_MagicLinkResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/tenants/magic-link",
            query: {
                tenant_id: tenantId,
                property_object_id: propertyObjectId,
            },
        })
    }

    /**
     * Get tenant
     * Get details of tenant user
     * @returns avy_api_pkg_tenants_domain_TenantWithContracts OK
     * @throws ApiError
     */
    public static tenantsGetOne({
        tenantId,
    }: {
        /** Tenant ID **/
        tenantId: number
    }): CancelablePromise<avy_api_pkg_tenants_domain_TenantWithContracts> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/tenants/{tenant_id}",
            path: {
                tenant_id: tenantId,
            },
        })
    }

    /**
     * Update Tenant
     * Update Tenant information
     * @returns any OK
     * @throws ApiError
     */
    public static patchV1AdminTenants({
        tenantId,
        request,
    }: {
        /** Tenant ID **/
        tenantId: number
        /** Request body for updating a tenant **/
        request: tenant_UpdateTenantRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PATCH",
            url: "/v1/admin/tenants/{tenant_id}",
            path: {
                tenant_id: tenantId,
            },
            body: request,
        })
    }

    /**
     * Get tenant notification method
     * Get notification method for tenant
     * @returns notification_NotificationMethodResponse OK
     * @throws ApiError
     */
    public static getV1AdminTenantsNotificationMethod({
        tenantId,
    }: {
        /** Tenant ID for which to load notification method **/
        tenantId: number
    }): CancelablePromise<notification_NotificationMethodResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/tenants/{tenant_id}/notification-method",
            path: {
                tenant_id: tenantId,
            },
        })
    }
}
