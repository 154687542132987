import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Tab, useMediaQuery, useTheme, styled } from "@mui/material"

interface ITabs {
    data: {
        label: string
        id: string
        view: JSX.Element
    }[]
    selectedTabId: string
    handleTabChange: (event: React.SyntheticEvent, id: string) => void
    tabListStyles?: {}
    tabContentStyles?: {}
    tabStyles?: {}
    tabPanelStyles?: {}
}

const StyledTabList = styled(TabList)(({ theme }) => ({
    "& .MuiTabs-indicator": {
        backgroundColor: theme.palette.info.main,
    },
    "& .Mui-selected": {
        color: `${theme.palette.info.main} !important`,
    },
}))

export const Tabs = (props: ITabs) => {
    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

    return props.selectedTabId !== "" ? (
        <TabContext value={props.selectedTabId}>
            <StyledTabList
                variant={smallScreen ? "fullWidth" : "standard"}
                onChange={props.handleTabChange}
                data-testid="tabList"
                sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    ...props.tabListStyles,
                }}
            >
                {props.data.map((tab) => (
                    <Tab
                        key={tab.id}
                        label={tab.label}
                        value={tab.id}
                        sx={{
                            backgroundColor:
                                tab.id === props.selectedTabId
                                    ? theme.palette.common.white
                                    : theme.palette.grey[300],
                            marginRight: "8px",
                            boxShadow:
                                tab.id === props.selectedTabId
                                    ? "0px 0px 10px rgba(0, 0, 0, 0.1)"
                                    : "",
                            borderRadius: "4px 4px 0px 0px",
                            ...props.tabStyles,
                        }}
                        data-testid={`tab-${tab.id}`}
                    />
                ))}
            </StyledTabList>
            {props.data.map((tab) => (
                <TabPanel
                    key={tab.id}
                    value={tab.id}
                    sx={{
                        backgroundColor: "#fff",
                        ...props.tabListStyles,
                        ...props.tabContentStyles,
                    }}
                    style={{ marginTop: 0 }}
                >
                    {tab.view}
                </TabPanel>
            ))}
        </TabContext>
    ) : null
}
