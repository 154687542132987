import { t } from "@lingui/macro"

import { useMemo, useCallback } from "react"
import { Box, Typography, DialogContent } from "@mui/material"

import UserInputHistorySection from "./sections/UserInputHistorySection"
import ReformulateNodeSection from "./sections/ReformulateNodeSection"
import ResponseSection from "./sections/ResponseSection"
import AnalysisSection from "./sections/AnalysisSection"
import ConsideredSection from "./sections/ConsideredSection"
import MessageTypeSection from "./sections/MessageTypeSection"

import HeaderSection from "src/components/ChatMessageJourney/sections/HeaderSection"
import { IChatMessage } from "src/types/chat-message/chatMessage"
import {
    ContentBox,
    StyledDialog,
} from "src/components/ChatMessageJourney/styled"
import { IChatMessageJourneyProps } from "src/components/ChatMessageJourney/types"

export const ChatMessageJourney = ({
    isOpenID,
    setIsOpenID,
    messages,
    handleSourceClick,
}: IChatMessageJourneyProps) => {
    const currentMessage: IChatMessage | undefined = useMemo(
        () => messages.find((message) => message.messageId === isOpenID),
        [messages, isOpenID],
    )

    const handleClose = useCallback(() => {
        setIsOpenID("")
    }, [setIsOpenID])

    if (currentMessage === undefined) {
        return null
    }

    return (
        <Box display="flex" justifyContent="flex-end" mt={2}>
            <StyledDialog
                open={Boolean(isOpenID)}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
            >
                <HeaderSection setIsOpenID={setIsOpenID} />
                <DialogContent sx={{ pt: 0, pl: 2, pr: 2, pb: 1 }}>
                    <ContentBox>
                        <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                            {t`chat-message-journey.message`}
                        </Typography>
                        <UserInputHistorySection messages={messages} />
                        <ReformulateNodeSection message={currentMessage} />
                        <MessageTypeSection
                            messageType={
                                currentMessage.messageType === undefined
                                    ? currentMessage.messageType
                                    : "CHAT_ANSWER"
                            }
                        />
                        <ResponseSection message={currentMessage} />
                    </ContentBox>
                    <ContentBox>
                        <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                            {t`chat-message-journey.sources-tools`}
                        </Typography>
                        <AnalysisSection
                            message={currentMessage}
                            handleSourceClick={handleSourceClick}
                        />
                        <ConsideredSection
                            message={currentMessage}
                            handleSourceClick={handleSourceClick}
                        />
                    </ContentBox>
                </DialogContent>
            </StyledDialog>
        </Box>
    )
}
