import { Button, Stack, useTheme } from "@mui/material"
import { observer } from "mobx-react"

import { t } from "@lingui/macro"

import { FormTypeDropdown } from "./FormTypeDropdown"

import { LabelQuestionTextField } from "./LabelQuestionTextField"

import { AddDynamicOptionsSection } from "./AddDynamicOptionsSection"

import { ShortLongAnswerTextField } from "./ShortLongAnswerTextField"

import { BottomContainerSection } from "./BottomContainerSection"

import {
    CardContainer,
    Separator,
    CardInnerContainer,
    DraggableIconContainer,
} from "src/modals/contact-forms-form-builder/styled"

import { FormDetailStore } from "src/modals/contact-forms-form-builder/store"

import { IDynamicNativeFieldsSectionProps } from "src/modals/contact-forms-form-builder/types/dynamicFieldSection"

import { FormTypeDropdownKeys } from "src/modals/contact-forms-form-builder/types/formTypeDropdownValues"

import { Add24 } from "src/components/icons/Add24"
import { Icon } from "src/components/icons"
import { useWindowWide } from "src/views/feature-toggles/features/useWindowWide"
import { useStore } from "src/store/lib/useStore"
import { DragIndicator } from "src/components/icons/DragIndicator"
import { isBinButtonDisabled } from "src/modals/contact-forms-form-builder/helper/isBinButtonDisabled"

export const DynamicNativeFieldsSection = observer(
    (props: IDynamicNativeFieldsSectionProps) => {
        const store = useStore(FormDetailStore)
        const theme = useTheme()
        const currentWidth = useWindowWide(0)

        const handleInputChange = (
            index: number,
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
            const list = [...props.dynamicFieldsList]
            const obj = JSON.parse(JSON.stringify(list[props.index]))
            obj.inputList[index].errorMessage = ""
            store.setHasError(false)
            obj.inputList[index].value =
                event.target.value.trim() !== "" ? event.target.value : ""
            if (event.target.value.trim() === "") {
                store.setHasError(true)
                obj.inputList[index].errorMessage = t`errors.required`
            }
            list[props.index] = obj
            props.setDynamicFieldsList(list)
        }

        const handleRemoveClick = (index: number) => {
            store.setHasError(false)
            const list = [...props.dynamicFieldsList]
            list[props.index].inputList.splice(index, 1)
            props.setDynamicFieldsList(list)
        }

        const handleAddClick = () => {
            const list = [...props.dynamicFieldsList]
            list[props.index].inputList = [
                ...list[props.index].inputList,
                { value: "", uuid: "", errorMessage: "" },
            ]
            props.setDynamicFieldsList(list)
        }

        const handleLabelQuestionFormChange = (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
            const list = [...props.dynamicFieldsList]
            const obj = JSON.parse(JSON.stringify(list[props.index]))
            if (obj.dropdownValue.value === FormTypeDropdownKeys.FileUpload) {
                obj.inputList = [{ value: "", uuid: "", errorMessage: "" }]
            }
            obj.label.errorMessage = ""
            store.setHasError(false)
            obj.label.value =
                event.target.value.trim() !== "" ? event.target.value : ""
            if (event.target.value.trim() === "") {
                store.setHasError(true)
                obj.label.errorMessage = t`errors.required`
            }
            list[props.index] = obj
            props.setDynamicFieldsList(list)
        }

        const handleShortAnswerChange = (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
            const list = [...props.dynamicFieldsList]
            const obj = JSON.parse(JSON.stringify(list[props.index]))
            obj.inputList = [{ value: "", uuid: "", errorMessage: "" }]
            obj.answer.errorMessage = ""
            store.setHasError(false)
            obj.answer.value =
                event.target.value.trim() !== "" ? event.target.value : ""
            list[props.index] = obj
            props.setDynamicFieldsList(list)
        }

        const onSelectValueChange = (value: string) => {
            let data = [...props.dynamicFieldsList]
            const obj = JSON.parse(JSON.stringify(data[props.index]))
            obj.dropdownValue.errorMessage = ""
            store.setHasError(false)
            obj.dropdownValue.value = value.trim() !== "" ? value : ""
            if (value.trim() === "") {
                store.setHasError(true)
                obj.dropdownValue.errorMessage = t`errors.required`
            }
            data[props.index] = obj
            props.setDynamicFieldsList(data)
        }
        return (
            <CardContainer windowWidth={currentWidth}>
                <CardInnerContainer>
                    <DraggableIconContainer>
                        <DragIndicator />
                    </DraggableIconContainer>
                    <Stack spacing={2}>
                        <FormTypeDropdown
                            index={props.index}
                            item={
                                props.dynamicFieldsList[props.index]
                                    .dropdownValue
                            }
                            onSelectValueChange={onSelectValueChange}
                        />
                        <LabelQuestionTextField
                            index={props.index}
                            item={props.dynamicFieldsList[props.index].label}
                            handleFormChange={handleLabelQuestionFormChange}
                        />
                        <Stack spacing={2}>
                            {props.dynamicFieldsList[props.index].dropdownValue
                                .value === FormTypeDropdownKeys.RadioButton ||
                            props.dynamicFieldsList[props.index].dropdownValue
                                .value === FormTypeDropdownKeys.Checkbox ||
                            props.dynamicFieldsList[props.index].dropdownValue
                                .value === FormTypeDropdownKeys.Dropdown ? (
                                <>
                                    {props.inputList.map((input, index) => {
                                        return (
                                            <AddDynamicOptionsSection
                                                type={
                                                    props.dynamicFieldsList[
                                                        props.index
                                                    ].dropdownValue.value
                                                }
                                                index={index}
                                                item={input}
                                                handleInputChange={
                                                    handleInputChange
                                                }
                                                handleRemoveClick={
                                                    handleRemoveClick
                                                }
                                                disabled={store.isEditMode}
                                            />
                                        )
                                    })}
                                    <Button
                                        sx={{ alignSelf: "flex-start" }}
                                        startIcon={
                                            <Icon
                                                icon={<Add24 />}
                                                size={20}
                                                color={
                                                    store.isEditMode
                                                        ? theme.palette.info
                                                              .light
                                                        : theme.palette.info
                                                              .main
                                                }
                                            />
                                        }
                                        disabled={store.isEditMode}
                                        color="info"
                                        onClick={handleAddClick}
                                    >
                                        {t`contact-form-forms-detail-modal.form-field-section.add-options-label`}
                                    </Button>
                                </>
                            ) : null}
                            {props.dynamicFieldsList[props.index].dropdownValue
                                .value === FormTypeDropdownKeys.ShortAnswer ||
                            props.dynamicFieldsList[props.index].dropdownValue
                                .value === FormTypeDropdownKeys.Paragraph ? (
                                <ShortLongAnswerTextField
                                    type={
                                        props.dynamicFieldsList[props.index]
                                            .dropdownValue.value
                                    }
                                    index={props.index}
                                    item={
                                        props.dynamicFieldsList[props.index]
                                            .answer
                                    }
                                    handleShortAnswerChange={
                                        handleShortAnswerChange
                                    }
                                    maxLength={40}
                                />
                            ) : null}
                        </Stack>
                        <Separator />
                        <BottomContainerSection
                            index={props.index}
                            isRequired={props.isRequired ?? false}
                            handleSwitchChange={() =>
                                props.handleSwitchChange(props.index)
                            }
                            handleContentCopyClick={() =>
                                props.handleContentCopyClick(props.index)
                            }
                            handleDeleteClick={() =>
                                props.handleRemoveClick(props.index)
                            }
                            disableButton={isBinButtonDisabled(
                                props.dynamicFieldsList,
                            )}
                        />
                    </Stack>
                </CardInnerContainer>
            </CardContainer>
        )
    },
)
