import { makeAutoObservable } from "mobx"

import DashboardCustomizeRoundedIcon from "@mui/icons-material/DashboardCustomizeRounded"
import { Dns } from "@material-ui/icons"

import { FeatureStore } from "./features"
import {
    INavigationItem,
    INavigationItemHighGroup,
    INavigationItemSingle,
} from "./navigation/types"
import { SessionStore } from "./session"

import { Calendar24 } from "src/components/icons/Calendar24"
import { environment } from "src/config/variables"
import { AntIconAccess } from "src/layouts/DefaultLayout/Navigation/icons/Access"
import { AntIconAccessGroups } from "src/layouts/DefaultLayout/Navigation/icons/AccessGroups"
import { AntIconBarChart } from "src/layouts/DefaultLayout/Navigation/icons/BarChart"
import { AntIconCampaign } from "src/layouts/DefaultLayout/Navigation/icons/Campaign"
import { AntIconClientManagement } from "src/layouts/DefaultLayout/Navigation/icons/ClientManagement"
import { AntIconCommunity } from "src/layouts/DefaultLayout/Navigation/icons/Community"
import { AntIconContactForms } from "src/layouts/DefaultLayout/Navigation/icons/ContactForms"
import { EmbeddedIntegrationsIcon } from "src/layouts/DefaultLayout/Navigation/icons/EmbeddedIntegrations"
import { AntIconFeatureConfiguration } from "src/layouts/DefaultLayout/Navigation/icons/FeatureConfiguration"
import { AntIconInvoices } from "src/layouts/DefaultLayout/Navigation/icons/Invoices"
import { AntIconLibraryBooks } from "src/layouts/DefaultLayout/Navigation/icons/LibraryBooks"
import { AntIconManageUsers } from "src/layouts/DefaultLayout/Navigation/icons/ManageUsers"
import { AntIconOrders } from "src/layouts/DefaultLayout/Navigation/icons/Orders"
import { AntIconProductCatalogue } from "src/layouts/DefaultLayout/Navigation/icons/ProductCatalogue"
import { AntIconProvider } from "src/layouts/DefaultLayout/Navigation/icons/Provider"
import { AntIconPushPin } from "src/layouts/DefaultLayout/Navigation/icons/PushPin"
import { AntIconResidents } from "src/layouts/DefaultLayout/Navigation/icons/Residents"
import { AntIconRestore } from "src/layouts/DefaultLayout/Navigation/icons/Restore"
import { AntIconSelfRegistration } from "src/layouts/DefaultLayout/Navigation/icons/SelfRegistration"

export class NavigationStore {
    private allDefaultItems: INavigationItemHighGroup[] = [
        //RESIDENTS
        {
            group: "residents",
            text: "navigation.resident",
            children: [
                {
                    to: "/tenants",
                    icon: <AntIconResidents />,
                    text: `navigation.residents`,
                    module: "tenants",
                },
                {
                    to: "/tenants-insights",
                    icon: <AntIconBarChart />,
                    text: `navigation.tenants-insights`,
                    module: "dashboard",
                },
                {
                    to: "/invoices",
                    icon: <AntIconInvoices />,
                    text: `navigation.invoices`,
                    module: "invoices",
                },
                {
                    to: "/self-registration",
                    icon: <AntIconSelfRegistration />,
                    text: `navigation.self-registration`,
                    module: ["lobby", "self_registration_leaflet"],
                },
            ],
        },
        // COMMUNICATION
        {
            group: "communication",
            text: "navigation.communication",
            children: [
                {
                    to: "/notice-board",
                    icon: <AntIconPushPin />,
                    text: `navigation.notice-board`,
                    module: "notice_board",
                },
                {
                    prefix: "/library",
                    icon: <AntIconLibraryBooks />,
                    text: `navigation.library`,
                    children: [
                        {
                            to: "/library/information-items",
                            text: `navigation.library-information-items`,
                            module: "info_center",
                        },
                        {
                            to: "/library/categories",
                            text: `navigation.library-categories`,
                            module: "info_center_category",
                        },
                    ],
                },
                {
                    prefix: "/community",
                    icon: <AntIconCommunity />,
                    text: `navigation.community`,
                    children: [
                        {
                            to: "/community/overview",
                            text: `navigation.community-overview`,
                            module: "community",
                            hidden: environment === "production",
                        },
                        {
                            to: "/community/insights",
                            text: `navigation.insights`,
                            module: "community",
                            hidden: environment === "production",
                        },
                        {
                            to: "/community/posts-and-comments",
                            text: `navigation.community-posts-and-comments`,
                            module: "community",
                            hidden: environment === "production",
                        },
                        {
                            to: "/community/posts",
                            text: `navigation.community-posts`,
                            module: "community",
                        },
                        {
                            to: "/community/comments",
                            text: `navigation.community-comments`,
                            module: "community",
                        },
                        {
                            to: "/community/communities",
                            text: `navigation.communities`,
                            module: "community",
                        },
                    ],
                },
                {
                    prefix: "/chatbot",
                    icon: <Calendar24 />,
                    text: `navigation.chatbot`,
                    children: [
                        {
                            to: "/chatbot/insights",
                            text: `navigation.insights`,
                            module: "ai_chatbot",
                        },
                        {
                            to: "/chatbot/conversation",
                            text: `navigation.conversation`,
                            module: "ai_chatbot",
                        },
                        {
                            to: "/chatbot/dispatches",
                            text: `navigation.dispatch`,
                            module: "ai_chatbot",
                        },
                        {
                            to: "/chatbot/tags",
                            text: `navigation.tags`,
                            module: "tags",
                        },
                    ],
                },
            ],
        },
        //MANAGEMENT
        {
            group: "management",
            text: "navigation.management",
            children: [
                {
                    prefix: "/case",
                    icon: <AntIconContactForms />,
                    text: `navigation.contact-forms`,
                    children: [
                        {
                            to: "/case/cases",
                            text: `navigation.contact-forms`,
                            module: "contact_form",
                        },
                        {
                            to: "/case/cases-v2",
                            text: `navigation.cases-v2`,
                            module: "contact_form",
                        },
                        {
                            to: "/case/forms",
                            text: `navigation.contact-forms-forms`,
                            module: "contact_form_type",
                        },
                        {
                            to: "/case/settings",
                            text: `navigation.contact-form-settings`,
                            module: "contact_form_type",
                        },
                        {
                            to: "/case/automatic-assignee",
                            text: `navigation.contact-form-automatic-assignee`,
                            module: "contact_form_default_assignee",
                        },
                    ],
                },
                {
                    prefix: "/booking",
                    icon: <Calendar24 />,
                    text: `navigation.booking`,
                    children: [
                        {
                            to: "/booking/bookings",
                            text: `navigation.bookings`,
                            module: "booking",
                        },
                        {
                            to: "/booking/resources",
                            text: `navigation.resources`,
                            module: "booking",
                        },
                    ],
                },
                {
                    to: "/access",
                    icon: <AntIconAccess />,
                    text: `navigation.access`,
                    module: "locks",
                },
                {
                    prefix: "/user-interface",
                    icon: <DashboardCustomizeRoundedIcon />,
                    text: `User interface`,
                    children: [
                        {
                            to: "/user-interface/navigation-items",
                            text: `navigation.navigation-items`,
                            module: "navigation_items",
                        },
                    ],
                },
            ],
        },
        // MARKETPLACE
        {
            group: "marketplace",
            text: "navigation.marketplace",
            children: [
                {
                    prefix: "/product-catalogue",
                    icon: <AntIconProductCatalogue />,
                    text: `navigation.product-catalogue`,
                    children: [
                        {
                            to: "/product-catalogue/products",
                            text: `navigation.products`,
                            module: "product_catalogue",
                        },
                        {
                            to: "/product-catalogue/categories",
                            text: `navigation.categories`,
                            module: "product_catalogue_category",
                        },
                    ],
                },
                {
                    prefix: "/order",
                    icon: <AntIconOrders />,
                    text: `navigation.orders.title`,
                    children: [
                        {
                            to: "/order/orders",
                            text: `navigation.orders`,
                            module: "product_catalogue",
                        },
                        {
                            to: "/order/individual-additions",
                            text: `navigation.orders-individual-additions`,
                            module: "product_catalogue",
                        },
                    ],
                },
                {
                    to: "/campaigns",
                    icon: <AntIconCampaign />,
                    text: `navigation.campaigns`,
                    module: "spotlight",
                },
                {
                    to: "/providers",
                    icon: <AntIconProvider />,
                    text: `navigation.providers`,
                    module: "provider",
                },
            ],
        },
    ]

    private allSecondListItems: INavigationItemHighGroup[] = [
        //users-&-access
        {
            group: "users-&-access",
            text: "navigation.users-&-access",
            children: [
                {
                    to: "/manage-users",
                    icon: <AntIconManageUsers />,
                    text: `navigation.manage-users`,
                    module: "usermanagement",
                },
                {
                    to: "/access-groups",
                    icon: <AntIconAccessGroups />,
                    text: `navigation.access-groups`,
                    module: "usermanagement",
                },
            ],
        },
        // CONFIGURATION
        {
            group: "configuration",
            text: "navigation.configuration",
            children: [
                {
                    to: "/client-management/property-owners",
                    icon: <AntIconClientManagement />,
                    text: `navigation.client-management`,
                },
                {
                    prefix: "/feature-toggles",
                    icon: <AntIconFeatureConfiguration />,
                    text: `navigation.feature-toggles`,
                    children: [
                        {
                            to: "/feature-toggles/features",
                            text: "navigation.features",
                        },
                        {
                            to: "/feature-toggles/feature-configurations",
                            text: `navigation.feature-configuration`,
                        },
                    ],
                },
                {
                    prefix: "/integrations",
                    icon: <EmbeddedIntegrationsIcon />,
                    text: `navigation.integration`,
                    children: [
                        {
                            to: "/integrations/integration-connector",
                            text: `navigation.integration-connector`,
                            module: "integration_connector",
                        },
                        {
                            to: "/integrations/embedded-integrations",
                            text: `navigation.embedded-integrations`,
                            module: "embedded_integrations",
                        },
                        {
                            to: "/integrations/embedded-integrations-v2",
                            text: `navigation.embedded-integrations.versionTwo`,
                            module: "embedded_integrations",
                        },
                    ],
                },
                {
                    to: "/reset",
                    icon: <AntIconRestore />,
                    text: "navigation.reset",
                    hidden: environment === "production",
                },
                {
                    to: "/omni-domains",
                    icon: <Dns />,
                    text: `navigation.omni-domains`,
                },
            ],
        },
    ]

    constructor(private session: SessionStore, private features: FeatureStore) {
        makeAutoObservable(this)
    }

    get allMenuItems() {
        return [...this.allDefaultItems, ...this.allSecondListItems]
    }

    get defaultItems() {
        return this.getRefinedNavigationItemList(this.allDefaultItems)
    }

    get secondListItems() {
        return this.getRefinedNavigationItemList(this.allSecondListItems)
    }

    get initialPageUrl() {
        if (this.session.user == null) {
            return "/login"
        }

        // Find the first accessible page. If an item is a group select the
        // first child.
        const fullList: INavigationItem[] = []
        this.defaultItems.forEach((group) =>
            group.children.forEach((child) => fullList.push(child)),
        )
        for (const item of fullList) {
            if ("children" in item) {
                if (typeof item.children[0].to === "string") {
                    return item.children[0].to
                }
            } else if (typeof item.to === "string") {
                return item.to
            }
        }

        // Fallback to a non-existent route. This should never happen but it's
        // better than crashing or infinite looping.
        return "/not-found"
    }

    private toAccessibleItem<
        TItem extends {
            hidden?: boolean
            module?: Modules | Modules[]
            children?: Omit<INavigationItemSingle, "icon">[]
        },
    >(item: TItem) {
        if (item.hidden === true) {
            return null
        } else {
            const modules =
                item.module != null
                    ? Array.isArray(item.module)
                        ? item.module
                        : [item.module]
                    : [undefined]
            let hasAccess = modules.some((module) =>
                this.session.hasAccessToModule(module),
            )
            if (item.children !== undefined && item.children.length > 0) {
                hasAccess =
                    hasAccess ||
                    item.children.some((c) => this.toAccessibleItem(c))
            }
            if (!hasAccess) {
                return null
            }
        }

        // Don't modify the original `item`. `toAccessibleItem` will only be
        // called once per item, but this is a safety measure in case we call it
        // multiple times sometime in the future.
        const copy = { ...item }

        if ("children" in copy && copy.children != null) {
            copy.children = copy.children.filter(
                (child) => this.toAccessibleItem(child) != null,
            )
        }

        return copy
    }

    get closedSectionLocalStore() {
        const closedSections = window.localStorage.getItem(
            "navBarClosedSections",
        )
        return closedSections !== null ? JSON.parse(closedSections) : null
    }

    //store in localStorage which sections where collapsed
    public setClosedSections(key: string) {
        const localClosedSections = this.closedSectionLocalStore ?? []
        const closedSections: string[] = [...localClosedSections]

        const closedSectionIndex = closedSections.findIndex(
            (item) => item === key,
        )
        closedSectionIndex >= 0
            ? closedSections.splice(closedSectionIndex, 1)
            : closedSections.push(key)

        window.localStorage.setItem(
            "navBarClosedSections",
            JSON.stringify(closedSections),
        )
    }
    //verify if a section is collapsed at load
    public getSingleSection(key: string) {
        const localClosedSections = this.closedSectionLocalStore ?? []
        return typeof localClosedSections.find(
            (item: string) => item === key,
        ) === "string"
            ? true
            : false
    }

    private getAccessibleItemsIntoArray(group: INavigationItemHighGroup) {
        const childrenGroupArray: INavigationItem[] = []

        group.children.forEach((item) => {
            const isAccessible = this.toAccessibleItem(item)
            if (isAccessible !== null) {
                childrenGroupArray.push({
                    ...isAccessible,
                })
            }
        })

        return childrenGroupArray
    }

    private getRefinedNavigationItemList = (
        itemList: INavigationItemHighGroup[],
    ) => {
        const newItemList: INavigationItemHighGroup[] = []

        itemList.forEach((group) => {
            const accessibleItemsArray = this.getAccessibleItemsIntoArray(group)
            if (accessibleItemsArray.length > 0) {
                newItemList.push({
                    ...group,
                    children: accessibleItemsArray,
                })
            }
        })

        return newItemList
    }
}
