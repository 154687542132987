import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useEffect, useRef } from "react"

import { useTheme, Grid, Stack } from "@mui/material"

import { Table } from "src/components/Table"

import { ListPage } from "src/components/ListPage"

import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { CommunitiesOverviewViewStore } from "src/views/community-overview/store"
import { MultipleSelectCheckmarks } from "src/components/MultipleSelectCheckmarks"
import { StatisticsRow } from "src/views/community-overview/components/StatisticsRow"
import { LastUpdateRow } from "src/views/community-overview/components/LastUpdateRow"
import { DateRangePickerRow } from "src/views/community-overview/components/DateRangePickerRow"
import { WarningFlagsHeader } from "src/views/community-overview/components/WarningFlagsHeader"
import { NoWrap } from "src/components/NoWrap"
import { WarningFlagChipsRow } from "src/views/community-overview/components/WarningFlagChipsRow"
import { ICommunityFlagsOverview } from "src/views/community-overview/types"
import { TMultipleSelectCheckmarksOption } from "src/components/MultipleSelectCheckmarks/types"

const View = observer(() => {
    const store = useStore(CommunitiesOverviewViewStore)
    const gstore = useStore(GlobalStore)
    const { palette } = useTheme()

    const prevSelectedCommunities = useRef(store.selectedCommunities)
    const prevStartDate = useRef(store.startDate)

    const isLoading =
        gstore.loading.is(CommunitiesOverviewViewStore.LoadingKeys.loading) ||
        gstore.loading.is(CommunitiesOverviewViewStore.LoadingKeys.init)

    useEffect(() => {
        ;(async () => await store.init(gstore.session.accessGroupId))()
    }, [store, gstore.session.accessGroupId])

    useEffect(() => {
        if (
            prevSelectedCommunities.current !== store.selectedCommunities ||
            prevStartDate.current !== store.startDate
        ) {
            ;(async () => await store.loadCommunityOverviewEndpoints())()

            prevSelectedCommunities.current = store.selectedCommunities
            prevStartDate.current = store.startDate
        }
    }, [store, store.selectedCommunities, store.startDate])

    const handleFilterBySelectedCommunity = useCallback(
        (selectedCommunities: TMultipleSelectCheckmarksOption[]) => {
            store.filterBySelectedCommunity(selectedCommunities)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [store.selectedCommunities],
    )

    const renderRows = useCallback(
        (item: ICommunityFlagsOverview) => ({
            communityName: <NoWrap>{item.name}</NoWrap>,
            incidentsFlags: (
                <WarningFlagChipsRow flags={item.flags} communityId={item.id} />
            ),
        }),
        [],
    )

    return (
        <ListPage
            header={{
                header: t`community-view.header`,
                breadcrumbs: [
                    t`community-view.community-breadcrumb`,
                    t`community-overview.community-breadcrumb`,
                ],
                tailingComponent: (
                    <MultipleSelectCheckmarks
                        label={t`community-view.header`}
                        options={store.communitiesForMultiSelect}
                        onChange={handleFilterBySelectedCommunity}
                    />
                ),
            }}
            loading={isLoading}
        >
            <Stack bgcolor={palette.background.default} padding={3}>
                <Grid
                    borderRadius={1}
                    bgcolor={palette.grey[50]}
                    marginBottom={2}
                >
                    {<DateRangePickerRow />}
                    {<StatisticsRow />}
                    {<LastUpdateRow />}
                </Grid>
            </Stack>
            <Stack bgcolor={palette.background.default} padding={3}>
                <Table
                    headers={[
                        {
                            key: "communityName",
                            name: t`community-overview.table-header-community-name`,
                        },
                        {
                            key: "incidentsFlags",
                            name: <WarningFlagsHeader />,
                        },
                    ]}
                    paginator={store.communitiesPaginator}
                    rowRenderer={renderRows}
                />
            </Stack>
        </ListPage>
    )
})

export const CommunityOverviewView = observer(() => (
    <StoreProvider Store={CommunitiesOverviewViewStore}>
        <View />
    </StoreProvider>
))
