/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { avy_api_pkg_segment_PublishResourceRequest } from "../models/avy_api_pkg_segment_PublishResourceRequest"
import type { avy_api_pkg_segment_PublishResourceResponse } from "../models/avy_api_pkg_segment_PublishResourceResponse"
import type { chatbot_Conversation } from "../models/chatbot_Conversation"
import type { chatbot_CreateDispatchRequest } from "../models/chatbot_CreateDispatchRequest"
import type { chatbot_DispatchListRequest } from "../models/chatbot_DispatchListRequest"
import type { chatbot_DispatchListResponse } from "../models/chatbot_DispatchListResponse"
import type { chatbot_DispatchResponse } from "../models/chatbot_DispatchResponse"
import type { chatbot_GetConversationsRequest } from "../models/chatbot_GetConversationsRequest"
import type { chatbot_GetConversationsResponse } from "../models/chatbot_GetConversationsResponse"
import type { chatbot_GetMessageTypesResponse } from "../models/chatbot_GetMessageTypesResponse"
import type { chatbot_GetTranslatedConversationResponse } from "../models/chatbot_GetTranslatedConversationResponse"
import type { chatbot_Message } from "../models/chatbot_Message"
import type { chatbot_SendMessageRequestStreaming } from "../models/chatbot_SendMessageRequestStreaming"
import type { chatbot_UpdateFeedbackRequest } from "../models/chatbot_UpdateFeedbackRequest"
import type { tags_ApplyTagsRequest } from "../models/tags_ApplyTagsRequest"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class ChatbotAdminService {
    /**
     * Gets Chatbot conversations
     * Takes a request for getting conversations held between tenants and avy-chatbot
     * @returns chatbot_GetConversationsResponse OK
     * @throws ApiError
     */
    public static postV1AdminChatbotConversations({
        request,
        pageNumber = 1,
        pageSize = 10,
        accessGroupId,
    }: {
        /** How conversations should be filtered **/
        request: chatbot_GetConversationsRequest
        /** Pagination - number of page to load **/
        pageNumber?: number
        /** Pagination - size of pages to load **/
        pageSize?: number
        /** Access group ID for which to load conversations **/
        accessGroupId?: number
    }): CancelablePromise<chatbot_GetConversationsResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/chatbot/conversations",
            query: {
                page_number: pageNumber,
                page_size: pageSize,
                access_group_id: accessGroupId,
            },
            body: request,
        })
    }

    /**
     * Get Chatbot conversation
     * Gets a single conversation by ID
     * @returns chatbot_Conversation OK
     * @throws ApiError
     */
    public static getV1AdminChatbotConversations({
        conversationId,
    }: {
        /** Conversation ID **/
        conversationId: string
    }): CancelablePromise<chatbot_Conversation> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/chatbot/conversations/{conversation_id}",
            path: {
                conversation_id: conversationId,
            },
        })
    }

    /**
     * Send a chat message in a conversation
     * Takes a body containing a message to be sent to a conversation
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminChatbotConversationsMessage({
        conversationId,
        request,
    }: {
        /** Conversation ID **/
        conversationId: string
        /** SendMessageRequestStreaming **/
        request: chatbot_SendMessageRequestStreaming
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/chatbot/conversations/{conversation_id}/message",
            path: {
                conversation_id: conversationId,
            },
            body: request,
        })
    }

    /**
     * Get Chatbot conversation messages
     * Gets a single conversation's messages
     * @returns chatbot_Message OK
     * @throws ApiError
     */
    public static getV1AdminChatbotConversationsMessages({
        conversationId,
    }: {
        /** Conversation ID **/
        conversationId: string
    }): CancelablePromise<Array<chatbot_Message>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/chatbot/conversations/{conversation_id}/messages",
            path: {
                conversation_id: conversationId,
            },
        })
    }

    /**
     * Get Chatbot conversation messages translated to requested language
     * Gets a single conversation's messages translated to requested language
     * @returns chatbot_GetTranslatedConversationResponse OK
     * @throws ApiError
     */
    public static getV1AdminChatbotConversationsMessagesTranslate({
        conversationId,
        language,
    }: {
        /** Conversation ID **/
        conversationId: string
        /** Language to translate to **/
        language: string
    }): CancelablePromise<chatbot_GetTranslatedConversationResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/chatbot/conversations/{conversation_id}/messages/translate",
            path: {
                conversation_id: conversationId,
            },
            query: {
                language: language,
            },
        })
    }

    /**
     * Apply tags to a conversation
     * Takes a body with tags to be applied to a conversation
     * @returns any OK
     * @throws ApiError
     */
    public static putV1AdminChatbotConversationsTag({
        conversationId,
        request,
    }: {
        /** Conversation ID **/
        conversationId: string
        /** ApplyTagsRequest **/
        request: tags_ApplyTagsRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/chatbot/conversations/{conversation_id}/tag",
            path: {
                conversation_id: conversationId,
            },
            body: request,
        })
    }

    /**
     * Update admin's feedback on a chatbot conversation
     * Takes a body with feedback to be proxied to avy-chatbot
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminChatbotConversationsUpdateFeedback({
        request,
        conversationId,
    }: {
        /** Update feedback **/
        request: chatbot_UpdateFeedbackRequest
        /** Conversation ID **/
        conversationId: string
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/chatbot/conversations/{conversation_id}/update-feedback",
            path: {
                conversation_id: conversationId,
            },
            body: request,
        })
    }

    /**
     * Create dispatch
     * Takes a body with dispatch to be created
     * @returns chatbot_DispatchResponse OK
     * @throws ApiError
     */
    public static postV1AdminChatbotDispatch({
        request,
    }: {
        /** CreateDispatchRequest **/
        request: chatbot_CreateDispatchRequest
    }): CancelablePromise<chatbot_DispatchResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/chatbot/dispatch",
            body: request,
        })
    }

    /**
     * Get dispatches
     * Gets all available dispatches
     * @returns chatbot_DispatchListResponse OK
     * @throws ApiError
     */
    public static postV1AdminChatbotDispatchList({
        request,
    }: {
        /** DispatchListRequest **/
        request: chatbot_DispatchListRequest
    }): CancelablePromise<chatbot_DispatchListResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/chatbot/dispatch/list",
            body: request,
        })
    }

    /**
     * Get dispatch
     * Gets one dispatch by ID
     * @returns chatbot_DispatchResponse OK
     * @throws ApiError
     */
    public static getV1AdminChatbotDispatch({
        dispatchId,
    }: {
        /** Dispatch ID **/
        dispatchId: string
    }): CancelablePromise<chatbot_DispatchResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/chatbot/dispatch/{dispatch_id}",
            path: {
                dispatch_id: dispatchId,
            },
        })
    }

    /**
     * Update dispatch
     * Takes a body with dispatch to be updated
     * @returns chatbot_DispatchResponse OK
     * @throws ApiError
     */
    public static putV1AdminChatbotDispatch({
        request,
        dispatchId,
    }: {
        /** CreateDispatchRequest **/
        request: chatbot_CreateDispatchRequest
        /** Dispatch ID **/
        dispatchId: string
    }): CancelablePromise<chatbot_DispatchResponse> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/chatbot/dispatch/{dispatch_id}",
            path: {
                dispatch_id: dispatchId,
            },
            body: request,
        })
    }

    /**
     * Delete dispatch
     * Takes a body with dispatch to be deleted
     * @returns any OK
     * @throws ApiError
     */
    public static deleteV1AdminChatbotDispatch({
        dispatchId,
    }: {
        /** Dispatch ID **/
        dispatchId: string
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/chatbot/dispatch/{dispatch_id}",
            path: {
                dispatch_id: dispatchId,
            },
        })
    }

    /**
     * Publish Dispatch
     * Publish dispatch by ID
     * @returns avy_api_pkg_segment_PublishResourceResponse OK
     * @throws ApiError
     */
    public static putV1AdminChatbotDispatchPublish({
        dispatchId,
        request,
    }: {
        /** ID of dispatch to publish **/
        dispatchId: string
        /** Request body to set where informationItems are published **/
        request: avy_api_pkg_segment_PublishResourceRequest
    }): CancelablePromise<avy_api_pkg_segment_PublishResourceResponse> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/chatbot/dispatch/{dispatch_id}/publish",
            path: {
                dispatch_id: dispatchId,
            },
            body: request,
        })
    }

    /**
     * Get message types
     * Get all available message types
     * @returns chatbot_GetMessageTypesResponse OK
     * @throws ApiError
     */
    public static getV1AdminChatbotMessageTypes(): CancelablePromise<chatbot_GetMessageTypesResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/chatbot/message-types",
        })
    }
}
