import { Palette, useTheme, Tooltip, Box } from "@mui/material"
import {
    ErrorRounded,
    FlagRounded,
    InfoRounded,
    OutlinedFlagRounded,
    WarningRounded,
} from "@mui/icons-material"
import { ReactNode } from "react"
import { t } from "@lingui/macro"

import { community_CommunityFlags } from "src/api"
import { convertHexToRgba } from "src/helpers/convertHexToRgba"

// Map the flag to the corresponding icon component
export const flagToIconMap = {
    community_guidelines_violation: WarningRounded,
    needs_admin_attention: InfoRounded,
    reported_by_admin: FlagRounded,
    security_risk: ErrorRounded,
    reported_by_tenant: OutlinedFlagRounded,
}

// Map the flag to the corresponding color
export const flagToColorMap = (palette: Palette) => ({
    community_guidelines_violation: palette.utility.clementine.main,
    needs_admin_attention: palette.info.main,
    reported_by_admin: palette.error.main,
    security_risk: palette.error.main,
    reported_by_tenant: palette.utility.clementine.main,
})

// Helper function to determine the color
const getColor = (isLightColor: boolean, color: string) =>
    isLightColor ? convertHexToRgba(color, 0.5) : color

export const FlagRenderer = (
    flags: community_CommunityFlags,
    flagsInComments?: community_CommunityFlags,
): ReactNode => {
    const { palette } = useTheme()
    const colorMap = flagToColorMap(palette)

    const flagOptions = {
        security_risk: t`community-posts-view.flags.security-risk`,
        community_guidelines_violation: t`community-posts-view.flags.community-guidelines-violation`,
        needs_admin_attention: t`community-posts-view.flags.needs-admin-attention`,
        reported_by_admin: t`community-posts-view.flags.reported-by-admin`,
        reported_by_tenant: t`community-posts-view.flags.reported-by-tenant`,
    }

    return Object.entries(flags).map(([key, value]) => {
        const isInFlags = value === true
        const isInFlagsInComments =
            flagsInComments?.[key as keyof community_CommunityFlags] === true

        // If flagsInComments is null, we are rendering the flags in the comments
        if (flagsInComments == null) {
            if (isInFlags) {
                const IconComponent =
                    flagToIconMap[key as keyof typeof flagToIconMap]
                if (IconComponent === undefined) return null
                const color = colorMap[key as keyof typeof colorMap]
                return <IconComponent key={key} htmlColor={color} />
            }
            return null
        }

        // If the flag is not in the flags or flagsInComments, don't render it
        if (!isInFlags && !isInFlagsInComments) {
            return null
        }

        // Determine if we should use the lightened color
        const isLightColor = isInFlagsInComments && !isInFlags

        const IconComponent = flagToIconMap[key as keyof typeof flagToIconMap]

        if (IconComponent === undefined) return null

        const color = colorMap[key as keyof typeof colorMap]
        const finalColor = getColor(isLightColor, color) // Use isLightColor here

        return (
            <Box sx={{ cursor: "pointer" }}>
                <Tooltip
                    arrow
                    title={flagOptions[key as keyof typeof flagToIconMap] ?? ""}
                >
                    <IconComponent key={key} htmlColor={finalColor} />
                </Tooltip>
            </Box>
        )
    })
}
