/* eslint-disable react-hooks/exhaustive-deps */
import { MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material"
import { observer } from "mobx-react"
import { useEffect } from "react"
import { t } from "@lingui/macro"

import { CreatePropertyOwnerModalStore } from "./store"

import { IntegrationsDropdown } from "./components/IntegrationsDropdown"

import { FormFieldSet } from "src/components/FormFieldSet"
import { useStore } from "src/store/lib/useStore"
import { IntegrationType } from "src/types/integration"

import { SwitchInput } from "src/components/Inputs"
import { environment } from "src/config/variables"

interface ISettingsSectionProps {
    legalName?: string
    integrationType?: string
    isDisabled?: boolean
    fastapiConnectorIntegrationId?: number
    mfaRequired?: boolean
    syncTenantContact?: boolean
}

export const SettingsSection = observer(
    ({
        legalName,
        integrationType,
        isDisabled,
        fastapiConnectorIntegrationId,
        mfaRequired,
        syncTenantContact,
    }: ISettingsSectionProps) => {
        const store = useStore(CreatePropertyOwnerModalStore)

        useEffect(() => {
            store.fields.set("legalName", legalName ?? "")
            store.fields.set("integrationType", integrationType ?? "")
            store.fields.set(
                "selectedConnectorId",
                fastapiConnectorIntegrationId ?? 0,
            )
            store.fields.set("mfaRequired", mfaRequired ?? false)
            store.fields.set("syncTenantContact", syncTenantContact ?? false)
        }, [
            legalName,
            integrationType,
            store.fields,
            mfaRequired,
            syncTenantContact,
        ])

        useEffect(() => {
            ;(async () => {
                if (
                    store.fields.get("integrationType") ===
                    IntegrationType.FastApi
                )
                    await store.getFastApiIntegrations()
            })()
        }, [store, store.fields.get("integrationType")])

        const handleDropdownChange = (event: SelectChangeEvent) => {
            if (event.target.value !== IntegrationType.FastApi) {
                store.fields.set("selectedConnectorId", 0)
                store.fields.set("syncTenantContact", false)
            }

            store.fields.set(
                "integrationType",
                event.target.value as IntegrationType,
            )
        }

        const renderDropdown = () => (
            <IntegrationsDropdown
                connectors={store.fields.get("connectorList") ?? []}
                selectedValue={String(store.fields.get("selectedConnectorId"))}
                onSelectionChange={(value) => {
                    store.fields.set("selectedConnectorId", value)
                }}
                errorMessage={
                    store.fields.error("selectedConnectorId") as string
                }
            />
        )

        return (
            <>
                <FormFieldSet
                    header={t`create-new-property-owner-modal.legal-name`}
                    tooltip={t`create-new-property-owner-modal.legal-name`}
                >
                    <TextField
                        label={t`create-new-property-owner-modal.legal-name`}
                        placeholder={t`create-new-property-owner-modal.legal-name`}
                        onChange={store.fields.setter("legalName")}
                        value={store.fields.get("legalName")}
                        helperText={store.fields.error("legalName")}
                        error={Boolean(store.fields.error("legalName"))}
                        disabled={isDisabled}
                    />
                </FormFieldSet>
                <FormFieldSet
                    header={t`create-new-property-owner-modal.integration-type`}
                    tooltip={t`create-new-property-owner-modal.integration-type`}
                >
                    <Select
                        data-testid="integration-type-dropdown"
                        labelId="integration-type-label"
                        id="integration-type"
                        value={store.fields.get("integrationType") ?? ""}
                        onChange={handleDropdownChange}
                        error={Boolean(store.fields.error("integrationType"))}
                        sx={{ width: "100%" }}
                    >
                        <MenuItem
                            value={IntegrationType.FastApi}
                        >{t`create-new-property-owner-modal.fast-api`}</MenuItem>
                        <MenuItem
                            value={IntegrationType.Other}
                        >{t`create-new-property-owner-modal.other`}</MenuItem>
                    </Select>
                </FormFieldSet>

                {store.fields.get("integrationType") ===
                    IntegrationType.FastApi && renderDropdown()}
                {store.fields.get("integrationType") ===
                    IntegrationType.FastApi &&
                store.fields.get("selectedConnectorId") !== 0 ? (
                    <FormFieldSet
                        header={t`create-new-property-owner-modal.contact-sync`}
                    >
                        <SwitchInput
                            data-testid="sync-tenant-contact"
                            checked={store.fields.get("syncTenantContact")}
                            label={
                                store.fields.get("syncTenantContact") ?? false
                                    ? t`create-new-property-owner-modal.enabled`
                                    : t`create-new-property-owner-modal.disabled`
                            }
                            onSwitchChange={(value) =>
                                store.fields.set("syncTenantContact", value)
                            }
                            color={
                                store.fields.get("syncTenantContact") ?? false
                                    ? `success`
                                    : `default`
                            }
                        />
                    </FormFieldSet>
                ) : null}
                {/* TODO (rohan / avneesh): un hide from production when full feature is ready */}
                {environment !== "production" && (
                    <FormFieldSet
                        header={t`create-new-property-owner-modal.two-factor`}
                    >
                        <SwitchInput
                            checked={store.fields.get("mfaRequired")}
                            label={
                                store.fields.get("mfaRequired") ?? false
                                    ? t`create-new-property-owner-modal.enabled`
                                    : t`create-new-property-owner-modal.disabled`
                            }
                            onSwitchChange={(value) =>
                                store.fields.set("mfaRequired", value)
                            }
                            color={
                                store.fields.get("mfaRequired") ?? false
                                    ? `success`
                                    : `default`
                            }
                        />
                    </FormFieldSet>
                )}
            </>
        )
    },
)
