import { t } from "@lingui/macro"
import {
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    Switch,
} from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"

import { environment } from "src/config/variables"

import { GlobalStore } from "src/store"

import { DateTimePicker } from "src/components/DateTimePicker"
import { FormFieldSet } from "src/components/FormFieldSet"
import { SegmentPickerField } from "src/components/SegmentPickerField"
import { NoticeBoardPostDetailStore } from "src/modals/notice-board-post-detail/store"
import { useStore } from "src/store/lib/useStore"

interface IProps {
    mode?: string
}

export const PublishSettingsSection = observer((props: IProps) => {
    const store = useStore(NoticeBoardPostDetailStore)
    const globalStore = useStore(GlobalStore)
    useEffect(() => {
        props.mode === "Copy" && store.fields.set("segmentIds", [])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.mode])

    const handleIncludeInChatbotChange = useCallback(
        (_: unknown, checked: boolean) => {
            store.fields.set("includeInChatbot", checked)
        },
        [store.fields],
    )

    return (
        <>
            <FormFieldSet
                header={t`notice-board-post-detail-modal.publish-settings-section.receivers-header`}
                tooltip={t`notice-board-post-detail-modal.publish-settings-section.receivers-tooltip`}
            >
                <SegmentPickerField
                    value={store.fields.get("segmentIds")}
                    accessGroupId={store.accessGroupId}
                    onChange={(segments) =>
                        store.fields.set("segmentIds", segments)
                    }
                    disabled={store.fields.get("accessType") === "READ"}
                />
            </FormFieldSet>
            <FormFieldSet
                header={t`notice-board-post-detail-modal.publish-settings-section.publish-date-header`}
                tooltip={t`notice-board-post-detail-modal.publish-settings-section.publish-date-tooltip`}
            >
                <Stack
                    spacing={2}
                    direction={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                    }}
                >
                    <DateTimePicker
                        value={store.fields.get("publishAt")}
                        onChangePicker={(date: Date | null) => {
                            store.fields.set("publishAt", date)
                        }}
                        label={t`notice-board-post-detail-modal.publish-settings-section.publish-start-label`}
                        maxDate={store.fields.get("publishAt")}
                        inputId="publish-at"
                        disabled={store.fields.get("accessType") === "READ"}
                    />
                    <DateTimePicker
                        value={store.fields.get("unPublishAt")}
                        onChangePicker={(date: Date | null) => {
                            store.fields.set("unPublishAt", date)
                        }}
                        label={t`notice-board-post-detail-modal.publish-settings-section.publish-end-label`}
                        minDate={store.fields.get("unPublishAt")}
                        inputId="un-publish-at"
                        disabled={store.fields.get("accessType") === "READ"}
                    />
                </Stack>
            </FormFieldSet>
            <FormFieldSet
                header={t`notice-board-post-detail-modal.publish-settings-section.pinned-post-header`}
                tooltip={t`notice-board-post-detail-modal.publish-settings-section.pinned-post-tooltip`}
            >
                <RadioGroup
                    aria-label="hello"
                    value={store.fields.get("pinned")}
                    onChange={(_, value) => {
                        store.fields.set("pinned", value === "true")
                    }}
                >
                    <Stack direction="row" spacing={1}>
                        <FormControlLabel
                            label={t`notice-board-post-detail-modal.publish-settings-section.pinned-post-yes`}
                            value={true}
                            disabled={store.fields.get("accessType") === "READ"}
                            control={<Radio color="info" />}
                        />
                        <FormControlLabel
                            label={t`notice-board-post-detail-modal.publish-settings-section.pinned-post-no`}
                            value={false}
                            disabled={store.fields.get("accessType") === "READ"}
                            control={<Radio color="info" />}
                        />
                    </Stack>
                </RadioGroup>
            </FormFieldSet>
            <FormFieldSet
                header={t`notice-board-post-detail-modal.publish-settings-section.push-notificaiton-header`}
                description={t`notice-board-post-detail-modal.publish-settings-section.push-notification-description`}
            >
                <RadioGroup
                    value={store.fields.get("sendPushNotification")}
                    onChange={(_, value) => {
                        store.fields.set(
                            "sendPushNotification",
                            value === "true",
                        )
                    }}
                >
                    <FormControlLabel
                        label={t`notice-board-post-detail-modal.publish-settings-section.push-notificaiton-no`}
                        value={false}
                        disabled={store.fields.get("accessType") === "READ"}
                        control={<Radio color="info" />}
                    />
                    <FormControlLabel
                        label={t`notice-board-post-detail-modal.publish-settings-section.push-notificaiton-yes`}
                        value={true}
                        disabled={store.fields.get("accessType") === "READ"}
                        control={<Radio color="info" />}
                    />
                </RadioGroup>
            </FormFieldSet>
            {environment !== "production" &&
                globalStore.session.hasAccessToModule("ai_chatbot") && (
                    <>
                        <FormControlLabel
                            checked={store.fields.get("includeInChatbot")}
                            onChange={handleIncludeInChatbotChange}
                            control={<Switch color="success" />}
                            label={t`edit-information-item-modal.include-in-chat-bot-label`}
                            sx={{ ml: 1, mt: 2 }}
                        />
                    </>
                )}
        </>
    )
})
