import { t } from "@lingui/macro"
import { Button, Stack, useTheme } from "@mui/material"
import { observer } from "mobx-react"
import React, { useCallback } from "react"

import { useNavigate } from "react-router-dom"

import { Icon } from "src/components/icons"
import { Cross16 } from "src/components/icons/Cross16"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

interface IProps {
    children?: React.ReactNode
    onCloseClick?: (event: React.MouseEvent<Element>) => void
    path?: string
}

export const ModalHeader = observer((props: IProps) => {
    const theme = useTheme()
    const gstore = useStore(GlobalStore)
    const navigate = useNavigate()

    const handleCloseClick = useCallback(() => {
        if (props.path != null && navigate != null) {
            navigate(props.path)
        }
        gstore.modals.pop()
    }, [gstore.modals, navigate, props.path])

    return (
        <Stack paddingBottom={1} justifyContent="space-between" direction="row">
            <Button
                data-testid="close-button"
                startIcon={
                    <Icon
                        icon={<Cross16 />}
                        size={20}
                        color={theme.palette.info.main}
                    />
                }
                color="info"
                onClick={
                    props.onCloseClick != null
                        ? props?.onCloseClick
                        : handleCloseClick
                }
            >
                {t`modal-header-component.close`}
            </Button>
            {props.children}
        </Stack>
    )
})
