import { Alert, Box, useMediaQuery, useTheme } from "@mui/material"
import { observer } from "mobx-react"
import { useEffect, useMemo } from "react"

import { AuthorSelect } from "./Author"

import { PostWrapper, HeaderWrapper, AuthorWrapper } from "./styled"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { ModalHeader } from "src/components/ModalHeader"
import { CommentForm } from "src/modals/view-community-post/CommentForm"
import { Head } from "src/modals/view-community-post/Head"
import { Post } from "src/modals/view-community-post/Post"

import {
    IAuthor,
    ViewCommunityPostStore,
} from "src/modals/view-community-post/store"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"

interface IProps {
    id: number
}

const View = observer((props: IProps) => {
    const store = useStore(ViewCommunityPostStore)
    const gstore = useStore(GlobalStore)
    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

    const authors: IAuthor[] = useMemo(() => {
        const authors: IAuthor[] = [
            { id: "self", name: gstore.session.user?.name ?? "" },
        ]

        if (gstore.session.hasAccessToModule("community_author_name")) {
            const propertyOwners = gstore.session.propertyOwners
            for (const propertyOwner of propertyOwners) {
                authors.push({
                    id: `po-${propertyOwner.id}`,
                    name: propertyOwner.legalName ?? "",
                })
            }

            const legalEntities = propertyOwners.flatMap(
                (propertyOwner) => propertyOwner.legalEntities ?? [],
            )

            for (const legalEntity of legalEntities) {
                authors.push({
                    id: `le-${legalEntity.id}`,
                    name: legalEntity.legalName ?? "",
                })
            }
        }

        return authors
    }, [gstore.session])

    useEffect(() => {
        ;(async () => {
            await store.init(props.id, authors)
        })()
    }, [store, props.id, authors])

    if (
        gstore.loading.is(ViewCommunityPostStore.LoadingKeys.init) ||
        !store.initialized
    ) {
        return <CenteredSpinner />
    }

    return (
        <Box display="flex" flexDirection="column" height="100%">
            <HeaderWrapper smallScreen={smallScreen}>
                <ModalHeader />
                {gstore.session.hasAccessToModule("community_author_name") && (
                    <AuthorWrapper smallScreen={smallScreen}>
                        <AuthorSelect />
                    </AuthorWrapper>
                )}
            </HeaderWrapper>
            {Boolean(store.commentForm.error("generic")) && (
                <Alert severity="error">
                    {store.commentForm.error("generic")}
                </Alert>
            )}
            <PostWrapper>
                <Head />
                <Post />
                <CommentForm />
            </PostWrapper>
        </Box>
    )
})

export const ViewCommunityPostModal = observer((props: IProps) => (
    <StoreProvider Store={ViewCommunityPostStore}>
        <View {...props} />
    </StoreProvider>
))
