import { observer } from "mobx-react"
import { useCallback } from "react"

import { AttachmentsField } from "src/components/AttachmentsField"
import { NoticeBoardPostDetailStore } from "src/modals/notice-board-post-detail/store"
import { useStore } from "src/store/lib/useStore"

export const ImagesAndDocumentSection = observer(() => {
    const store = useStore(NoticeBoardPostDetailStore)

    const handleChange = useCallback(
        (images: IFile[], documents: IFile[], type: IFileType) => {
            type === "image" && store.fields.set("imageAttachments", images)
            type === "document" &&
                store.fields.set("documentAttachments", documents)
        },
        [store.fields],
    )

    return (
        <AttachmentsField
            onChange={handleChange}
            images={store.fields.get("imageAttachments")}
            documents={store.fields.get("documentAttachments")}
            disabled={store.fields.get("accessType") === "READ"}
            onError={(documentsErrorMessage: string) => {
                store.fields.setError(
                    "documentAttachments",
                    documentsErrorMessage,
                )
            }}
            documentError={
                store.fields.error("documentAttachments") ?? undefined
            }
        />
    )
})
