import { Box, Stack } from "@mui/material"
import { useEffect, useRef, useState } from "react"

import AvyChatMessage from "src/components/Chat/Thread/Message"
import AvyChatSystemMessage from "src/components/Chat/Thread/SystemMessage"
import { IAvyChatThreadProps } from "src/components/Chat/types/chatThread"
import { ChatMessageJourney } from "src/components/ChatMessageJourney"
import { EMessageType } from "src/types/chat-message/chatMessage"

export const AvyChatThread = ({
    messages,
    boxWrapperStyles,
    handleSourceClick,
}: IAvyChatThreadProps) => {
    const bottomRef = useRef<HTMLDivElement>(null)

    const [isOpenID, setIsOpenID] = useState<string | undefined>("")

    useEffect(() => {
        bottomRef.current?.scrollIntoView()
    }, [messages.length])

    return (
        <Box overflow="auto" style={{ ...boxWrapperStyles }} width={"100%"}>
            <Stack spacing={2} padding={0}>
                {messages.map((message) => {
                    const { messageType, messageId } = message
                    if (
                        messageType !== EMessageType.SystemMessage &&
                        messageType !== EMessageType.SystemMessageV2
                    ) {
                        return (
                            <AvyChatMessage
                                key={`message-${messageId}`}
                                message={message}
                                handleSourceClick={handleSourceClick}
                                setMessageJourneyID={setIsOpenID}
                            />
                        )
                    }

                    const systemMessage = message.systemMessage
                    if (systemMessage == null) return null

                    return (
                        <AvyChatSystemMessage
                            key={`systemStatus-${systemMessage.systemMessageId}`}
                            message={systemMessage}
                        />
                    )
                })}
            </Stack>
            {bottomRef != null && <div ref={bottomRef} />}
            <ChatMessageJourney
                messages={messages}
                isOpenID={isOpenID}
                setIsOpenID={setIsOpenID}
                handleSourceClick={handleSourceClick}
            />
        </Box>
    )
}
