/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { admin_CopyFeatureFlagsRequest } from "../models/admin_CopyFeatureFlagsRequest"
import type { admin_GetFeatureConfigResponse } from "../models/admin_GetFeatureConfigResponse"
import type { admin_GetFeatureFlagsRequest } from "../models/admin_GetFeatureFlagsRequest"
import type { admin_GetFeatureFlagsResponse } from "../models/admin_GetFeatureFlagsResponse"
import type { admin_UpdateFeatureConfigRequest } from "../models/admin_UpdateFeatureConfigRequest"
import type { admin_UpdateFeatureFlagsRequest } from "../models/admin_UpdateFeatureFlagsRequest"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class FeaturesAdminService {
    /**
     * Get feature config
     * Get feature config for a legal entity
     * @returns admin_GetFeatureConfigResponse OK
     * @throws ApiError
     */
    public static getV1AdminFeaturesConfig({
        legalEntityId,
    }: {
        /** Legal entity id **/
        legalEntityId: number
    }): CancelablePromise<admin_GetFeatureConfigResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/features/config",
            query: {
                legal_entity_id: legalEntityId,
            },
        })
    }

    /**
     * Update feature config
     * Update feature config for legal entities
     * @returns any OK
     * @throws ApiError
     */
    public static putV1AdminFeaturesConfig({
        request,
    }: {
        /** Request body to update feature config **/
        request: admin_UpdateFeatureConfigRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/features/config",
            body: request,
        })
    }

    /**
     * Update feature flags
     * Update feature flags for legal entities
     * @returns any OK
     * @throws ApiError
     */
    public static putV1AdminFeaturesFlag({
        request,
    }: {
        /** Request body to update feature flags **/
        request: admin_UpdateFeatureFlagsRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/features/flag",
            body: request,
        })
    }

    /**
     * Copy feature flags
     * Copy feature flags from a legal entity to other legal entities
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminFeaturesFlagCopy({
        request,
    }: {
        /** Request body to copy feature flags **/
        request: admin_CopyFeatureFlagsRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/features/flag/copy",
            body: request,
        })
    }

    /**
     * Has feature flags
     * Returns ids of legal entities who have feature flags
     * @returns number OK
     * @throws ApiError
     */
    public static getV1AdminFeaturesFlagHasFlags({
        propertyOwnerId,
    }: {
        /** Property owner id **/
        propertyOwnerId: number
    }): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/features/flag/has-flags",
            query: {
                property_owner_id: propertyOwnerId,
            },
        })
    }

    /**
     * Get feature flags
     * Get enabled feature flags for legal entities
     * @returns admin_GetFeatureFlagsResponse OK
     * @throws ApiError
     */
    public static postV1AdminFeaturesFlagList({
        request,
    }: {
        /** Request body to get feature flags **/
        request: admin_GetFeatureFlagsRequest
    }): CancelablePromise<admin_GetFeatureFlagsResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/features/flag/list",
            body: request,
        })
    }
}
