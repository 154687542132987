import { Box, BoxProps, styled } from "@mui/material"
import { observer } from "mobx-react"
import React, { useCallback, useRef } from "react"
import { t } from "@lingui/macro"
import FocusLock from "react-focus-lock"

import { ConfirmModal } from "src/modals/confirm"
import { ErrorBoundary } from "src/components/ErrorBoundary"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { IModalOptions } from "src/types/modal/modal-options"
import { isMobile } from "src/lib/mobile"

interface IModalProps extends BoxProps {
    closing: boolean
    variant: IModalOptions["variant"]
}

interface IOverlayProps extends BoxProps {
    closing: boolean
}

const Modal = styled(Box, {
    shouldForwardProp: () => true,
})<IModalProps>(({ closing, variant, theme }) => ({
    background: "#fff",
    overflow: "auto",
    zIndex: theme.zIndex.modal,
    boxShadow: "0 8px 16px rgb(8 8 8 / 24%)",
    ...(variant === "right-slide-in"
        ? {
              height: "100vh",
              position: "fixed",
              boxSizing: "border-box",
              animation: closing
                  ? "right-slide-out 550ms cubic-bezier(0.23, 1, 0.32, 1)"
                  : "right-slide-in 550ms cubic-bezier(0.23, 1, 0.32, 1)",
              [theme.breakpoints.down("md")]: {
                  width: "100%",
                  inset: 0,
                  padding: isMobile ? "1rem 1rem 4.375rem" : "1rem",
              },
              [theme.breakpoints.up("md")]: {
                  width: "40%",
                  minWidth: "768px",
                  inset: "0 0 0 auto",
                  padding: "2rem",
              },
          }
        : {}),
    ...(variant === "slide-up-w995"
        ? {
              transform: "translateY(0)",
              animation: closing
                  ? "up-slide-out 550ms cubic-bezier(0.23, 1, 0.32, 1)"
                  : "up-slide-in 550ms cubic-bezier(0.23, 1, 0.32, 1)",
              [theme.breakpoints.down("md")]: {
                  position: "fixed",
                  inset: 0,
              },
              [theme.breakpoints.up("md")]: {
                  borderRadius: theme.shape.borderRadius,
                  maxWidth: "995px",
                  width: "100%",
                  height: "90vh",
              },
          }
        : {}),
    ...(variant === "slide-up-w600"
        ? {
              transform: "translateY(0)",
              animation: closing
                  ? "up-slide-out 550ms cubic-bezier(0.23, 1, 0.32, 1)"
                  : "up-slide-in 550ms cubic-bezier(0.23, 1, 0.32, 1)",
              borderRadius: theme.shape.borderRadius,
              width: "100%",
              maxHeight: "90vh",
              [theme.breakpoints.down("md")]: {
                  maxWidth: "90vw",
              },
              [theme.breakpoints.up("md")]: {
                  maxWidth: "600px",
              },
          }
        : {}),
}))

const Overlay = styled(Box, {
    shouldForwardProp: () => true,
})<IOverlayProps>(({ closing, theme }) => ({
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(8, 8, 8, 0.32)",
    zIndex: theme.zIndex.modal,
    animation: closing
        ? "fade-out 550ms cubic-bezier(0.23, 1, 0.32, 1)"
        : "fade-in 550ms cubic-bezier(0.23, 1, 0.32, 1)",
}))

export const ModalFragment = observer(() => {
    const gstore = useStore(GlobalStore)
    const overlays = useRef<HTMLDivElement[]>([])

    const showConfirmModal = useCallback(() => {
        gstore.modals.open(
            () => (
                <ConfirmModal
                    onConfirm={(confirmed: boolean) => {
                        if (confirmed) {
                            gstore.modals.pop()
                        }
                    }}
                    title={t`modal-index.confirm-close-modal-title`}
                    content={t`modal-index.confirm-close-modal-content`}
                />
            ),
            { variant: "slide-up-w600" },
        )
    }, [gstore.modals])

    const handleOverlayClick = useCallback(
        (event: React.MouseEvent) => {
            if (
                overlays.current.some(
                    (overlay) =>
                        event.target instanceof Node &&
                        event.target.isSameNode(overlay),
                )
            ) {
                const thisModal =
                    gstore.modals.active[gstore.modals.active.length - 1]

                if (
                    thisModal.confirmOnOverlayClick != null &&
                    thisModal.confirmOnOverlayClick()
                ) {
                    showConfirmModal()
                } else {
                    gstore.modals.pop()
                }
            }
        },
        [gstore.modals, showConfirmModal],
    )

    return (
        <>
            {gstore.modals.active.map((modal, i) => {
                const { Component } = modal
                return (
                    <FocusLock key={modal.id} returnFocus={true}>
                        <Overlay
                            ref={(el: HTMLDivElement) =>
                                (overlays.current[i] = el)
                            }
                            closing={modal.isClosing}
                            onClick={handleOverlayClick}
                            data-testid="ModalFragment/Overlay"
                        >
                            <Modal
                                closing={modal.isClosing}
                                variant={modal.options.variant}
                            >
                                <ErrorBoundary>
                                    <Component />
                                </ErrorBoundary>
                            </Modal>
                        </Overlay>
                    </FocusLock>
                )
            })}
        </>
    )
})
