import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import {
    admin_CreateMessageRequest,
    admin_MessageAttachment,
    MessageAdminService,
    message_MessageAttachment,
    message_UpdateMessage,
    message_Message,
} from "src/api"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { parseDate } from "src/lib/date"
import { persistFiles } from "src/lib/file"
import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"

interface IFormFields {
    adminTitle: string
    title: string
    text: string
    accessType: string
    pinned: boolean
    sendPushNotification: boolean
    publishAt: Date | null
    unPublishAt: Date | null
    eventStartsAt: Date | null
    eventEndsAt: Date | null
    includeInChatbot: boolean
    segmentIds: number[]
    imageAttachments: IFile[]
    documentAttachments: IFile[]
}

type IPersistedNoticeBoardPostFile = Omit<IPersistedFile, "type"> & {
    type: "image" | "doc"
}

type IFieldsAndRequestKeysMap<Type> = {
    [Property in keyof Type]: keyof message_Message
}

export class NoticeBoardPostDetailStore {
    static Context = React.createContext<NoticeBoardPostDetailStore | null>(
        null,
    )
    static LoadingKeys = createLoadingKeys("init", "submit")
    fields = new FormFields<IFormFields>({
        adminTitle: "",
        title: "",
        text: "",
        accessType: "",
        pinned: false,
        sendPushNotification: true,
        publishAt: null,
        unPublishAt: null,
        eventEndsAt: null,
        eventStartsAt: null,
        includeInChatbot: false,
        segmentIds: [],
        imageAttachments: [],
        documentAttachments: [],
    })
    fieldsAndRequestKeysMap: IFieldsAndRequestKeysMap<IFormFields> = {
        adminTitle: "admin_title",
        title: "title",
        text: "text",
        pinned: "pinned",
        accessType: "access_type",
        sendPushNotification: "send_push",
        publishAt: "publish_at",
        unPublishAt: "unpublish_at",
        eventEndsAt: "event_ends_at",
        eventStartsAt: "event_starts_at",
        segmentIds: "segment_ids",
        imageAttachments: "attachments",
        documentAttachments: "attachments",
        includeInChatbot: "include_in_chatbot",
    }
    private id?: number

    constructor() {
        makeAutoObservable(this)
    }

    private _accessGroupId?: number

    get accessGroupId() {
        return this._accessGroupId
    }

    setAccessGroupId(id?: number) {
        this._accessGroupId = id
    }

    @loads(() => NoticeBoardPostDetailStore.LoadingKeys.init)
    async init(accessGroupId: number, id?: number) {
        this.setId(id)

        if (id != null) {
            const response = await MessageAdminService.noticeBoardGetMessage({
                messageId: id,
            })
            this.setAccessGroupId(response.access_group_id ?? undefined)
            this.initFields({
                adminTitle: response.admin_title ?? "",
                title: response.title ?? "",
                text: response.text ?? "",
                pinned: response.pinned ?? false,
                accessType: response.access_type ?? "",
                sendPushNotification: response.send_push ?? false,
                publishAt: parseDate(response.publish_at),
                unPublishAt: parseDate(response.unpublish_at),
                eventStartsAt: parseDate(response.event_starts_at),
                eventEndsAt: parseDate(response.event_ends_at),
                segmentIds: response.segment_ids ?? [],
                imageAttachments: this.toPersistedFiles(
                    response.attachments ?? [],
                    "image",
                ),
                documentAttachments: this.toPersistedFiles(
                    response.attachments ?? [],
                    "doc",
                ),
                includeInChatbot: response.include_in_chatbot ?? false,
            })
        } else {
            this.setAccessGroupId(accessGroupId)
        }
    }

    @loads(() => NoticeBoardPostDetailStore.LoadingKeys.submit)
    async submit(mode: string) {
        const { data } = this.fields

        if (this._accessGroupId == null) {
            this.fields.setErrors({ accessGroupId: t`errors.required` })
            return
        }

        await this.fields.catchErrors(async () => {
            if (this.id == null || mode === "Copy") {
                const request: admin_CreateMessageRequest = {
                    access_group_id: this._accessGroupId ?? 0,
                    admin_title: data.adminTitle,
                    attachments: this.toMessageAttachments(
                        await this.persistAttachments(
                            data.documentAttachments,
                            data.imageAttachments,
                        ),
                    ),
                    event_ends_at: data.eventEndsAt?.toISOString(),
                    event_starts_at: data.eventStartsAt?.toISOString(),
                    pinned: data.pinned,
                    publish_at: data.publishAt?.toISOString(),
                    segment_ids: data.segmentIds ?? [],
                    send_push: data.sendPushNotification,
                    text: data.text,
                    title: data.title,
                    unpublish_at: data.unPublishAt?.toISOString(),
                    include_in_chatbot: data.includeInChatbot,
                }

                const response =
                    await MessageAdminService.noticeBoardCreateMessage({
                        request,
                    })

                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "messages",
                        action: "create",
                        item: {
                            id: response.messageID ?? 0,
                            name: data.title,
                        },
                    },
                })
            } else {
                let updateRequest: message_UpdateMessage = {
                    access_group_id: this._accessGroupId ?? 0,
                    admin_title: data.adminTitle,
                    attachments: this.toMessageAttachments(
                        await this.persistAttachments(
                            data.documentAttachments,
                            data.imageAttachments,
                        ),
                    ),
                    event_ends_at: data.eventEndsAt?.toISOString(),
                    event_starts_at: data.eventStartsAt?.toISOString(),
                    pinned: data.pinned,
                    publish_at: data.publishAt?.toISOString(),
                    send_push: data.sendPushNotification,
                    text: data.text,
                    title: data.title,
                    unpublish_at: data.unPublishAt?.toISOString(),
                    include_in_chatbot: data.includeInChatbot,
                }

                await MessageAdminService.noticeBoardUpdateMessage({
                    request: updateRequest,
                    messageId: this.id,
                })

                await MessageAdminService.noticeBoardPublishMessage({
                    messageId: this.id,
                    request: { published_in: data.segmentIds },
                })

                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "messages",
                        action: "update",
                        item: {
                            id: this.id,
                            name: data.title,
                        },
                    },
                })
            }
        })
    }

    private setId(id?: number) {
        this.id = id
    }

    private initFields(fields: IFormFields) {
        this.fields.init(fields)
    }

    private toMessageAttachments(
        files: IPersistedNoticeBoardPostFile[],
    ): admin_MessageAttachment[] {
        return files.map((file) => ({
            name: file.name,
            url: file.url,
            // The api docs are incorrect so we need to type assert this.
            attachment_type:
                file.type as admin_MessageAttachment["attachment_type"],
        }))
    }

    private toPersistedFiles(
        messageAttachments: message_MessageAttachment[],
        type: "image" | "doc",
    ) {
        return messageAttachments
            .filter((attachment) => attachment.attachment_type === type)
            .map((attachment) => this.toPesistedFile(attachment, type))
    }

    private toPesistedFile(
        messageAttachment: message_MessageAttachment,
        type: "image" | "doc",
    ): IPersistedFile {
        return {
            name: messageAttachment.name ?? "",
            url: messageAttachment.url ?? "",
            // The document type is called "doc" for this particular api. Rename
            // it to "document", to be consistent with other api:s.
            type: type === "doc" ? "document" : type,
        }
    }

    private async persistAttachments(
        documents: IFile[],
        images: IFile[],
    ): Promise<IPersistedNoticeBoardPostFile[]> {
        const persistedDocuments = await persistFiles(documents, "document")
        const persistedImages = await persistFiles(images, "image")
        return [...persistedDocuments, ...persistedImages].map((file) => ({
            ...file,
            // The document type is called "doc" for this particular api. Map
            // back type for documents from `document` to `doc`.
            type: file.type === "document" ? ("doc" as const) : file.type,
        }))
    }
}
