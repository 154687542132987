import { makeAutoObservable } from "mobx"
import React from "react"

import { ContactFormAdminService } from "src/api"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { parseDate } from "src/lib/date"

import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"
import { ContactFormStatus, parseStatus } from "src/types/contact-form"

interface IFormFields {
    location: string
    status: ContactFormStatus
    title: string
    attachments: string[]
    description: string
    tenantFullName: string
    tenantEmail: string
    objectId: string
    externalObjectId: string
    tenantId: number
    tenantPhoneNumber: string
    typeName: string
    tenantAddress: string
    apartmentAccess: string
    apartmentAccess1: string
    apartmentAccess2: string
    createdAt: Date | null
    implementationDate: Date | null
    assignedToId: number | null
    oldFormFields?: IOldFormFields
}
interface IOldFormFields {
    status: ContactFormStatus
    implementationDate: Date | null
    assignedToId: number | null
}
interface IHandler {
    id: number
    name: string
}

export enum ITabId {
    DETAIL = "detail",
    CHAT = "chat",
}

export class ContactFormDetailStore {
    static Context = React.createContext<ContactFormDetailStore | null>(null)
    static LoadingKeys = createLoadingKeys("submit", "init")

    /* selectedTabId */
    private _selectedTabId: string = ITabId.DETAIL

    private _messagingEnabled: boolean = false

    get selectedTabId() {
        return this._selectedTabId
    }

    setSelectedTabId(id: string) {
        this._selectedTabId = id
    }

    get messagingEnabled() {
        return this._messagingEnabled
    }

    setMessagingEnabled(value: boolean) {
        this._messagingEnabled = value
    }

    handlers: IHandler[] = []

    private id: number | null = null
    initialized = false

    form = new FormFields<IFormFields>({
        location: "",
        status: ContactFormStatus.Unknown,
        title: "",
        description: "",
        attachments: [],
        objectId: "",
        externalObjectId: "",
        tenantId: 0,
        assignedToId: 0,
        tenantFullName: "",
        tenantEmail: "",
        tenantPhoneNumber: "",
        tenantAddress: "",
        typeName: "",
        apartmentAccess: "",
        apartmentAccess1: "",
        apartmentAccess2: "",
        createdAt: new Date(),
        implementationDate: null,
    })

    constructor() {
        makeAutoObservable(this)
    }

    @loads(() => ContactFormDetailStore.LoadingKeys.init)
    async init(id: number) {
        this.setId(id)

        const [handlers, contactForm] = await Promise.all([
            await ContactFormAdminService.getV1AdminContactFormAdmins1({
                contactFormId: id,
            }),
            await ContactFormAdminService.contactFormGetOne({
                contactFormId: id,
            }),
        ])
        if (handlers !== null) {
            this.setHandlers(
                handlers.map((handler) => ({
                    id: handler.admin_id ?? -1,
                    name: handler.name ?? "",
                })),
            )
        }

        this.setMessagingEnabled(contactForm.messaging_enabled ?? false)

        this.form.init({
            location: contactForm.location ?? "",
            status: parseStatus(contactForm.status ?? ""),
            title: contactForm.title ?? "",
            description: contactForm.contact_form_description ?? "",
            attachments: contactForm.contact_form_photos ?? [],
            objectId: contactForm.object_id ?? "",
            externalObjectId: contactForm.external_object_id ?? "",
            tenantId: contactForm.tenant_id ?? -1,
            assignedToId: contactForm.assigned_to ?? null,
            tenantFullName: contactForm.tenant_full_name ?? "",
            tenantEmail: contactForm.tenant_email ?? "",
            tenantPhoneNumber: contactForm.tenant_phone_number ?? "",
            tenantAddress: contactForm.object_address ?? "",
            typeName: contactForm.contact_form_type_name ?? "",
            apartmentAccess: contactForm.apartment_access ?? "",
            apartmentAccess1: contactForm.apartment_access_1 ?? "",
            apartmentAccess2: contactForm.apartment_access_2 ?? "",
            createdAt: parseDate(contactForm.created_at),
            implementationDate: parseDate(contactForm.implementation_date),
        })

        this.setInitialized()
    }

    @loads(() => ContactFormDetailStore.LoadingKeys.submit)
    async submit() {
        const { assignedToId, implementationDate, status, title } =
            this.form.data
        const { id } = this

        if (id == null) {
            return
        }

        await this.form.catchErrors(async () => {
            await ContactFormAdminService.contactFormUpdate({
                contactFormId: id,
                request: {
                    assigned_to: assignedToId ?? undefined,
                    implementation_date:
                        implementationDate?.toISOString() ?? undefined,
                    status,
                },
            })

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "contact-forms",
                    action: "update",
                    item: { id, name: title },
                },
            })
        })
    }

    private setHandlers(handlers: IHandler[]) {
        this.handlers = handlers
    }

    private setId(id: number) {
        this.id = id
    }

    private setInitialized() {
        this.initialized = true
    }
}
