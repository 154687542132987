import { makeAutoObservable } from "mobx"

import { IModal } from "src/types/modal/modal"
import { IModalOptions } from "src/types/modal/modal-options"

export class ModalsStore {
    readonly CLOSING_ANIMATION_MS = 500

    private _active: IModal[] = []
    get active() {
        return this._active
    }

    constructor() {
        makeAutoObservable(this)
    }

    open(Component: () => JSX.Element, options?: IModalOptions) {
        const modal: IModal = {
            id: Math.random().toString(),
            isClosing: false,
            Component,
            options: options ?? { variant: "right-slide-in" },
        }
        if (
            modal.options.variant === "right-slide-in" &&
            this._active.length > 0
        ) {
            return
        } else {
            this._active.push(modal)
        }
        return () => {
            this.close(modal.id)
        }
    }

    close(id: string) {
        this.markModalAsClosing(id)
        setTimeout(() => this.removeModal(id), this.CLOSING_ANIMATION_MS)
    }

    pop() {
        const modals = this.nonClosingActive()
        if (modals.length > 0) {
            const last = modals[modals.length - 1]
            this.close(last.id)
        }
    }

    addConfirmCondition(fn: () => boolean) {
        const modal = this.topMostModal()
        if (modal != null) {
            modal.confirmOnOverlayClick = fn
        }
    }

    private nonClosingActive() {
        return this._active.filter((modal) => !modal.isClosing)
    }

    private topMostModal(): IModal | null {
        const modals = this.nonClosingActive()
        if (modals.length > 0) {
            const last = modals[modals.length - 1]
            return last
        }
        return null
    }

    private markModalAsClosing(id: string) {
        const modal = this._active.find((modal) => modal.id === id)
        if (modal != null) {
            modal.isClosing = true
        }
    }

    private removeModal(id: string) {
        this._active = this._active.filter((modal) => modal.id !== id)
    }
}
