import { observer } from "mobx-react"
import { Stack, Typography, useTheme, Button, Link, Box } from "@mui/material"
import { t } from "@lingui/macro"

import { Close } from "@material-ui/icons"

import { useCallback, useMemo } from "react"

import { useNavigate } from "react-router"

import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { batch_ClusterWithSummary } from "src/api"
import { HeaderTypographyFields } from "src/modals/community-insights/components/HeaderTypographyFields"

interface IProps {
    communityName: string
    period: string
    subject: string
    hitsNumber: number
    color: string
    clusters: batch_ClusterWithSummary[]
}

export const CommunityInsightsChipDialog = observer(
    ({
        communityName,
        period,
        subject,
        hitsNumber,
        color,
        clusters,
    }: IProps) => {
        const globalStore = useStore(GlobalStore)
        const { palette } = useTheme()
        const navigate = useNavigate()

        const handleLinkClick = useCallback(
            (postsIds?: number[]) => {
                if (postsIds?.length === 0) return
                navigate(
                    `/community/posts-and-comments?post_id=${JSON.stringify(
                        postsIds,
                    )}`,
                )
                globalStore.modals.pop()
            },
            [globalStore.modals, navigate],
        )

        const renderClusters = useMemo(() => {
            if (clusters.length === 0) {
                return (
                    <Typography variant="body1">
                        {t`community-insights.warning-dialog.no-clusters`}
                    </Typography>
                )
            }
            return clusters.map((cluster, index) => {
                return (
                    <Stack key={index}>
                        <Typography variant="body1">
                            {cluster.summary}
                        </Typography>
                        <Link
                            href={undefined}
                            onClick={() => handleLinkClick(cluster.post_ids)}
                            target="_blank"
                            sx={{
                                cursor: "pointer",
                            }}
                        >
                            {t`community-insights.warning-dialog.view-posts`}
                        </Link>
                        <Stack height={16} />
                    </Stack>
                )
            })
        }, [handleLinkClick, clusters])

        const renderButtonSection = useMemo(
            () => (
                <Stack direction="row" justifyContent="end" padding={1}>
                    <Button
                        sx={{
                            color: palette.utility.darkBlue.main,
                            marginLeft: 1,
                        }}
                        startIcon={<Close />}
                        onClick={() => {
                            globalStore.modals.pop()
                        }}
                    >
                        {t`modal-header-component.close`}
                    </Button>
                </Stack>
            ),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [],
        )

        return (
            <>
                <Stack bgcolor={color} height={24} />
                <Stack paddingX={3} paddingY={2} bgcolor={palette.grey[50]}>
                    <HeaderTypographyFields
                        label={t`community-insights.warning-dialog.community-name`}
                        value={communityName}
                    />
                    <HeaderTypographyFields
                        label={t`community-insights.warning-dialog.period`}
                        value={period}
                    />
                    <HeaderTypographyFields
                        label={t`community-insights.warning-dialog.subject`}
                        value={subject}
                    />
                    <HeaderTypographyFields
                        label={t`community-insights.warning-dialog.hits`}
                        value={hitsNumber}
                    />
                </Stack>

                <Box
                    sx={{
                        maxHeight: 500,
                        overflowY: "auto",
                    }}
                >
                    <Stack paddingX={3} paddingY={2}>
                        <Typography variant="h6">
                            {t`community-insights.warning-dialog.summary`}
                        </Typography>
                        <Stack paddingTop={2}>{renderClusters}</Stack>
                    </Stack>
                </Box>
                {renderButtonSection}
            </>
        )
    },
)
