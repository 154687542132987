import { t } from "@lingui/macro"
import { Grid } from "@mui/material"
import { observer } from "mobx-react"

import { FormFieldSet } from "src/components/FormFieldSet"
import { ImageUploadField } from "src/components/ImageUploadField"
import { ModalTextField } from "src/components/ModalTextField"
import { FeatureConfigurationDetailStore } from "src/modals/feature-configuration-detail/store"
import { useStore } from "src/store/lib/useStore"

export const FlutterCustomerBrandingSection = observer(() => {
    const store = useStore(FeatureConfigurationDetailStore)

    return (
        <>
            <FormFieldSet>
                <ModalTextField
                    variant="default"
                    label={t`feature-configuration-detail-modal.flutter-customer-branding-section.use-for-branding-label`}
                    value={store.form.get("useForPublicBranding")}
                    onChange={store.form.setter("useForPublicBranding")}
                    error={Boolean(store.form.error("useForPublicBranding"))}
                    maxLength={255}
                />
            </FormFieldSet>
            <FormFieldSet
                header={t`feature-configuration-detail-modal.flutter-customer-branding-section.primary-colors-header`}
            >
                <ModalTextField
                    variant="default"
                    label={t`feature-configuration-detail-modal.flutter-customer-branding-section.primary-color-flutter-label`}
                    value={store.form.get("primaryColorFlutter")}
                    onChange={store.form.setter("primaryColorFlutter")}
                    error={Boolean(store.form.error("primaryColorFlutter"))}
                />
            </FormFieldSet>
            <FormFieldSet>
                <ModalTextField
                    variant="default"
                    label={t`feature-configuration-detail-modal.flutter-customer-branding-section.onPrimary-color-label`}
                    value={store.form.get("onPrimaryColor")}
                    onChange={store.form.setter("onPrimaryColor")}
                    error={Boolean(store.form.error("onPrimaryColor"))}
                />
            </FormFieldSet>
            <FormFieldSet>
                <ModalTextField
                    variant="default"
                    label={t`feature-configuration-detail-modal.flutter-customer-branding-section.primary-container-color-label`}
                    value={store.form.get("primaryContainerColor")}
                    onChange={store.form.setter("primaryContainerColor")}
                    error={Boolean(store.form.error("primaryContainerColor"))}
                />
            </FormFieldSet>
            <FormFieldSet>
                <ModalTextField
                    variant="default"
                    label={t`feature-configuration-detail-modal.flutter-customer-branding-section.onPrimary-container-color-label`}
                    value={store.form.get("onPrimaryContainerColor")}
                    onChange={store.form.setter("onPrimaryContainerColor")}
                    error={Boolean(store.form.error("onPrimaryContainerColor"))}
                />
            </FormFieldSet>

            <FormFieldSet
                header={t`feature-configuration-detail-modal.flutter-customer-branding-section.secondary-colors-header`}
            >
                <ModalTextField
                    variant="default"
                    label={t`feature-configuration-detail-modal.flutter-customer-branding-section.secondary-color-flutter-label`}
                    value={store.form.get("secondaryColorFlutter")}
                    onChange={store.form.setter("secondaryColorFlutter")}
                    error={Boolean(store.form.error("secondaryColorFlutter"))}
                />
            </FormFieldSet>
            <FormFieldSet>
                <ModalTextField
                    variant="default"
                    label={t`feature-configuration-detail-modal.flutter-customer-branding-section.onSecondary-color-label`}
                    value={store.form.get("onSecondaryColor")}
                    onChange={store.form.setter("onSecondaryColor")}
                    error={Boolean(store.form.error("onSecondaryColor"))}
                />
            </FormFieldSet>
            <FormFieldSet>
                <ModalTextField
                    variant="default"
                    label={t`feature-configuration-detail-modal.flutter-customer-branding-section.secondary-container-color-label`}
                    value={store.form.get("secondaryContainerColor")}
                    onChange={store.form.setter("secondaryContainerColor")}
                    error={Boolean(store.form.error("secondaryContainerColor"))}
                />
            </FormFieldSet>
            <FormFieldSet>
                <ModalTextField
                    variant="default"
                    label={t`feature-configuration-detail-modal.flutter-customer-branding-section.onSecondary-container-color-label`}
                    value={store.form.get("onSecondaryContainerColor")}
                    onChange={store.form.setter("onSecondaryContainerColor")}
                    error={Boolean(
                        store.form.error("onSecondaryContainerColor"),
                    )}
                />
            </FormFieldSet>
            <FormFieldSet
                header={t`feature-configuration-detail-modal.flutter-customer-branding-section.branding-assets-header`}
            >
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <ImageUploadField
                            label={t`feature-configuration-detail-modal.flutter-customer-branding-section.login-background-expanded`}
                            value={store.form.get("loginBackgroundExpanded")}
                            onChange={(image) =>
                                store.form.set("loginBackgroundExpanded", image)
                            }
                            tooltip={t`feature-configuration-detail-modal.flutter-customer-branding-section.login-background-expanded-tooltip`}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ImageUploadField
                            label={t`feature-configuration-detail-modal.flutter-customer-branding-section.login-background-compact`}
                            value={store.form.get("loginBackgroundCompact")}
                            onChange={(image) =>
                                store.form.set("loginBackgroundCompact", image)
                            }
                            tooltip={t`feature-configuration-detail-modal.flutter-customer-branding-section.login-background-compact-tooltip`}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ImageUploadField
                            label={t`feature-configuration-detail-modal.flutter-customer-branding-section.side-pane-image-expanded`}
                            value={store.form.get("sidePaneImageExpanded")}
                            onChange={(image) =>
                                store.form.set("sidePaneImageExpanded", image)
                            }
                            tooltip={t`feature-configuration-detail-modal.flutter-customer-branding-section.side-pane-image-expanded-tooltip`}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ImageUploadField
                            label={t`feature-configuration-detail-modal.flutter-customer-branding-section.overview-header-image-compact`}
                            value={store.form.get("overviewHeaderImageCompact")}
                            onChange={(image) =>
                                store.form.set(
                                    "overviewHeaderImageCompact",
                                    image,
                                )
                            }
                            tooltip={t`feature-configuration-detail-modal.flutter-customer-branding-section.overview-header-image-compact-tooltip`}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ImageUploadField
                            label={t`feature-configuration-detail-modal.flutter-customer-branding-section.company-logo`}
                            value={store.form.get("companyLogo")}
                            onChange={(image) =>
                                store.form.set("companyLogo", image)
                            }
                            tooltip={t`feature-configuration-detail-modal.flutter-customer-branding-section.company-logo-tooltip`}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ImageUploadField
                            label={t`feature-configuration-detail-modal.flutter-customer-branding-section.overview-and-app-icon`}
                            value={store.form.get("overviewAndAppIcon")}
                            onChange={(image) =>
                                store.form.set("overviewAndAppIcon", image)
                            }
                            tooltip={t`feature-configuration-detail-modal.flutter-customer-branding-section.overview-and-app-icon-tooltip`}
                        />
                    </Grid>
                </Grid>
            </FormFieldSet>
        </>
    )
})
