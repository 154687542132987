import { IFeatureConfigurationDetailFormFields } from "src/modals/feature-configuration-detail/types/formFields"

export const defaultValuesFeatures: IFeatureConfigurationDetailFormFields =
    Object.freeze({
        appIconName: "",
        primaryColor: "",
        propertyOwnerEmailAddress: "",
        senderName: "",
        senderAddress: "",
        issueReportingExternalLink: "",
        issueReportingEmailToName: "",
        issueReportingEmailToAddress: "",
        emailTemplateTenantElectricityEmail: "",
        emailTemplateTenantFinalReminder: "",
        emailTemplateTenantInsuranceEmail: "",
        emailTemplateTenantMoveIn: "",
        emailTemplateTenantPostMoveInRemindEmail: "",
        emailTemplateTenantReminderRegister: "",
        brandingBackgroundImage: null,
        brandingLogo: null,
        overviewHeaderImage: null,
        overviewLogo: null,
        propertyOwnerEmailLogo: null,
        paymentDueDateBlocker: "",
        emailTemplateTenantRolloutRegistrationReminder: "",
        emailTemplateTenantRolloutRegistrationReminder2: "",
        emailTemplateTenantRolloutRegistrationReminderFinal: "",
        SMSTemplateTenantMoveIn: "",
        SMSFromPhone: "",
        SMSTemplateTenantFinalReminder: "",
        SMSTemplateTenantRolloutRegistrationReminder: "",
        SMSTemplateTenantRolloutRegistrationReminder2: "",
        DashPositionExternalAccess: "",
        DashPositionAmido: "",
        // flutter keys
        useForPublicBranding: "",
        primaryColorFlutter: "",
        onPrimaryColor: "",
        primaryContainerColor: "",
        onPrimaryContainerColor: "",
        secondaryColorFlutter: "",
        onSecondaryColor: "",
        secondaryContainerColor: "",
        onSecondaryContainerColor: "",
        companyLogo: null,
        overviewAndAppIcon: null,
        overviewHeaderImageCompact: null,
        sidePaneImageExpanded: null,
        loginBackgroundCompact: null,
        loginBackgroundExpanded: null,
    })
