import { t } from "@lingui/macro"
import { Checkbox, FormControlLabel, Stack } from "@mui/material"
import { observer } from "mobx-react"
import { useEffect } from "react"

import { FormPanel } from "src/components/FormPanel"
import { AccessGroup } from "src/components/ModalAccessGroup/AccessGroup"
import { ModalTextField } from "src/components/ModalTextField"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { reportError } from "src/lib/report"
import { ChatbotDispatchesStore } from "src/modals/chatbot-dispatches/store"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

export const ChatbotDispatchesSettingsSection = observer(() => {
    const store = useStore(ChatbotDispatchesStore)
    const globalStore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => {
            try {
                await store.setSelectedAccessGroupId(
                    globalStore.session.accessGroup.id !== undefined
                        ? globalStore.session.accessGroup.id
                        : DEFAULT_ACCESS_GROUP.id,
                )
            } catch (error) {
                reportError(
                    t`edit-information-item-modal.load-categories-fail`,
                    error,
                )
            }
        })()
    }, [store, globalStore.session.accessGroup])

    return (
        <FormPanel
            sections={[
                {
                    header: t`chatbot-dispatches-modal.section.settings`,
                    content: (
                        <Stack spacing={2}>
                            <ModalTextField
                                variant="default"
                                label={t`chatbot-dispatches-modal.form-field.internal-name`}
                                value={store.formFields.get("name")}
                                onChange={store.formFields.setter("name")}
                                helperText={store.formFields.error("name")}
                                error={Boolean(store.formFields.error("name"))}
                                disabled={
                                    store.formFields.get("access_type") ===
                                    "READ"
                                }
                                rows={1}
                            />
                            <AccessGroup
                                disabled={store.isEditMode()}
                                accessGroupId={store.formFields.get(
                                    "access_group_id",
                                )}
                                errorMessage={
                                    store.formFields.error("access_group_id") ??
                                    ""
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={store.getDispatchCategoryAsBoolean()}
                                        onChange={
                                            store.toggleSelfServiceStatistics
                                        }
                                    />
                                }
                                label={t`chatbot-dispatches-modal.section.statistics-checkbox`}
                            />
                        </Stack>
                    ),
                },
            ]}
        />
    )
})
