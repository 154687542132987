import { FormFields } from "src/lib/form-fields"
import { IGeneralDynamicFieldsItemProps } from "src/modals/embedded-integration-v2/types/generalDynamicFieldsItem"
import { ISalesDynamicFieldsItemProps } from "src/modals/embedded-integration-v2/types/salesDynamicFieldsItem"
import { IEmbeddedIntegrationContentSectionFormFields } from "src/modals/embedded-integration-v2/types/embeddedIntegrationContentSectionFormFields"
import { IEmbeddedIntegrationFormFields } from "src/modals/embedded-integration-v2/types/embeddedIntegrationFormFields"
import { IDynamicFieldItemProps } from "src/modals/embedded-integration-v2/types/dynamicFieldItem"
import { avy_api_pkg_embed_IntegrationCreateUpdate } from "src/api/models/avy_api_pkg_embed_IntegrationCreateUpdate"
import { EmbeddedIntegrationSettingType } from "src/modals/embedded-integration-v2/types/contentDynamicFields"
import { IPurchaseRichLinkDynamicFieldsItemProps } from "src/modals/embedded-integration-v2/types/purchaseRichLinkFieldsItem"
import { IPurchaseSimpleLinkDynamicFieldsItemProps } from "src/modals/embedded-integration-v2/types/purchaseSimplelinkFieldsItem"
import { IPurchaseCollapsibleLinkDynamicFieldsItemProps } from "src/modals/embedded-integration-v2/types/purchaseCollapsibleLinkFieldsItem"

export const createUpdateRequestBody = (
    mainStoreFormFields: FormFields<IEmbeddedIntegrationFormFields>,
    contentStoreFields: FormFields<IEmbeddedIntegrationContentSectionFormFields>,
    generalDynamicFieldsList: IGeneralDynamicFieldsItemProps[],
    salesDynamicFieldsList: ISalesDynamicFieldsItemProps[],
    toggleChipOptions: string[],
) => {
    const getSelectedCategories = () =>
        generalDynamicFieldsList.map((item) => item.categoryDropdownValue.value)

    const getCategoryFields = () => {
        return generalDynamicFieldsList.reduce(
            (acc, cur) => ({
                ...acc,
                [cur.categoryDropdownValue.value]: cur.categoryFields.reduce(
                    (acc, cur) => ({
                        ...acc,
                        [cur.name as string]: cur.description,
                    }),
                    {},
                ),
            }),
            {},
        )
    }
    const getStatusBadgeObject = (type: string) => {
        let purchaseTypeObject =
            type.length > 0
                ? contentStoreFields.data[type]
                : contentStoreFields.data[
                      contentStoreFields.data.salesPurchaseType
                  ]
        if (purchaseTypeObject !== undefined) {
            return {
                text: purchaseTypeObject.statusBadgeText.value ?? "",
                color:
                    purchaseTypeObject.statusBadgeBackgroundColor.value ?? "",
            }
        }
    }

    const getInfoBoxObject = (type: string) => {
        let purchaseTypeObject =
            type.length > 0
                ? contentStoreFields.data[type]
                : contentStoreFields.data[
                      contentStoreFields.data.salesPurchaseType
                  ]
        if (purchaseTypeObject !== undefined) {
            return {
                title: purchaseTypeObject.infoBoxTitle.value ?? "",
                color: purchaseTypeObject.infoBoxBackgroundColor.value ?? "",
                description:
                    purchaseTypeObject.infoBoxTextDescription.value ?? "",
            }
        }
    }
    const getPriceBoxObject = (type: string) => {
        let purchaseTypeObject =
            type.length > 0
                ? contentStoreFields.data[type]
                : contentStoreFields.data[
                      contentStoreFields.data.salesPurchaseType
                  ]
        if (purchaseTypeObject !== undefined) {
            return {
                amount_cents:
                    Number(String(purchaseTypeObject.priceBoxAmount.value)) ??
                    0,
                currency: purchaseTypeObject.priceBoxCurrency.value ?? "",
                period: purchaseTypeObject.priceBoxPeriod.value ?? "",
            }
        }
    }
    const getIntegrationLinkArray = (type: string) => {
        let purchaseTypeObject =
            type.length > 0
                ? contentStoreFields.data[type]
                : contentStoreFields.data[
                      contentStoreFields.data.salesPurchaseType
                  ]
        if (
            purchaseTypeObject?.purchaseRichLinkDynamicFieldsList !== undefined
        ) {
            return purchaseTypeObject.purchaseRichLinkDynamicFieldsList.map(
                (item: IPurchaseRichLinkDynamicFieldsItemProps) => ({
                    description: item.linkSubTitle.value ?? "",
                    icon_url: item.linkSvgFile.value ?? "",
                    label: item.linkTitle.value ?? "",
                    url: item.linkUrl.value ?? "",
                }),
            )
        } else {
            return []
        }
    }
    const getSimpleLinkArray = (type: string) => {
        let purchaseTypeObject =
            type.length > 0
                ? contentStoreFields.data[type]
                : contentStoreFields.data[
                      contentStoreFields.data.salesPurchaseType
                  ]
        if (
            purchaseTypeObject?.purchaseSimpleLinkDynamicFieldsList !==
            undefined
        ) {
            return purchaseTypeObject.purchaseSimpleLinkDynamicFieldsList.map(
                (item: IPurchaseSimpleLinkDynamicFieldsItemProps) => ({
                    label: item.linkTitle.value ?? "",
                    url: item.linkUrl.value ?? "",
                }),
            )
        } else {
            return []
        }
    }

    const getCollapsibleLinkArray = (type: string) => {
        let purchaseTypeObject =
            type.length > 0
                ? contentStoreFields.data[type]
                : contentStoreFields.data[
                      contentStoreFields.data.salesPurchaseType
                  ]
        if (
            purchaseTypeObject?.purchaseCollapsibleLinkDynamicFieldsList !==
            undefined
        ) {
            return purchaseTypeObject.purchaseCollapsibleLinkDynamicFieldsList.map(
                (item: IPurchaseCollapsibleLinkDynamicFieldsItemProps) => ({
                    content: item.sectionContent.value ?? "",
                    title: item.sectionTitle.value ?? "",
                }),
            )
        } else {
            return []
        }
    }
    const getPurchaseName = (type: string) => {
        return type.length > 0
            ? contentStoreFields.data[type]?.priceBoxName?.value
            : contentStoreFields.data[contentStoreFields.data.salesPurchaseType]
                  ?.priceBoxName?.value
    }
    const getStaticPurchaseData = () => {
        return toggleChipOptions.reduce(
            (acc, cur) => ({
                ...acc,
                [cur]: {
                    status: getStatusBadgeObject(cur),
                    info_box: getInfoBoxObject(cur),
                    price: getPriceBoxObject(cur),
                    integration_links: getIntegrationLinkArray(cur),
                    links: getSimpleLinkArray(cur),
                    collapsible_boxes: getCollapsibleLinkArray(cur),
                    purchase_name: getPurchaseName(cur),
                },
            }),
            {},
        )
    }

    const getSalesBulletPoints = (fields: IDynamicFieldItemProps[]) => {
        return fields.map((item) => ({ text: item.value }))
    }
    const getExtraSalesCard = () =>
        salesDynamicFieldsList.map((item) => ({
            bullet_points: getSalesBulletPoints(item.salesCardBulletOptions),
            description: item.salesCardDescription.value,
            headline: item.salesCardHeadline.value,
            icon_url: item.salesCardFileUpload.value,
            subtitle: item.salesCardSubtitle.value,
        }))

    const request: avy_api_pkg_embed_IntegrationCreateUpdate = {
        categories: getSelectedCategories(),
        category_fields: getCategoryFields(),
        code: mainStoreFormFields.data.referenceId,
        type: mainStoreFormFields.data.type,
        entry_url: mainStoreFormFields.data.entryUrl,
        integration_connector_id:
            mainStoreFormFields.data.selectedConnectorId ?? undefined,
        external_links_regex: mainStoreFormFields.data.externalLink,
        name: mainStoreFormFields.data.internalName,
        open_externally: mainStoreFormFields.data.openExternally,
        partner_id: Number(mainStoreFormFields.data.partner),
        sales_data: {
            extra_sales_cards: getExtraSalesCard(),
            header_image_url: contentStoreFields.data.fileUploadUrl,
            primary_sales_card: {
                bullet_points: getSalesBulletPoints(
                    contentStoreFields.data.bulletOptions ?? [],
                ),
                title: contentStoreFields.data.salesPageTitle,
                description: contentStoreFields.data.salesPageDescription,
                headline: contentStoreFields.data.salesPageHeadline,
            },
            privacy_policy_text: contentStoreFields.data.ctaPrivacyPolicy,
            sales_screen_button_text: contentStoreFields.data.ctaButtonText,
        },
        show_sales_screen: mainStoreFormFields.data.useSalesPage,
        sso_enabled: mainStoreFormFields.data.singleSignOn,
        static_purchase_data:
            mainStoreFormFields.data.type ===
            EmbeddedIntegrationSettingType.Subscription
                ? getStaticPurchaseData()
                : undefined,
    }

    return request
}
