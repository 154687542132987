import { t } from "@lingui/macro"
import { Button } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"

import { SMSFromNameTemplateSection } from "./sections/SMSFromNameTemplate"

import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"
import { ModalHeader } from "src/components/ModalHeader"
import { CustomerBrandingSection } from "src/modals/feature-configuration-detail/sections/CustomerBranding"
import { EmailTemplateSection } from "src/modals/feature-configuration-detail/sections/EmailTemplate"
import { SMSTemplateSection } from "src/modals/feature-configuration-detail/sections/SMSFromTemplate"
import { LegalEntitySection } from "src/modals/feature-configuration-detail/sections/LegalEntity"
import { OtherSection } from "src/modals/feature-configuration-detail/sections/Other"

import { FeatureConfigurationDetailStore } from "src/modals/feature-configuration-detail/store"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"
import { useStore } from "src/store/lib/useStore"
import { ISessionLegalEntity } from "src/store/session"
import { FlutterCustomerBrandingSection } from "src/modals/feature-configuration-detail/sections/FlutterCustomerBranding"
import { CenteredSpinner } from "src/components/CenteredSpinner"

interface IProps {
    legalEntities?: ISessionLegalEntity[]
}

const View = observer((props: IProps) => {
    const store = useStore(FeatureConfigurationDetailStore)
    const gstore = useStore(GlobalStore)

    useCloseConfirmationForForm(store.form)

    useEffect(() => {
        if (props.legalEntities != null) {
            store.init(props.legalEntities)
        }
    }, [store, props.legalEntities])

    const submitIsDisabled =
        !store.form.getIsDirty() ||
        gstore.loading.is(FeatureConfigurationDetailStore.LoadingKeys.submit)

    const handleSubmit = useCallback(async () => {
        if (submitIsDisabled) {
            return
        }
        await store.validateAndSubmit()
        if (!store.form.hasErrors()) {
            gstore.modals.pop()
        }
    }, [store, gstore, submitIsDisabled])

    if (gstore.loading.is(FeatureConfigurationDetailStore.LoadingKeys.submit)) {
        return <CenteredSpinner />
    }
    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={submitIsDisabled}
                >{t`feature-configuration-detail-modal.save-button`}</Button>
            </ModalHeader>
            <FormPanel
                sections={[
                    {
                        header: t`feature-configuration-detail-modal.legal-entity-section.header`,
                        content: <LegalEntitySection />,
                    },
                ]}
            />
            {store.selectedLegalEntity != null && (
                <>
                    <FormPanel
                        sections={[
                            {
                                header: t`feature-configuration-detail-modal.flutter-customer-branding-section.header`,
                                content: <FlutterCustomerBrandingSection />,
                            },
                        ]}
                    />
                    <FormPanel
                        sections={[
                            {
                                header: t`feature-configuration-detail-modal.customer-branding-section.header`,
                                content: <CustomerBrandingSection />,
                            },
                        ]}
                    />
                    <FormPanel
                        sections={[
                            {
                                header: t`feature-configuration-detail-modal.other-section.header`,
                                content: <OtherSection />,
                            },
                        ]}
                    />
                    <FormPanel
                        sections={[
                            {
                                header: t`feature-configuration-detail-modal.email-template-section.header`,
                                content: <EmailTemplateSection />,
                            },
                        ]}
                    />
                    <FormPanel
                        sections={[
                            {
                                header: t`feature-configuration-detail-modal.sms-from-name-template-section.header`,
                                content: <SMSFromNameTemplateSection />,
                            },
                        ]}
                    />
                    <FormPanel
                        sections={[
                            {
                                header: t`feature-configuration-detail-modal.sms-template-section.header`,
                                content: <SMSTemplateSection />,
                            },
                        ]}
                    />
                </>
            )}
        </Form>
    )
})

export const FeatureConfigurationDetailModal = observer((props: IProps) => (
    <StoreProvider Store={FeatureConfigurationDetailStore}>
        <View {...props} />
    </StoreProvider>
))
