import { t } from "@lingui/macro"
import { Stack, Typography, useTheme, Fab } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { Container, Draggable, DropResult } from "react-smooth-dnd"

import { ModalTextField } from "src/components/ModalTextField"

import {
    DraggableContainer,
    FabButtonContainer,
    Separator,
} from "src/modals/embedded-integration-v2/styled"
import { Add24 } from "src/components/icons/Add24"
import { formFields } from "src/modals/embedded-integration-v2/formFields"
import { persistFiles } from "src/lib/file"
import { IEmbeddedIntegrationContentSectionFormFields } from "src/modals/embedded-integration-v2/types/embeddedIntegrationContentSectionFormFields"
import { EmbeddedIntegrationSalesCardSection } from "src/modals/embedded-integration-v2/components/SalesCardSectionView"
import { initialSalesDynamicFieldState } from "src/modals/embedded-integration-v2/constants/initialSalesDynamicFieldState"
import { FormPanel } from "src/components/FormPanel"
import { EmbeddedIntegrationSalesTabBulletOptionSection } from "src/modals/embedded-integration-v2/components/SalesBulletOptionView"
import { initialState } from "src/modals/embedded-integration-v2/constants/initialState"
import { useStore } from "src/store/lib/useStore"
import { ContentSectionStore } from "src/modals/embedded-integration-v2/sections/ContentSection/store"
export const EmbeddedIntegrationSalesTabSection = observer(() => {
    const contentStore = useStore(ContentSectionStore)
    const salesDynamicFieldsList = contentStore.salesTabFields
    const theme = useTheme()
    const bulletOptions = contentStore.fields.get("bulletOptions") ?? []

    const handleIconDelete = useCallback(
        () => contentStore.fields.set("fileUploadUrl", ""),
        [contentStore],
    )

    const handleIconChange = useCallback(
        async (images: IFile[]) => {
            const icon = images[0]
            if (Object.keys(icon).length > 0) {
                const images = await persistFiles([icon as ILocalFile], "image")

                if (images.length > 0) {
                    contentStore.fields.set("fileUploadUrl", images[0].url)
                }
            }
        },
        [contentStore],
    )

    const handleUspBulletOptionsTextInputChange = (
        index: number,
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const list = bulletOptions ?? []
        list[index].errorMessage = ""
        list[index].value =
            event.target.value.trim() !== "" ? event.target.value : ""
        if (event.target.value.trim() === "") {
            list[index].errorMessage = t`errors.required`
        }
        contentStore.fields.set("bulletOptions", list)
    }

    const handleAddUspBulletOptionClick = () => {
        contentStore.fields.set("bulletOptions", [
            ...bulletOptions,
            initialState,
        ])
    }
    const handleRemoveBulletOptionsClick = (index: number) => {
        const list = [...bulletOptions]
        list.splice(index, 1)
        contentStore.fields.set("bulletOptions", list)
    }

    const handleAddSalesCardClick = () => {
        contentStore.fields.set("salesDynamicFieldsList", [
            ...salesDynamicFieldsList,
            initialSalesDynamicFieldState,
        ])
    }
    const handleRemoveSalesCardClick = (index: number) => {
        if (salesDynamicFieldsList.length > 0) {
            const list = [...salesDynamicFieldsList]
            list.splice(index, 1)
            contentStore.fields.set("salesDynamicFieldsList", list)
        }
    }
    const sortListFromResult = (dropResult: IDropResult) => {
        const { removedIndex, addedIndex } = dropResult
        const items = [...salesDynamicFieldsList]

        const [item] = items.splice(removedIndex, 1)
        items.splice(addedIndex, 0, item)

        const list = items.map((item) => ({ ...item }))
        contentStore.fields.set("salesDynamicFieldsList", list)
    }

    const onDrop = (dropResult: DropResult) => {
        const { removedIndex, addedIndex } = dropResult
        if (
            removedIndex == null ||
            addedIndex == null ||
            addedIndex === removedIndex
        ) {
            return
        }

        sortListFromResult(dropResult as IDropResult)
    }

    const handleTextInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        key: keyof IEmbeddedIntegrationContentSectionFormFields,
    ) => {
        contentStore.fields.set(key, event.target.value)
    }
    const renderTextInput = (
        label: string,
        key: keyof IEmbeddedIntegrationContentSectionFormFields,
        rowLength: number,
        maxLength: number,
    ) => {
        return (
            <ModalTextField
                variant="default"
                label={label ?? ""}
                value={contentStore.fields.get(key) as string | number}
                onChange={(event) => handleTextInputChange(event, key)}
                helperText={contentStore.fields.error(key)}
                error={Boolean(contentStore.fields.error(key))}
                maxLength={maxLength ?? 255}
                rows={rowLength}
            />
        )
    }
    const renderAddFileSection = () => (
        <formFields.EmbeddedIntegrationSvgIconUpload
            header={t`embed-integrations-modal.content-section.sales-tab.svg-icon-header`}
            subHeader={t`embed-integrations-modal.content-section.sales-tab.svg-icon.sub-header`}
            value={contentStore.fields.get("fileUploadUrl")}
            onIconDelete={handleIconDelete}
            onIconChange={handleIconChange}
            errorMessage={contentStore.fields.error("fileUploadUrl")}
        />
    )

    const renderSalesTabUspBulletOptionSection = () => (
        <EmbeddedIntegrationSalesTabBulletOptionSection
            headerLabel={t`embed-integrations-modal.content-section.sales-tab.usp-bullet-header`}
            textInputLabel={t`embed-integrations-modal.content-section.sales-tab.bullet-option-label`}
            buttonText={t`embed-integrations-modal.content-section.sales-tab.add-bullet-label`}
            item={bulletOptions}
            theme={theme}
            onInputChange={handleUspBulletOptionsTextInputChange}
            onRemoveClick={handleRemoveBulletOptionsClick}
            onAddOptionClick={handleAddUspBulletOptionClick}
            disabled={false}
        />
    )
    const renderSalesCard = () => (
        <Stack spacing={1}>
            {salesDynamicFieldsList?.length > 0 ? (
                <Container onDrop={onDrop}>
                    {salesDynamicFieldsList?.map((item, index) => (
                        <Draggable key={index}>
                            <DraggableContainer>
                                <EmbeddedIntegrationSalesCardSection
                                    index={index}
                                    item={item}
                                    onRemoveClick={handleRemoveSalesCardClick}
                                />
                            </DraggableContainer>
                        </Draggable>
                    ))}
                </Container>
            ) : null}
            <FabButtonContainer>
                <Fab
                    variant="extended"
                    color="primary"
                    size="medium"
                    disabled={false}
                    onClick={handleAddSalesCardClick}
                >
                    <Add24 />
                    {t`embed-integrations-modal.publish-content-section.sales-tab.add-card`}
                </Fab>
            </FabButtonContainer>
        </Stack>
    )
    const renderCtaContentSection = () => (
        <FormPanel
            sections={[
                {
                    header: t`embed-integrations-modal.content-section.sales-tab.sales-page.cta-header`,
                    content: (
                        <>
                            {renderTextInput(
                                t`embed-integrations-modal.content-section.sales-tab.sales-page.cta-privacy-policy-label`,
                                "ctaPrivacyPolicy",
                                1,
                                255,
                            )}
                            {renderTextInput(
                                t`embed-integrations-modal.content-section.sales-tab.sales-page.cta-button-text-label`,
                                "ctaButtonText",
                                1,
                                36,
                            )}
                        </>
                    ),
                },
            ]}
        />
    )
    return (
        <Stack spacing={2}>
            <Typography variant="body1">{t`embed-integrations-modal.content-section.sales-tab.header`}</Typography>
            {renderTextInput(
                t`embed-integrations-modal.content-section.sales-tab.sales-page-title`,
                "salesPageTitle",
                1,
                255,
            )}
            {renderAddFileSection()}
            {renderTextInput(
                t`embed-integrations-modal.content-section.sales-tab.sales-page-headline`,
                "salesPageHeadline",
                1,
                255,
            )}
            {renderTextInput(
                t`embed-integrations-modal.content-section.sales-tab.sales-page-description`,
                "salesPageDescription",
                4,
                10000,
            )}
            {renderSalesTabUspBulletOptionSection()}
            <Separator />
            {renderSalesCard()}
            <Separator />
            {renderCtaContentSection()}
        </Stack>
    )
})
