/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { avy_api_pkg_segment_PublishResourceRequest } from "../models/avy_api_pkg_segment_PublishResourceRequest"
import type { internal_api_http_admin_navigation_NavigationItemListRequest } from "../models/internal_api_http_admin_navigation_NavigationItemListRequest"
import type { navigation_CreateNavigationItemRequest } from "../models/navigation_CreateNavigationItemRequest"
import type { navigation_Module } from "../models/navigation_Module"
import type { navigation_NavigationItem } from "../models/navigation_NavigationItem"
import type { navigation_NavigationItemListResponse } from "../models/navigation_NavigationItemListResponse"
import type { navigation_UpdateNavigationItemRequest } from "../models/navigation_UpdateNavigationItemRequest"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class NavigationAdminService {
    /**
     * Create navigation item
     * Create new navigation item
     * @returns navigation_NavigationItem OK
     * @throws ApiError
     */
    public static postV1AdminNavigationItem({
        request,
    }: {
        /** Request body for creating navigation item **/
        request: navigation_CreateNavigationItemRequest
    }): CancelablePromise<navigation_NavigationItem> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/navigation/item",
            body: request,
        })
    }

    /**
     * Get navigation items
     * Get navigation items
     * @returns navigation_NavigationItemListResponse OK
     * @throws ApiError
     */
    public static postV1AdminNavigationItemList({
        request,
    }: {
        /** NavigationItemListRequest **/
        request: internal_api_http_admin_navigation_NavigationItemListRequest
    }): CancelablePromise<navigation_NavigationItemListResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/navigation/item/list",
            body: request,
        })
    }

    /**
     * Get navigation item
     * Get navigation item by ID
     * @returns any OK
     * @throws ApiError
     */
    public static getV1AdminNavigationItem({
        navigationItemId,
    }: {
        /** Navigation item ID **/
        navigationItemId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/navigation/item/{navigation_item_id}",
            path: {
                navigation_item_id: navigationItemId,
            },
        })
    }

    /**
     * Update navigation item
     * Update navigation item by ID
     * @returns any OK
     * @throws ApiError
     */
    public static putV1AdminNavigationItem({
        navigationItemId,
        request,
    }: {
        /** Navigation item ID **/
        navigationItemId: number
        /** Request body for updating navigation item **/
        request: navigation_UpdateNavigationItemRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/navigation/item/{navigation_item_id}",
            path: {
                navigation_item_id: navigationItemId,
            },
            body: request,
        })
    }

    /**
     * Delete navigation item
     * Delete navigation item by ID
     * @returns any OK
     * @throws ApiError
     */
    public static deleteV1AdminNavigationItem({
        navigationItemId,
    }: {
        /** Navigation item ID **/
        navigationItemId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/navigation/item/{navigation_item_id}",
            path: {
                navigation_item_id: navigationItemId,
            },
        })
    }

    /**
     * Publish navigation item
     * Publish navigation item by ID
     * @returns any OK
     * @throws ApiError
     */
    public static putV1AdminNavigationItemPublish({
        navigationItemId,
        request,
    }: {
        /** Navigation item ID **/
        navigationItemId: number
        /** Request body for publishing navigation item **/
        request: avy_api_pkg_segment_PublishResourceRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/navigation/item/{navigation_item_id}/publish",
            path: {
                navigation_item_id: navigationItemId,
            },
            body: request,
        })
    }

    /**
     * Get modules
     * Get feature flags that correspond to modules
     * @returns navigation_Module OK
     * @throws ApiError
     */
    public static getV1AdminNavigationModule(): CancelablePromise<
        Array<navigation_Module>
    > {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/navigation/module",
        })
    }
}
