import { format, addMonths, startOfMonth, isAfter } from "date-fns"

import { batch_MonthlyScore } from "src/api"
import { TCommunityPeriod } from "src/views/community-insights/types"

const monthFormat = "MMM yyyy"

export const getCommunityPeriods = (
    monthlyScores?: batch_MonthlyScore[],
): TCommunityPeriod[] =>
    monthlyScores?.map((score) => ({
        period:
            score.month !== undefined
                ? format(new Date(score.month), monthFormat)
                : "",
        value: score.size ?? 0,
        warningScore: score.score ?? 0,
        clusters: score.clusters ?? [],
    })) ?? []

export const getMonthsPeriodsFromDateRange = (
    startDate: Date,
    endDate: Date,
): string[] => {
    const months: string[] = []

    let currentDate = startOfMonth(startDate)

    while (!isAfter(currentDate, endDate)) {
        months.push(format(currentDate, monthFormat))
        currentDate = addMonths(currentDate, 1)
    }

    return months
}

export const transformTopicKeyToReadable = (str: string) => {
    str = str.charAt(0).toUpperCase() + str.slice(1)
    return str.replaceAll("_", " ")
}
