import { format, startOfMonth, endOfMonth } from "date-fns"
import { makeAutoObservable } from "mobx"
import { createContext } from "react"

import {
    batch_CommunitiesAndTimePeriodRequest,
    batch_CommunityFlagsCount,
    batch_FlagsByCommunity,
    CommunityAdminService,
    CommunityInsightsAdminService,
} from "src/api"
import { loads } from "src/channel/utils"
import { TMultipleSelectCheckmarksOption } from "src/components/MultipleSelectCheckmarks/types"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import {
    ICommunityFlagsOverview,
    ICommunityOverviewCommunityItem,
} from "src/views/community-overview/types"

const DATE_FORMAT_SHORT = "yyyy-MM-dd"
const DATE_FORMAT_WITH_TIME = "yyyy-MM-dd HH:mm"

export class CommunitiesOverviewViewStore implements IDisposable {
    static Context = createContext<CommunitiesOverviewViewStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "loading")

    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    private allCommunities: ICommunityOverviewCommunityItem[] = []

    flagsByCommunities: ICommunityFlagsOverview[] = []
    totalFlags: batch_CommunityFlagsCount = {}
    selectedCommunities: number[] = []
    numberOfPosts: number = 0
    numberOfComments: number = 0
    activeUsers: string = "0.00"
    communityFlags: batch_CommunityFlagsCount = {}
    startDate: string = format(startOfMonth(new Date()), DATE_FORMAT_SHORT)
    endDate: string = format(endOfMonth(new Date()), DATE_FORMAT_SHORT)
    recentActivityFromResidents: string = format(
        startOfMonth(new Date()),
        DATE_FORMAT_WITH_TIME,
    )
    lastActivityFromAdministrator: string = format(
        endOfMonth(new Date()),
        DATE_FORMAT_WITH_TIME,
    )

    communitiesPaginator = new Pagination(
        (params) => {
            const pageStart = (params.page - 1) * params.pageSize
            const communities = this.flagsByCommunities
            const pageCommunities = communities.slice(
                pageStart,
                pageStart + params.pageSize,
            )

            return {
                items: pageCommunities,
                sourceItems: this.flagsByCommunities,
                count: this.flagsByCommunities.length,
            }
        },
        {
            static: true,
        },
    )

    get communitiesForMultiSelect(): TMultipleSelectCheckmarksOption[] {
        return this.allCommunities.map((community) => ({
            value: community.id.toString(),
            name: community.name,
        }))
    }

    private repositoryUpdatesListenerDisposer?: () => void

    constructor() {
        makeAutoObservable(this)
    }

    dispose(): void {
        this.repositoryUpdatesListenerDisposer?.()
    }

    @loads(() => CommunitiesOverviewViewStore.LoadingKeys.loading)
    async init(accessGroupId: number) {
        this.setAccessGroupId(accessGroupId)
        await this.loadCommunities()
        await this.loadCommunityOverviewEndpoints()
    }

    @loads(() => CommunitiesOverviewViewStore.LoadingKeys.init)
    async loadCommunityOverviewEndpoints() {
        const request: batch_CommunitiesAndTimePeriodRequest = {
            start_month: format(new Date(this.startDate), "yyyyMM"),
            end_month: format(new Date(this.endDate), "yyyyMM"),
            communities: this.selectedCommunities,
        }
        await this.loadNumberOfPosts(request)
        await this.loadNumberOfComments(request)
        await this.loadEngagementRate(request)
        await this.loadCommunityFlags(request)
        await this.getRecentActivityFromAdministrator(this.selectedCommunities)
        await this.getRecentActivityFromTenant(this.selectedCommunities)
        await this.communitiesPaginator.loadInitialPage()
    }

    private async loadCommunities() {
        const rawCommunities = await CommunityAdminService.getV1AdminCommunity()
        this.setCommunities(
            (rawCommunities ?? [])
                .filter(
                    (community) =>
                        this._accessGroupId === DEFAULT_ACCESS_GROUP.id ||
                        community.access_group_id === this._accessGroupId,
                )
                .map((community) => {
                    return {
                        id: community.community_id ?? 0,
                        name: community.name ?? "",
                    }
                }),
        )
    }
    private async loadCommunityFlags(
        request: batch_CommunitiesAndTimePeriodRequest,
    ) {
        const communitiesFlags =
            await CommunityInsightsAdminService.postV1AdminCommunityInsightsOverviewNbrFlags(
                { request },
            )

        this.setFlagsByCommunities(communitiesFlags.flags_by_communities ?? [])
        this.totalFlags = communitiesFlags.total_flags ?? {}
    }
    private async loadNumberOfPosts(
        request: batch_CommunitiesAndTimePeriodRequest,
    ) {
        const numberOfPosts =
            await CommunityInsightsAdminService.postV1AdminCommunityInsightsOverviewNbrPosts(
                { request },
            )

        this.numberOfPosts = numberOfPosts.count ?? 0
    }
    private async loadNumberOfComments(
        request: batch_CommunitiesAndTimePeriodRequest,
    ) {
        const numberOfComments =
            await CommunityInsightsAdminService.postV1AdminCommunityInsightsOverviewNbrComments(
                { request },
            )

        this.numberOfComments = numberOfComments.count ?? 0
    }

    async loadEngagementRate(request: batch_CommunitiesAndTimePeriodRequest) {
        const engagementRate =
            await CommunityInsightsAdminService.postV1AdminCommunityInsightsOverviewEngagementRate(
                { request },
            )

        this.activeUsers = engagementRate.rate?.toFixed(2) ?? "0.00"
    }

    async getRecentActivityFromAdministrator(request: number[]) {
        const recentActivityFromResidents =
            await CommunityInsightsAdminService.postV1AdminCommunityInsightsOverviewLatestAdminActivity(
                { request: { communities: request } },
            )

        this.recentActivityFromResidents = format(
            new Date(recentActivityFromResidents.timestamp ?? ""),
            DATE_FORMAT_WITH_TIME,
        )
    }

    async getRecentActivityFromTenant(request: number[]) {
        const lastActivityFromAdministrator =
            await CommunityInsightsAdminService.postV1AdminCommunityInsightsOverviewLatestTenantActivity(
                { request: { communities: request } },
            )

        this.lastActivityFromAdministrator = format(
            new Date(lastActivityFromAdministrator.timestamp ?? ""),
            DATE_FORMAT_WITH_TIME,
        )
    }

    private setCommunities(communities: ICommunityOverviewCommunityItem[]) {
        this.allCommunities = communities
    }

    private setAccessGroupId(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }

    setFlagsByCommunities(flagsByCommunities: batch_FlagsByCommunity[]) {
        const flagsByCommunitiesNew: ICommunityFlagsOverview[] =
            flagsByCommunities.map((community) => {
                return {
                    id: community.community_id ?? 0,
                    name: community.community_name ?? "",
                    flags: community.flags ?? {},
                }
            })
        this.flagsByCommunities = flagsByCommunitiesNew
    }

    setDateRangeFilter(value: Date) {
        this.startDate = format(startOfMonth(value), DATE_FORMAT_SHORT)
        this.endDate = format(endOfMonth(value), DATE_FORMAT_SHORT)
    }

    filterBySelectedCommunity(communities: TMultipleSelectCheckmarksOption[]) {
        const selectedCommunitiesIds = communities.map((community) =>
            parseInt(community.value),
        )

        this.selectedCommunities = selectedCommunitiesIds
    }
}
