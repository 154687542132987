import { makeAutoObservable } from "mobx"
import React from "react"
import { t } from "@lingui/macro"

import { loads } from "src/channel/utils"
import { createLoadingKeys } from "src/lib/loading"
import { OmniDomainAdminService } from "src/api"
import { Pagination } from "src/lib/pagination"
import { reportError } from "src/lib/report"
import { IAdvanceQueryModel } from "src/types/data-grid-pro"
import { omnidomain_OmniDomain } from "src/api/models/omnidomain_OmniDomain"
import { Channel } from "src/channel"

export interface IOmniDomain extends omnidomain_OmniDomain {
    id: number
}

export class OmniDomainStore {
    static Context = React.createContext<OmniDomainStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "deleteItem")

    omniDomains = new Pagination<IOmniDomain, unknown>(async (query) => {
        const response = await OmniDomainAdminService.postV1AdminOmnidomainList(
            {
                request: {
                    page_number: query.page,
                    page_size: query.pageSize,
                    filter: query.advanceQuery,
                },
            },
        )

        const items: IOmniDomain[] =
            response.omni_domains?.map((item) => ({
                ...item,
                id: item.omni_domain_id ?? 0,
                // Add any other custom field mappings here if needed
            })) ?? []

        return {
            items,
            count: response.total_count ?? 0,
        }
    })

    private repositoryUpdatesListenerDisposer?: () => void

    constructor() {
        makeAutoObservable(this)
    }

    async init(advanceQuery?: IAdvanceQueryModel) {
        this.listenToRepositoryUpdates()
        await this.omniDomains.loadInitialPage(advanceQuery)
    }

    async query(advanceQuery: IAdvanceQueryModel) {
        await this.omniDomains.loadAdvanceQuery(advanceQuery)
    }

    async getItem(id: number) {
        try {
            return await OmniDomainAdminService.getV1AdminOmnidomain({
                omniDomainId: id,
            })
        } catch (error) {
            reportError(t`omni-domains-view.get-item-fail`, error)
            return null
        }
    }

    async checkDNS(id: number) {
        try {
            return await OmniDomainAdminService.getV1AdminOmnidomainCheckDns({
                omniDomainId: id,
            })
        } catch (error) {
            reportError(t`omni-domains-view.check-dns-fail`, error)
            return null
        }
    }

    @loads(() => OmniDomainStore.LoadingKeys.deleteItem)
    async deleteItem(id: number) {
        try {
            await OmniDomainAdminService.deleteV1AdminOmnidomain({
                omniDomainId: id,
            })
            await this.omniDomains.reload()
        } catch (error) {
            reportError(t`omni-domains-view.delete-item-fail`, error)
        }
    }

    private listenToRepositoryUpdates() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "omni-domains"
                ) {
                    await this.omniDomains.reload()
                }
            },
        )
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }
}
