/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { avy_api_pkg_segment_PublishResourceRequest } from "../models/avy_api_pkg_segment_PublishResourceRequest"
import type { contact_Admin } from "../models/contact_Admin"
import type { contact_DefaultAssigneeUpdate } from "../models/contact_DefaultAssigneeUpdate"
import type { contactv2_AdminFilledOutForm } from "../models/contactv2_AdminFilledOutForm"
import type { contactv2_ContactFormAdminUpdate } from "../models/contactv2_ContactFormAdminUpdate"
import type { contactv2_ContactFormListResponse } from "../models/contactv2_ContactFormListResponse"
import type { contactv2_ContactFormTypeListResponse } from "../models/contactv2_ContactFormTypeListResponse"
import type { contactv2_FormFieldSource } from "../models/contactv2_FormFieldSource"
import type { contactv2_FormType } from "../models/contactv2_FormType"
import type { contactv2_FormTypeWithSegments } from "../models/contactv2_FormTypeWithSegments"
import type { contactv2_GetDefaultAssigneesResponse } from "../models/contactv2_GetDefaultAssigneesResponse"
import type { contactv2_UpdateFormType } from "../models/contactv2_UpdateFormType"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class ContactFormV2AdminService {
    /**
     * Get contact forms
     * Get contact form list
     * @returns contactv2_ContactFormListResponse OK
     * @throws ApiError
     */
    public static getV2AdminContactForm({
        pageNumber = 1,
        pageSize = 10,
        accessGroupId,
        filter,
    }: {
        /** Pagination - number of page to load **/
        pageNumber?: number
        /** Pagination - size of pages to load **/
        pageSize?: number
        /** Access group ID for which to load the contact forms **/
        accessGroupId?: number
        /** Advanced grid filter (JSON object) **/
        filter?: string
    }): CancelablePromise<contactv2_ContactFormListResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v2/admin/contact-form",
            query: {
                page_number: pageNumber,
                page_size: pageSize,
                access_group_id: accessGroupId,
                filter: filter,
            },
        })
    }

    /**
     * Get contact form handlers
     * Get a list of admins that can be assigned to contact forms from a certain property or a list of assigned admins
     * @returns contact_Admin OK
     * @throws ApiError
     */
    public static getV2AdminContactFormAdmins({
        propertyId,
    }: {
        /** Property ID **/
        propertyId?: number
    }): CancelablePromise<Array<contact_Admin>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v2/admin/contact-form/admins",
            query: {
                property_id: propertyId,
            },
        })
    }

    /**
     * List default assignees
     * Returns a list of properties with their default assignees
     * @returns contactv2_GetDefaultAssigneesResponse OK
     * @throws ApiError
     */
    public static getV2AdminContactFormDefaultAssignee({
        pageNumber = 1,
        pageSize = 10,
    }: {
        /** Pagination - number of page to load **/
        pageNumber?: number
        /** Pagination - size of pages to load **/
        pageSize?: number
    }): CancelablePromise<contactv2_GetDefaultAssigneesResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v2/admin/contact-form/default-assignee",
            query: {
                page_number: pageNumber,
                page_size: pageSize,
            },
        })
    }

    /**
     * Update default assignee
     * Updates the default assignee for a property
     * @returns any OK
     * @throws ApiError
     */
    public static postV2AdminContactFormDefaultAssignee({
        request,
    }: {
        /** Request body default assignee update **/
        request: contact_DefaultAssigneeUpdate
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v2/admin/contact-form/default-assignee",
            body: request,
        })
    }

    /**
     * List contact form types
     * List contact form types
     * @returns contactv2_ContactFormTypeListResponse OK
     * @throws ApiError
     */
    public static getV2AdminContactFormType({
        pageNumber = 1,
        pageSize = 10,
        accessGroupId,
    }: {
        /** Pagination - number of page to load **/
        pageNumber?: number
        /** Pagination - size of pages to load **/
        pageSize?: number
        /** Access group ID for which to load the contact form types **/
        accessGroupId?: number
    }): CancelablePromise<contactv2_ContactFormTypeListResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v2/admin/contact-form/type",
            query: {
                page_number: pageNumber,
                page_size: pageSize,
                access_group_id: accessGroupId,
            },
        })
    }

    /**
     * Create form type
     * Create new contact form type
     * @returns any OK
     * @throws ApiError
     */
    public static postV2AdminContactFormType({
        request,
    }: {
        /** Request body contact form type **/
        request: contactv2_FormType
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v2/admin/contact-form/type",
            body: request,
        })
    }

    /**
     * Get integration connector sources
     * Get sources available for contact form by integration connector ID ID
     * @returns contactv2_FormFieldSource OK
     * @throws ApiError
     */
    public static getV2AdminContactFormTypeConnectorSource({
        integrationConnectorId,
    }: {
        /** ID of integration connector **/
        integrationConnectorId: number
    }): CancelablePromise<Array<contactv2_FormFieldSource>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v2/admin/contact-form/type/connector/{integration_connector_id}/source",
            path: {
                integration_connector_id: integrationConnectorId,
            },
        })
    }

    /**
     * Get form type
     * Get form type with segments
     * @returns contactv2_FormTypeWithSegments OK
     * @throws ApiError
     */
    public static getV2AdminContactFormType1({
        formTypeId,
    }: {
        /** ID of form type **/
        formTypeId: number
    }): CancelablePromise<contactv2_FormTypeWithSegments> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v2/admin/contact-form/type/{form_type_id}",
            path: {
                form_type_id: formTypeId,
            },
        })
    }

    /**
     * Update form type
     * Update contact form type by ID
     * @returns any OK
     * @throws ApiError
     */
    public static putV2AdminContactFormType({
        formTypeId,
        request,
    }: {
        /** ID of form type **/
        formTypeId: number
        /** Request body update contact form type **/
        request: contactv2_UpdateFormType
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v2/admin/contact-form/type/{form_type_id}",
            path: {
                form_type_id: formTypeId,
            },
            body: request,
        })
    }

    /**
     * Publish form type
     * Publish (unpublish) form type to segments
     * @returns any OK
     * @throws ApiError
     */
    public static putV2AdminContactFormTypePublish({
        formTypeId,
        request,
    }: {
        /** ID of form type **/
        formTypeId: number
        /** Request body to set where form type is published **/
        request: avy_api_pkg_segment_PublishResourceRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v2/admin/contact-form/type/{form_type_id}/publish",
            path: {
                form_type_id: formTypeId,
            },
            body: request,
        })
    }

    /**
     * Get contact form
     * Get contact form by ID
     * @returns contactv2_AdminFilledOutForm OK
     * @throws ApiError
     */
    public static getV2AdminContactForm1({
        formId,
    }: {
        /** ID of contact form **/
        formId: number
    }): CancelablePromise<contactv2_AdminFilledOutForm> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v2/admin/contact-form/{form_id}",
            path: {
                form_id: formId,
            },
        })
    }

    /**
     * Update contact form
     * Update contact form by ID
     * @returns any OK
     * @throws ApiError
     */
    public static putV2AdminContactForm({
        formId,
        request,
    }: {
        /** ID of contact form **/
        formId: number
        /** Request body contact form **/
        request: contactv2_ContactFormAdminUpdate
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v2/admin/contact-form/{form_id}",
            path: {
                form_id: formId,
            },
            body: request,
        })
    }

    /**
     * Delete contact form
     * Delete contact form by ID
     * @returns any OK
     * @throws ApiError
     */
    public static deleteV2AdminContactForm({
        formId,
    }: {
        /** ID of contact form **/
        formId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v2/admin/contact-form/{form_id}",
            path: {
                form_id: formId,
            },
        })
    }

    /**
     * Get potential contact form handlers
     * Get a list of admins that can be assigned to contact form by ID
     * @returns contact_Admin OK
     * @throws ApiError
     */
    public static getV2AdminContactFormAdmins1({
        formId,
    }: {
        /** ID of contact form **/
        formId: number
    }): CancelablePromise<Array<contact_Admin>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v2/admin/contact-form/{form_id}/admins",
            path: {
                form_id: formId,
            },
        })
    }
}
