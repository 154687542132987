import { MoreHoriz } from "@mui/icons-material"
import { Stack } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"
import { t } from "@lingui/macro"

import {
    HeadUnderline,
    Wrap,
    Author,
    TimeSince,
    InternalObjectIDContainer,
} from "./styled"

import { ActionDropdown } from "src/components/ActionDropdown"
import { Avatar } from "src/components/Avatar"
import { timeSinceDate } from "src/lib/date"
import { ViewCommunityPostStore } from "src/modals/view-community-post/store"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { ConfirmModal } from "src/modals/confirm"
import { CommunityPostDetailModal } from "src/modals/community-post-detail"

export const Head = observer(() => {
    const gstore = useStore(GlobalStore)
    const store = useStore(ViewCommunityPostStore)

    const timeSinceCreated =
        store.post.createdDate != null
            ? timeSinceDate(store.post.createdDate)
            : null

    const handleDeletePostConfirm = useCallback(
        async (confirmed: boolean) => {
            if (confirmed && (await store.deletePost())) {
                gstore.modals.pop()
            }
        },
        [gstore.modals, store],
    )

    const handleDeletePostConfirmModal = useCallback(() => {
        gstore.modals.open(
            () => (
                <ConfirmModal
                    onConfirm={handleDeletePostConfirm}
                    title={t`view-community-post-modal.delete-post-confirm-modal.title`}
                    content={t`view-community-post-modal.delete-post-confirm-modal.content`}
                />
            ),
            { variant: "slide-up-w600" },
        )
    }, [gstore.modals, handleDeletePostConfirm])

    const handleOpenPostDetailModal = useCallback(() => {
        gstore.modals.open(() => (
            <CommunityPostDetailModal id={store.post.id} />
        ))
    }, [gstore.modals, store.post])

    return (
        <Wrap
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="flex-start"
        >
            <Stack direction="row" spacing={2} alignItems="center">
                <Avatar size={56} name={store.post.authorName} />
                <Stack direction="column">
                    <Author>{store.post.authorName}</Author>
                    <HeadUnderline>
                        <TimeSince
                            dateTime={store.post.createdDate?.toLocaleString()}
                        >
                            {timeSinceCreated}
                        </TimeSince>
                        <InternalObjectIDContainer>
                            {t({
                                id: "view-community-post-modal.internal-object-id",
                                values: {
                                    internalObjectId:
                                        store.post.internalObjectId,
                                },
                            })}
                        </InternalObjectIDContainer>
                    </HeadUnderline>
                </Stack>
            </Stack>
            {store.post.accessType === "WRITE" && (
                <ActionDropdown
                    icon={<MoreHoriz fontSize="large" />}
                    items={[
                        {
                            text: t`view-community-post-modal.edit-post-button`,
                            onClick: handleOpenPostDetailModal,
                            hidden: !store.canEditPost(
                                gstore.session.user?.adminId,
                            ),
                        },
                        {
                            text: t`view-community-post-modal.delete-post-button`,
                            destructive: true,
                            loading: gstore.loading.is(
                                ViewCommunityPostStore.LoadingKeys[
                                    "delete-post"
                                ],
                            ),
                            onClick: handleDeletePostConfirmModal,
                        },
                    ]}
                />
            )}
        </Wrap>
    )
})
