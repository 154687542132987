import { observer } from "mobx-react"
import { useEffect, useCallback } from "react"

import { CasesMessagesStore } from "./store"

import { StoreProvider } from "src/store/lib/StoreProvider"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { AvyChat } from "src/components/Chat"
import { useStore } from "src/store/lib/useStore"
import { EAvyChatVariant } from "src/components/Chat/types/chat"
import { boxWrapperStyles } from "src/modals/cases-v2-detail/tabs/Messages/styled"
import { chatbot_MessageInfoSource } from "src/api"
import { environment } from "src/config"

interface IProps {
    conversationId: string
}

const View = observer((props: IProps) => {
    const store = useStore(CasesMessagesStore)

    const openLink = useCallback((url: string) => {
        window.open(url, "_blank", "")
    }, [])

    useEffect(() => {
        ;(async () => {
            await store.init(props.conversationId)
        })()
    }, [store, props.conversationId])

    const handleLinkClick = useCallback(
        (item?: chatbot_MessageInfoSource) => {
            if (
                item === undefined ||
                (item !== undefined && item.object_type !== "information_item")
            ) {
                openLink("")
                return
            }

            const url = `${environment.BASE_URL}/library/information-items/${item.object_id}`
            openLink(url)
        },
        [openLink],
    )

    if (!store.initialized) {
        return <CenteredSpinner height="100vh" />
    }

    return (
        <AvyChat
            variant={EAvyChatVariant.Chat}
            messages={store.messages}
            boxWrapperStyles={boxWrapperStyles}
            handleSubmit={(images: IPersistedFile[], text: string) =>
                store.submitCommentForm(props.conversationId, images, text)
            }
            handleSourceClick={handleLinkClick}
        />
    )
})

export const CasesMessages = (props: IProps) => (
    <StoreProvider Store={CasesMessagesStore}>
        <View {...props} />
    </StoreProvider>
)
