import { t } from "@lingui/macro"
import { FormControlLabel, Stack, Switch } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useMemo } from "react"

import { FormDetailStore } from "./store"

import { FormFieldSet } from "src/components/FormFieldSet"
import { useStore } from "src/store/lib/useStore"

export const ToggleChatAccessSection = observer(() => {
    const store = useStore(FormDetailStore)

    const handleDigitalAccessIntegrationChange = useCallback(
        (_: unknown, checked: boolean) => {
            store.formFields.set("messagingEnabled", checked)
        },
        [store.formFields],
    )

    const statusLabel = useMemo(
        () =>
            Boolean(store.formFields.get("messagingEnabled"))
                ? t`contact-form-type-detail-modal.enable-direct-message.value-enabled`
                : t`contact-form-type-detail-modal.enable-direct-message.value-disabled`,
        [store.formFields],
    )

    return (
        <FormFieldSet>
            <Stack spacing={2}>
                <FormControlLabel
                    checked={store.formFields.get("messagingEnabled")}
                    onChange={handleDigitalAccessIntegrationChange}
                    control={<Switch color="success" />}
                    label={statusLabel}
                />
            </Stack>
        </FormFieldSet>
    )
})
