import { t } from "@lingui/macro"
import { Alert, Button, Typography } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"

import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"
import { ModalHeader } from "src/components/ModalHeader"
import { ChatbotDispatchesContentSection } from "src/modals/chatbot-dispatches/sections/Content"
import { ChatbotDispatchesPublishSettingsSection } from "src/modals/chatbot-dispatches/sections/PublishSettings"
import { ChatbotDispatchesSettingsSection } from "src/modals/chatbot-dispatches/sections/Settings"

import { ChatbotDispatchesStore } from "src/modals/chatbot-dispatches/store"
import { IDispatchesModalProps } from "src/modals/chatbot-dispatches/types/dispatchesProps"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"

export const Modal = observer((props: IDispatchesModalProps) => {
    const store = useStore(ChatbotDispatchesStore)
    const globalStore = useStore(GlobalStore)

    useCloseConfirmationForForm(store.formFields)

    useEffect(() => {
        store.init(props.item)
    }, [store, props.item])

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            await store.validateAndSubmit(props.item)
            if (!store.formFields.hasErrors()) {
                globalStore.modals.pop()
            }
        },
        [store, props.item, globalStore],
    )

    const isButtonDisabled = () => {
        return (
            store.isLoading ||
            store.formFields.get("access_type") === "READ" ||
            store.formFields.getIsDirty() === false
        )
    }

    return (
        <Form onSubmit={handleSubmit}>
            <>
                <ModalHeader>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={isButtonDisabled()}
                        data-testid="submit-chatbot-dispatch-form"
                    >
                        {t`global.save`}
                    </Button>
                </ModalHeader>
                {Boolean(store.formFields.error("generic")) && (
                    <Alert severity="error">
                        {store.formFields.error("generic")}
                    </Alert>
                )}
                <FormPanel
                    sections={[
                        {
                            header: t`chatbot-dispatches-modal.section.dispatch`,
                            content: (
                                <Typography variant="body2">
                                    {store.formFields.get("dispatch_id") ?? "-"}
                                </Typography>
                            ),
                        },
                    ]}
                />
                <ChatbotDispatchesSettingsSection />
                <ChatbotDispatchesContentSection />
                <ChatbotDispatchesPublishSettingsSection />
            </>
        </Form>
    )
})

export const ChatbotDispatchesModal = (props: IDispatchesModalProps) => (
    <StoreProvider Store={ChatbotDispatchesStore}>
        <Modal {...props} />
    </StoreProvider>
)
