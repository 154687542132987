/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from "mobx-react"

import { useCallback } from "react"

import { Stack } from "@mui/material"

import { t } from "@lingui/macro"

import { persistFiles } from "src/lib/file"
import { GeneralCategoriesFieldsTypes } from "src/modals/embedded-integration-v2/types/contentDynamicFields"
import { formFields } from "src/modals/embedded-integration-v2/formFields"
import { DynamicCardLayout } from "src/components/DynamicCardLayout"
import { IGeneralDynamicFieldsSectionProps } from "src/modals/embedded-integration-v2/types/generalDynamicFieldsSection"
import { useStore } from "src/store/lib/useStore"

import { ModalTextField } from "src/components/ModalTextField"
import { IEmbedCategoryFieldProps } from "src/modals/embedded-integration-v2/types/embedCategoryField"
import { CardItemWrapper } from "src/modals/embedded-integration-v2/sections/ContentSection/styled"
import { ContentSectionStore } from "src/modals/embedded-integration-v2/sections/ContentSection/store"

export const EmbeddedIntegrationGeneralTabSection = observer(
    ({
        index,
        categoryFields,
        onRemoveClick,
        onContentCopyClick,
    }: IGeneralDynamicFieldsSectionProps) => {
        const contentStore = useStore(ContentSectionStore)
        const generalDynamicFieldsList = contentStore.generalTabFields
        const categories = contentStore.fields.data.categoryList

        const getCategoriesListArray = () => {
            const categoriesList = categories.map((item) => ({
                id: item.name,
                label: item.name,
            }))
            return categoriesList
        }

        const handleIconDelete = useCallback(
            (i: number) => {
                const data = [...generalDynamicFieldsList]
                const obj = JSON.parse(
                    JSON.stringify(data[index].categoryFields),
                )
                obj[i].description = undefined
                data[index].categoryFields = obj
                contentStore.fields.set("generalDynamicFieldsList", data)
            },
            [index, generalDynamicFieldsList, contentStore],
        )

        const handleIconChange = useCallback(
            async (images: IFile[], i: number) => {
                const data = [...generalDynamicFieldsList]
                const obj = JSON.parse(
                    JSON.stringify(data[index].categoryFields),
                )
                const icon = images[0]
                if (Object.keys(icon).length > 0) {
                    const images = await persistFiles(
                        [icon as ILocalFile],
                        "image",
                    )

                    if (images.length > 0) {
                        obj[i].description = images[0].url
                        obj[i].errorMessage = ""
                        data[index].categoryFields = obj
                        contentStore.fields.set(
                            "generalDynamicFieldsList",
                            data,
                        )
                    }
                }
            },
            [index, generalDynamicFieldsList, contentStore],
        )

        const handleTextInputChange = (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            i: number,
        ) => {
            const data = [...generalDynamicFieldsList]
            const obj = JSON.parse(JSON.stringify(data[index]))
            obj.categoryFields[i].errorMessage = ""
            obj.categoryFields[i].description =
                event.target.value.trim() !== "" ? event.target.value : ""
            data[index] = obj
            contentStore.fields.set("generalDynamicFieldsList", data)
        }
        const onSelectValueChange = (value: string) => {
            const data = [...generalDynamicFieldsList]
            const obj = JSON.parse(JSON.stringify(data[index]))
            obj.categoryDropdownValue.errorMessage = ""
            const inputValue = value.trim()
            if (inputValue === "") {
                obj.categoryDropdownValue.errorMessage = t`errors.required`
            }
            if (
                data.length > 1 &&
                data.filter(
                    (item) => item.categoryDropdownValue.value === inputValue,
                )?.length > 0
            ) {
                obj.categoryDropdownValue.value = ""
                obj.categoryFields = []
                obj.categoryDropdownValue.errorMessage = t`embed-integrations-modal.content-section.general-tab.category-error-text`
            } else {
                obj.categoryDropdownValue.value =
                    inputValue !== "" ? inputValue : ""
                obj.categoryFields =
                    categories?.find((item) => item.name === inputValue)?.fields
                        ?.fields ?? []
            }

            data[index] = obj
            contentStore.fields.set("generalDynamicFieldsList", data)
        }

        const renderTextInput = (
            item: IEmbedCategoryFieldProps,
            index: number,
            maxLength?: number,
        ) => {
            return (
                <ModalTextField
                    variant="default"
                    label={item?.label ?? ""}
                    value={item.description}
                    onChange={(event) => handleTextInputChange(event, index)}
                    helperText={item.errorMessage}
                    error={Boolean(item.errorMessage)}
                    maxLength={maxLength ?? 255}
                    rows={1}
                />
            )
        }
        const renderAddFileSection = (
            item: IEmbedCategoryFieldProps,
            index: number,
        ) => {
            return (
                <formFields.EmbeddedIntegrationSvgIconUpload
                    header={t`embed-integrations-modal.content-section.general-tab.image-upload.svg-icon-header`}
                    subHeader={t`embed-integrations-modal.content-section.general-tab.image-upload.svg-icon-subheader`}
                    value={item.description ?? ""}
                    onIconDelete={() => handleIconDelete(index)}
                    onIconChange={(images) => handleIconChange(images, index)}
                    errorMessage={item.errorMessage ?? ""}
                />
            )
        }

        return (
            <DynamicCardLayout
                index={index}
                showDraggableIndicator={false}
                hideCopyContentIcon={true}
                hideBinIcon={false}
                onRemoveClick={onRemoveClick}
                onContentCopyClick={onContentCopyClick}
            >
                <CardItemWrapper>
                    <Stack spacing={2}>
                        <formFields.EmbeddedIntegrationDropdown
                            optionArray={getCategoriesListArray()}
                            selectedValue={
                                generalDynamicFieldsList[index]
                                    .categoryDropdownValue.value
                            }
                            onSelectionChange={onSelectValueChange}
                            errorMessage={
                                generalDynamicFieldsList[index]
                                    .categoryDropdownValue.errorMessage
                            }
                            disabled={false}
                            inputLabel={t`embed-integrations-modal.publish-content-section.general-tab.dropdown-category`}
                            label={t`embed-integrations-modal.publish-content-section.general-tab.dropdown-category`}
                        />
                        {categoryFields.map(
                            (item: IEmbedCategoryFieldProps, i: number) => {
                                const isButton = item?.label
                                    ?.toLowerCase()
                                    ?.includes("button")
                                return (
                                    <div key={i}>
                                        {item.type ===
                                        GeneralCategoriesFieldsTypes.Text
                                            ? renderTextInput(
                                                  item,
                                                  i,
                                                  isButton ?? false ? 36 : 255,
                                              )
                                            : null}
                                        {item.type ===
                                        GeneralCategoriesFieldsTypes.File
                                            ? renderAddFileSection(item, i)
                                            : null}
                                    </div>
                                )
                            },
                        )}
                    </Stack>
                </CardItemWrapper>
            </DynamicCardLayout>
        )
    },
)
