import { observer } from "mobx-react"

import { useCallback } from "react"

import { t } from "@lingui/macro"

import { Stack } from "@mui/material"

import { persistFiles } from "src/lib/file"
import { formFields } from "src/modals/embedded-integration-v2/formFields"
import { DynamicCardLayout } from "src/components/DynamicCardLayout"
import { ModalTextField } from "src/components/ModalTextField"
import { IDynamicFieldItemProps } from "src/modals/embedded-integration-v2/types/dynamicFieldItem"
import { IPurchaseTabDynamicRichLinkFieldsProps } from "src/modals/embedded-integration-v2/types/purchaseRickLinkDynamicFields"
import { useStore } from "src/store/lib/useStore"
import { ContentSectionStore } from "src/modals/embedded-integration-v2/sections/ContentSection/store"

export const EmbeddedIntegrationRickLinkCardSection = observer(
    ({
        index,
        item,
        purchaseType,
        onRemoveClick,
    }: IPurchaseTabDynamicRichLinkFieldsProps) => {
        const contentStore = useStore(ContentSectionStore)
        const purchaseRichLinkDynamicFieldsList =
            purchaseType.purchaseRichLinkDynamicFieldsList
        const salePurchaseSelectedType =
            contentStore.fields.data.salesPurchaseType

        const handleIconDelete = useCallback(
            (key: string) => {
                const purchaseObject = {
                    ...purchaseType,
                }
                const data = [...purchaseRichLinkDynamicFieldsList]
                const obj = JSON.parse(JSON.stringify(data[index]))
                obj[key].value = undefined
                data[index] = obj
                purchaseObject.purchaseRichLinkDynamicFieldsList = data
                contentStore.fields.set(
                    salePurchaseSelectedType,
                    purchaseObject,
                )
            },
            [
                index,
                purchaseType,
                purchaseRichLinkDynamicFieldsList,
                salePurchaseSelectedType,
                contentStore,
            ],
        )

        const handleIconChange = useCallback(
            async (images: IFile[], key: string) => {
                const purchaseObject = {
                    ...purchaseType,
                }
                const data = [...purchaseRichLinkDynamicFieldsList]
                const obj = JSON.parse(JSON.stringify(data[index]))
                const icon = images[0]
                if (Object.keys(icon).length > 0) {
                    const images = await persistFiles(
                        [icon as ILocalFile],
                        "image",
                    )

                    if (images.length > 0) {
                        obj[key].value = images[0].url
                        obj[key].errorMessage = ""
                        data[index] = obj
                        purchaseObject.purchaseRichLinkDynamicFieldsList = data
                        contentStore.fields.set(
                            salePurchaseSelectedType,
                            purchaseObject,
                        )
                    }
                }
            },
            [
                index,
                purchaseType,
                purchaseRichLinkDynamicFieldsList,
                salePurchaseSelectedType,
                contentStore,
            ],
        )

        const handleTextInputChange = (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            key: string,
        ) => {
            const purchaseObject = {
                ...purchaseType,
            }
            const data = [...purchaseRichLinkDynamicFieldsList]
            const obj = JSON.parse(JSON.stringify(data[index]))
            obj[key].errorMessage = ""
            obj[key].value =
                event.target.value.trim() !== "" ? event.target.value : ""
            data[index] = obj
            purchaseObject.purchaseRichLinkDynamicFieldsList = data

            contentStore.fields.set(salePurchaseSelectedType, purchaseObject)
        }

        const renderTextInput = (
            label: string,
            item: IDynamicFieldItemProps,
            key: string,
            rowLength: number,
            maxLength?: number,
        ) => {
            return (
                <ModalTextField
                    variant="default"
                    label={label}
                    value={item?.value}
                    onChange={(event) => handleTextInputChange(event, key)}
                    helperText={item.errorMessage}
                    error={Boolean(item.errorMessage)}
                    maxLength={maxLength ?? 255}
                    rows={rowLength}
                />
            )
        }
        const renderAddFileSection = (
            item: IDynamicFieldItemProps,
            key: string,
        ) => {
            return (
                <formFields.EmbeddedIntegrationSvgIconUpload
                    header={t`embed-integrations-modal.content-section.purchase-tab.rich-link.svg-header`}
                    subHeader={t`embed-integrations-modal.content-section.sales-tab.sales-card.svg-icon.subtitle`}
                    value={item.value ?? ""}
                    onIconDelete={() => handleIconDelete(key)}
                    onIconChange={(images) => handleIconChange(images, key)}
                    errorMessage={item.errorMessage ?? ""}
                />
            )
        }

        return (
            <DynamicCardLayout
                index={index}
                showDraggableIndicator={true}
                hideCopyContentIcon={true}
                hideBinIcon={false}
                onRemoveClick={onRemoveClick}
            >
                <Stack spacing={2}>
                    {renderTextInput(
                        t`embed-integrations-modal.content-section.purchase-tab.rich-link.title`,
                        item.linkTitle,
                        "linkTitle",
                        1,
                        255,
                    )}
                    {renderTextInput(
                        t`embed-integrations-modal.content-section.purchase-tab.rich-link.subtitle`,
                        item.linkSubTitle,
                        "linkSubTitle",
                        1,
                        255,
                    )}
                    {renderTextInput(
                        t`embed-integrations-modal.content-section.purchase-tab.rich-link.url`,
                        item.linkUrl,
                        "linkUrl",
                        1,
                        255,
                    )}
                    {renderAddFileSection(item.linkSvgFile, "linkSvgFile")}
                </Stack>
            </DynamicCardLayout>
        )
    },
)
