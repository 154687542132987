/* eslint-disable react-hooks/exhaustive-deps */
import { t } from "@lingui/macro"
import { Alert, Button } from "@mui/material"
import { observer } from "mobx-react"

import { useEffect } from "react"

import { FormDetailStore } from "./store"

import { PublishingSettingsSection } from "./PublishingSettingsSection"

import { FormDetailSection } from "./FormDetailSection"

import { FormFieldsSection } from "./FormFieldsSection"

import useFieldList from "./hooks/useFieldsList"

import {
    IDynamicFieldsListItemProps,
    IExternalDynamicFieldsListItemProps,
} from "./types/fieldsListItem"

import { ToggleChatAccessSection } from "./ToggleChatAccessSection"

import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"
import { ModalHeader } from "src/components/ModalHeader"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { OutputType } from "src/types/output"
import { dynamicFieldsValidationCheck } from "src/modals/contact-forms-form-builder/helper/dynamicFieldsValidationCheckHelper"
import { createUpdateRequestBody } from "src/modals/contact-forms-form-builder/helper/createUpdateRequestBodyHelper"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"
import { CenteredSpinner } from "src/components/CenteredSpinner"
import { useInitializer } from "src/lib/initializer"

import { FormFieldSet } from "src/components/FormFieldSet"

interface IProps {
    id?: number
}

const FormDetailView = observer((props: IProps) => {
    const globalStore = useStore(GlobalStore)
    const store = useStore(FormDetailStore)
    const integrationId = store.formFields.get("selectedConnectorId") ?? 0

    const {
        dynamicFieldsList,
        setDynamicFieldsList,
        externalDynamicFieldsList,
        setExternalDynamicFieldsList,
    } = useFieldList()

    useCloseConfirmationForForm(store.formFields)

    const initialized = useInitializer(async () => {
        const connectorsResponse = await store.getAllIntegrations("")
        await store.init(
            connectorsResponse.items,
            globalStore.session.accessGroup.id as number,
            props.id,
        )
    }, [store, props.id, globalStore.session.accessGroup])

    useEffect(() => {
        if (store.fields != null && store.fields.length > 0) {
            if (integrationId > 0) {
                setExternalDynamicFieldsList(
                    store.externalDynamicFieldsList as IExternalDynamicFieldsListItemProps[],
                )
            } else {
                setDynamicFieldsList(
                    store.dynamicFieldsList as IDynamicFieldsListItemProps[],
                )
            }
        }
    }, [store, store.fields])

    const handleSubmit = async () => {
        store.validateRequiredFields()

        if (store.formFields.hasErrors() === true) {
            store.setIsLoading(false)
            return
        }

        if (
            !store.isEditMode &&
            (dynamicFieldsList.length > 0 ||
                externalDynamicFieldsList.length > 0)
        ) {
            const list:
                | IDynamicFieldsListItemProps[]
                | IExternalDynamicFieldsListItemProps[] = dynamicFieldsValidationCheck(
                dynamicFieldsList,
                externalDynamicFieldsList,
                store.formFields.get("outputTypeSelectedValue") ?? "",
                store.setHasError,
            )
            if (
                store.formFields.get("outputTypeSelectedValue") !==
                OutputType.Integration
            ) {
                setExternalDynamicFieldsList(
                    list as IExternalDynamicFieldsListItemProps[],
                )
            } else {
                setDynamicFieldsList(list as IDynamicFieldsListItemProps[])
            }
        }

        if (store.hasError) {
            return
        }
        const request = createUpdateRequestBody(
            store.formFields,
            externalDynamicFieldsList,
            dynamicFieldsList,
            integrationId,
            store.isEditMode,
        )
        await store.createUpdateForm(props.id ?? 0, request)
        globalStore.modals.pop()
    }

    if (!initialized) {
        return <CenteredSpinner height="100vh" />
    }

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={store.isLoading}
                >
                    {t`global.save`}
                </Button>
            </ModalHeader>
            {Boolean(store.formFields.error("generic")) && (
                <Alert severity="error">
                    {store.formFields.error("generic")}
                </Alert>
            )}

            <FormPanel
                sections={[
                    {
                        header: t`resource-detail-modal.publishing-settings-section.section-header`,
                        content: <PublishingSettingsSection />,
                    },
                ]}
            />

            <FormPanel
                sections={[
                    {
                        header: t`contact-form-forms-detail-modal.form-detail-settings-section.section-header`,
                        content: <FormDetailSection />,
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`contact-form-forms-detail-moda.enable-direct-message.header-title`,
                        content: (
                            <FormFieldSet
                                header={t`contact-form-forms-detail-moda.enable-direct-message.description`}
                            >
                                <ToggleChatAccessSection />
                            </FormFieldSet>
                        ),
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`contact-form-forms-detail-modal.form-fields-settings-section.section-header`,
                        content: (
                            <FormFieldsSection
                                dynamicFieldsList={dynamicFieldsList}
                                setDynamicFieldsList={setDynamicFieldsList}
                                externalDynamicFieldsList={
                                    externalDynamicFieldsList
                                }
                                setExternalDynamicFieldsList={
                                    setExternalDynamicFieldsList
                                }
                            />
                        ),
                    },
                ]}
            />
        </Form>
    )
})

export const FormDetailModalView = (props: IProps) => (
    <StoreProvider Store={FormDetailStore}>
        <FormDetailView {...props} />
    </StoreProvider>
)
