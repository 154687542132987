import { makeAutoObservable } from "mobx"
import React from "react"

import { BookingAdminService } from "src/api"
import { DEFAULT_ACCESS_GROUP } from "src/config"

import { parseDate } from "src/lib/date"

import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"

export class BookingsStore {
    static Context = React.createContext<BookingsStore | null>(null)
    static LoadingKeys = createLoadingKeys("pageLoad")

    constructor() {
        makeAutoObservable(this)
    }

    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id

    bookings = new Pagination(
        async (query) => {
            const response = await BookingAdminService.getV1AdminBooking({
                pageNumber: query.page,
                pageSize: query.pageSize,
                accessGroupId: this.getAccessGroupId(),
            })

            const items = (response.result ?? []).map((booking) => ({
                id: booking.booking_id ?? -1,
                tenantName: booking.tenant_name ?? "",
                propertyObjectAddress: booking.property_object_address ?? "",
                resourceName: booking.resource_name ?? "",
                createdAt: parseDate(booking.created_at),
                startTime: parseDate(booking.start_time),
                endTime: parseDate(booking.end_time),
                status: this.getStatus(booking.status),
            }))
            return { items, count: response.total_count ?? 0 }
        },
        {
            loadingKey: BookingsStore.LoadingKeys.pageLoad,
        },
    )

    async init(accessGroupId: number) {
        this.setAccountGroupId(accessGroupId)
        await this.bookings.loadInitialPage()
    }

    private getAccessGroupId() {
        return this._accessGroupId !== DEFAULT_ACCESS_GROUP.id
            ? this._accessGroupId
            : undefined
    }

    private setAccountGroupId(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }

    /**
     * `status` is documented as just a string in the api but can only be
     * Approved and Cancelled (currently). If `status` is something else than
     * the supported values it is set to "-"
     */
    private getStatus(status?: string): BookingStatus {
        if (status === "Approved" || status === "Cancelled") {
            return status
        } else {
            return "-"
        }
    }
}
