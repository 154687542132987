import { t } from "@lingui/macro"
import { SelectChangeEvent } from "@mui/material"
import { action, makeAutoObservable } from "mobx"

import { ApiError } from "src/api"
import { apiErrorToMap, unknownApiErrorToGenericError } from "src/lib/api"
import { IFormFieldValidation } from "src/types/form-fields/validation"

type ISetterFunction = (
    event:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string | number>,
) => void

type IFieldErrors<TFields> = Partial<Record<keyof TFields | "generic", string>>
type ISetterMap<TFields> = { [K in keyof TFields]?: ISetterFunction }
type IFieldDirty<TFields> = { [K in keyof TFields]?: boolean }
/**
 * FormFields is in-store representation of an html form. It creates getters and
 * setters for fields and stores errors related to those fields.
 */
export class FormFields<TFields extends Object> {
    private errors: IFieldErrors<TFields> = {}
    private dirtyFields: IFieldDirty<TFields> = {}
    private cachedSetters: ISetterMap<TFields> = {}
    private copiedFields: TFields | undefined

    constructor(private fields: TFields) {
        makeAutoObservable(this)
    }

    get data() {
        return { ...this.fields }
    }

    getIsDirty() {
        const dirtyFields = this.getAllDirtyFields()
        return dirtyFields.length > 0
    }

    getIsFieldDirty<TKey extends keyof TFields>(key: TKey) {
        return this.dirtyFields[key] ?? false
    }

    setIsDirty<TKey extends keyof TFields>(key: TKey, isDirty: boolean) {
        this.dirtyFields[key] = isDirty
    }

    getAllDirtyFields() {
        return Object.keys(this.fields).filter(
            (key) => this.dirtyFields[key as keyof TFields],
        ) as (keyof TFields)[]
    }

    setAllFieldsAsDirty() {
        Object.keys(this.fields).forEach((key) => {
            this.setIsDirty(key as keyof TFields, true)
        })
    }

    get<TKey extends keyof TFields>(key: TKey) {
        return this.fields[key]
    }

    set<TKey extends keyof TFields>(key: TKey, value: TFields[TKey]) {
        if (this.fields[key] !== value && this.error(key) != null) {
            this.clearError(key)
        }
        const initialValue =
            this.copiedFields !== undefined && this.copiedFields[key]

        this.fields[key] = value
        this.setIsDirty(
            key,
            JSON.stringify(initialValue) !== JSON.stringify(this.fields[key]),
        )
    }

    setter<TKey extends keyof TFields>(key: TKey): ISetterFunction {
        let setterFn = this.cachedSetters[key]

        if (setterFn == null) {
            this.cachedSetters[key] = setterFn = action((event) => {
                if (
                    event.currentTarget != null &&
                    "value" in event.currentTarget
                ) {
                    // TODO: fix typing
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    this.set(key, event.currentTarget.value as any)
                } else if (event.target != null && "value" in event.target) {
                    // TODO: fix typing
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    this.set(key, event.target.value as any)
                }
            })
        }

        return setterFn
    }

    init(fields: TFields) {
        this.fields = fields
        this.copiedFields = this.clonedFields(fields)
        Object.keys(fields).forEach((key) =>
            this.setIsDirty(key as keyof TFields, false),
        )
    }
    clonedFields(fields: TFields) {
        return { ...fields }
    }
    hasErrors() {
        return Object.keys(this.errors).length > 0
    }

    error<TKey extends keyof IFieldErrors<TFields>>(key: TKey) {
        return this.errors[key] ?? null
    }

    setErrors(
        errorsOrApiError:
            | { [key: number | symbol | string]: string | undefined }
            | ApiError,
    ) {
        const errors =
            errorsOrApiError instanceof ApiError
                ? apiErrorToMap(errorsOrApiError)
                : errorsOrApiError

        this.errors = {}

        const keys = Object.keys(this.fields) as (keyof TFields)[]
        keys.forEach((field) => {
            if (errors[field] != null) {
                this.errors[field] = errors[field]
            }
        })

        if (errors["generic"] != null) {
            this.errors["generic"] = errors["generic"]
        }
    }

    async catchErrors(fn: () => Promise<void>) {
        this.clearErrors()
        try {
            await fn()
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status === 400) {
                    this.setErrors(e)
                } else {
                    this.setError(
                        "generic",
                        t({
                            id: "errors.generic-with-message",
                            values: {
                                message: unknownApiErrorToGenericError(e),
                            },
                        }),
                    )
                }
            } else {
                throw e
            }
        }
    }

    setError<TKey extends keyof IFieldErrors<TFields>>(
        key: TKey,
        error: string,
    ) {
        this.errors[key] = error
    }

    clearErrors() {
        this.errors = {}
    }

    clearError<TKey extends keyof TFields>(key: TKey) {
        this.errors[key] = undefined
        delete this.errors[key]
    }

    /**
     * @param field - Array of fields that are required
     * @param errorMessage - Custom Error message to display if field is empty, else generic error message is displayed
     * @param validate - Custom validation function to validate the field value / callback function
     * @returns void
     *
     * This function validates required fields for a nullable check
     * Eliminates blank spaces and checks if the field is empty
     **/
    validateRequiredFields(payload: IFormFieldValidation<TFields>[]) {
        this.clearErrors()
        payload.forEach((validator) => {
            const { field, errorMessage, validate } = validator
            const formField = this.get(field)
            const value =
                typeof formField === "string" ? formField.trim() : formField
            const isValid = validate !== undefined ? validate(formField) : true

            if (value == null || value === "" || !isValid) {
                this.setError(field, errorMessage ?? t`errors.required`)
            } else {
                this.clearError(field)
            }
        })
    }
}
