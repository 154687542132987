import { t } from "@lingui/macro"
import { memo } from "react"
import { Box, Typography } from "@mui/material"
import {
    LibraryBooksRounded,
    OutboxRounded,
    CachedRounded,
    NotInterested,
} from "@mui/icons-material"

import {
    MessageTypeKey,
    IMessageTypeProps,
    IMessageTypeSectionProps,
} from "src/components/ChatMessageJourney/types"

const MESSAGE_TYPE_CONFIG: Record<MessageTypeKey, IMessageTypeProps> = {
    CHAT_ANSWER: {
        color: "rgba(102, 187, 106, 1)",
        label: "Chat answer",
        icon: <LibraryBooksRounded sx={{ fontSize: "18px" }} />,
    },
    DISPATCH: {
        color: "rgba(30, 136, 229, 1)",
        label: "Dispatch",
        icon: <OutboxRounded sx={{ fontSize: "18px" }} />,
    },
    CLARIFICATION: {
        color: "rgba(245, 124, 0, 1)",
        label: "Clarification",
        icon: <CachedRounded sx={{ fontSize: "18px" }} />,
    },
    NO_RESPONSE: {
        color: "rgba(189, 189, 189, 1)",
        label: "No response",
        icon: <NotInterested sx={{ fontSize: "18px" }} />,
    },
}

const MessageType = memo(({ color, label, icon }: IMessageTypeProps) => (
    <Box
        sx={{
            bgcolor: color,
            color: "success.contrastText",
            padding: "5px",
            borderRadius: 1,
            display: "inline-flex",
            alignItems: "center",
        }}
    >
        {icon}
        <Typography sx={{ py: 0, px: 0.5, fontSize: "14px", fontWeight: 500 }}>
            {label}
        </Typography>
    </Box>
))

const MessageTypeSection = memo(({ messageType }: IMessageTypeSectionProps) => (
    <Box component="section" sx={{ marginBottom: 2 }}>
        <Typography variant="subtitle2" sx={{ marginBottom: 0.5 }}>
            {t`chat-message-journey.message-type`}
        </Typography>
        <MessageType {...MESSAGE_TYPE_CONFIG[messageType ?? "CHAT_ANSWER"]} />
    </Box>
))

export default MessageTypeSection
