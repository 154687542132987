import { t } from "@lingui/macro"

import { useCallback } from "react"

import { observer } from "mobx-react"

import { Stack, Typography } from "@mui/material"

import { FormFields } from "src/modals/navigation-items-detail/components/formFields"
import { FormFieldSet } from "src/components/FormFieldSet"
import AssetLibraryPicker from "src/components/AssetLibraryPicker"

import { useStore } from "src/store/lib/useStore"

import { NavigationItemDetailStore } from "src/modals/navigation-items-detail/store"
import { persistFiles } from "src/lib/file"
import {
    CONTENT_GUIDELINES,
    CONTENT_READ_GUIDELINES,
} from "src/modals/navigation-items-detail/constants"

import { GlobalStore } from "src/store"

export const ContentSection = observer(() => {
    const navDetailStore = useStore(NavigationItemDetailStore)
    const globalStore = useStore(GlobalStore)

    const handleIconChange = useCallback(
        async (images: IFile[]) => {
            const icon = images[0]
            if (Object.keys(icon).length > 0) {
                const images = await persistFiles([icon as ILocalFile], "image")

                if (images.length > 0) {
                    navDetailStore.form.set("imageUrl", images[0].url)
                }
            }
        },
        [navDetailStore.form],
    )

    const handleIconDelete = useCallback(() => {
        navDetailStore.form.set("imageUrl", "")
    }, [navDetailStore.form])

    return (
        <Stack spacing={2}>
            <Typography>
                {t`navigation-items-view.content-section.keep-text-brief`}{" "}
                <FormFields.LinkGuideline
                    link={CONTENT_GUIDELINES}
                    text={t`navigation-items-view.content-section.guidelines`}
                />{" "}
                {t`navigation-items-view.content-section.will-assist-you`}
            </Typography>

            {navDetailStore.form.get("showInMainNavigation") && (
                <>
                    <Typography variant="subtitle1">{t`navigation-items-view.content-section.navigation`}</Typography>
                    <FormFields.NavigationItemNumberInput
                        label={t`navigation-items-view.content-section.navigation-priority`}
                        key={"priorityMainNavigation"}
                        disabled={navDetailStore.isReadOnly}
                        maxLength={10}
                        helperText={
                            navDetailStore.form.error(
                                "priorityMainNavigation",
                            ) ?? ""
                        }
                        value={navDetailStore.form.get(
                            "priorityMainNavigation",
                        )}
                        handleTextInputChange={(event) => {
                            navDetailStore.form.set(
                                "priorityMainNavigation",
                                event.target.value,
                            )
                        }}
                    />
                    <FormFields.NavigationItemTextInput
                        label={t`navigation-items-view.content-section.navigation-header-swedish`}
                        key={"svHeaderMainNavigation"}
                        rowLength={1}
                        disabled={navDetailStore.isReadOnly}
                        maxLength={30}
                        helperText={
                            navDetailStore.form.error(
                                "svHeaderMainNavigation",
                            ) ?? ""
                        }
                        value={navDetailStore.form.get(
                            "svHeaderMainNavigation",
                        )}
                        handleTextInputChange={(event) => {
                            navDetailStore.form.set(
                                "svHeaderMainNavigation",
                                event.target.value,
                            )
                        }}
                    />
                    <FormFields.NavigationItemTextInput
                        label={t`navigation-items-view.content-section.navigation-header-english`}
                        key={"enHeaderMainNavigation"}
                        rowLength={1}
                        disabled={navDetailStore.isReadOnly}
                        maxLength={30}
                        helperText={
                            navDetailStore.form.error(
                                "enHeaderMainNavigation",
                            ) ?? ""
                        }
                        value={navDetailStore.form.get(
                            "enHeaderMainNavigation",
                        )}
                        handleTextInputChange={(event) => {
                            navDetailStore.form.set(
                                "enHeaderMainNavigation",
                                event.target.value,
                            )
                        }}
                    />
                </>
            )}
            {navDetailStore.form.get("showInOverview") && (
                <>
                    <Typography variant="subtitle1">{t`navigation-items-view.content-section.overview`}</Typography>
                    <FormFields.NavigationItemNumberInput
                        label={t`navigation-items-view.content-section.overview-priority`}
                        key={"priorityOverview"}
                        disabled={navDetailStore.isReadOnly}
                        maxLength={10}
                        helperText={
                            navDetailStore.form.error("priorityOverview") ?? ""
                        }
                        value={navDetailStore.form.get("priorityOverview")}
                        handleTextInputChange={(event) => {
                            navDetailStore.form.set(
                                "priorityOverview",
                                event.target.value,
                            )
                        }}
                    />
                    <FormFields.NavigationItemTextInput
                        label={t`navigation-items-view.content-section.overview-header-swedish`}
                        key={"svHeaderOverview"}
                        rowLength={1}
                        disabled={navDetailStore.isReadOnly}
                        maxLength={30}
                        helperText={
                            navDetailStore.form.error("svHeaderOverview") ?? ""
                        }
                        value={navDetailStore.form.get("svHeaderOverview")}
                        handleTextInputChange={(event) => {
                            navDetailStore.form.set(
                                "svHeaderOverview",
                                event.target.value,
                            )
                        }}
                    />
                    <FormFields.NavigationItemTextInput
                        label={t`navigation-items-view.content-section.overview-header-english`}
                        key={"enHeaderOverview"}
                        rowLength={1}
                        disabled={navDetailStore.isReadOnly}
                        maxLength={30}
                        helperText={
                            navDetailStore.form.error("enHeaderOverview") ?? ""
                        }
                        value={navDetailStore.form.get("enHeaderOverview")}
                        handleTextInputChange={(event) => {
                            navDetailStore.form.set(
                                "enHeaderOverview",
                                event.target.value,
                            )
                        }}
                    />
                    <FormFields.NavigationItemTextInput
                        label={t`navigation-items-view.content-section.overview-subheader-swedish`}
                        key={"svSubHeader"}
                        rowLength={1}
                        disabled={navDetailStore.isReadOnly}
                        maxLength={30}
                        value={navDetailStore.form.get("svSubHeader")}
                        handleTextInputChange={(event) => {
                            navDetailStore.form.set(
                                "svSubHeader",
                                event.target.value,
                            )
                        }}
                    />
                    <FormFields.NavigationItemTextInput
                        label={t`navigation-items-view.content-section.overview-subheader-english`}
                        key={"enSubHeader"}
                        rowLength={1}
                        disabled={navDetailStore.isReadOnly}
                        maxLength={30}
                        value={navDetailStore.form.get("enSubHeader")}
                        handleTextInputChange={(event) => {
                            navDetailStore.form.set(
                                "enSubHeader",
                                event.target.value,
                            )
                        }}
                    />
                    <Typography variant="h6">{t`navigation-items-view.content-section.image-optional`}</Typography>
                    <FormFieldSet>
                        <Typography>
                            {t`navigation-items-view.content-section.upload-image-represent`}{" "}
                            <FormFields.LinkGuideline
                                link={
                                    CONTENT_READ_GUIDELINES[
                                        globalStore.session.language ?? "sv"
                                    ]
                                }
                                text={t`navigation-items-view.content-section.read-guidelines`}
                            />
                        </Typography>
                        <br />
                        <AssetLibraryPicker
                            value={navDetailStore.form.get("imageUrl")}
                            onIconDelete={() => handleIconDelete()}
                            onIconChange={(images: IFile[]) =>
                                handleIconChange(images)
                            }
                            errorMessage={
                                navDetailStore.form.error("imageUrl") ?? ""
                            }
                            setErrorMessage={(error) =>
                                navDetailStore.form.setError("imageUrl", error)
                            }
                            acceptedFormats={[
                                "image/png",
                                "image/jpeg",
                                "image/jpg",
                            ]}
                            aspectRatio="1/1"
                            disabled={navDetailStore.isReadOnly}
                            activeAssetType={"images"}
                        />
                    </FormFieldSet>
                </>
            )}
        </Stack>
    )
})
