import { t } from "@lingui/macro"
import { Stack, Typography, useTheme } from "@mui/material"
import { observer } from "mobx-react"
import { ReactNode, useCallback, useMemo } from "react"

import { NoWrap } from "src/components/NoWrap"

import { Table } from "src/components/Table"
import { TruncatedText } from "src/components/TruncatedText"

import { uniqueId } from "src/lib/unique-id"

import { useStore } from "src/store/lib/useStore"
import { RowCell } from "src/views/community-insights/components/RowCell"
import { tableStyles } from "src/views/community-insights/components/styles"
import { CommunityInsightsStore } from "src/views/community-insights/store"
import {
    ICommunitiesInsight,
    IHeaders,
} from "src/views/community-insights/types"

export const InsightsTable = observer(() => {
    const store = useStore(CommunityInsightsStore)
    const { palette } = useTheme()

    // Generate a dynamic header based on the periods
    const getDynamicHeader = useMemo((): IHeaders => {
        const periodHeaders = store.periods.map((period, index) => ({
            key: `period${index + 1}`,
            name: period,
        }))
        const headers: IHeaders = [
            {
                key: "communityId",
                name: t`community-insights.community-and-id`,
            },
            ...periodHeaders,
        ]
        return headers
    }, [store.periods])

    // Assign cell values to the corresponding dynamic header
    const getRowRenderer = useCallback(
        (item: ICommunitiesInsight) =>
            getDynamicHeader.reduce((acc, curr) => {
                const period = item.periods.find(
                    (period) => period.period === curr.name,
                )
                acc[curr.key] = (
                    <NoWrap>
                        <Stack
                            padding="4px 8px"
                            height={24}
                            paddingLeft={
                                curr.key === "communityId" ? "16px" : undefined
                            }
                            maxWidth={
                                curr.key === "communityId" ? "200px" : undefined
                            }
                        >
                            {curr.key === "communityId" ? (
                                <TruncatedText
                                    text={`${item.name} (${item.id})`}
                                    max={30}
                                />
                            ) : period != null ? (
                                <RowCell period={period} item={item} />
                            ) : (
                                <Stack minWidth={64}> - </Stack>
                            )}
                        </Stack>
                    </NoWrap>
                )

                return acc
            }, {} as Record<string, string | ReactNode>),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            store.communityInsightsPagination,
            store.allCommunitiesInsights,
            getDynamicHeader,
        ],
    )

    // Generate a styled header
    const getStyledHeader = useMemo(() => {
        return getDynamicHeader.map(({ name, key }) => ({
            key: key,
            name: (
                <Stack
                    alignItems={key === "communityId" ? "flex-start" : "center"}
                    paddingLeft={key === "communityId" ? "16px" : 0}
                >
                    <Typography variant="body2" fontWeight="500">
                        {name}
                    </Typography>
                </Stack>
            ),
        }))
    }, [getDynamicHeader])

    return (
        <Table
            paginator={store.communityInsightsPagination}
            headers={getStyledHeader}
            emptyStateText={t`invoices-view.table.no-information-text`}
            rowKeyResolver={uniqueId}
            rowRenderer={getRowRenderer}
            tableContainerStyle={tableStyles(palette)}
        />
    )
})
