import { observer } from "mobx-react"

import { t } from "@lingui/macro"

import { Stack, useTheme } from "@mui/material"

import { useCallback, useEffect, useMemo } from "react"

import { endOfMonth } from "date-fns"

import { CommunityInsightsStore } from "./store"

import { StoreProvider } from "src/store/lib/StoreProvider"

import { DatePickerRangeField } from "src/components/DatePickerRangeField"
import { ListPage } from "src/components/ListPage"
import { IPageFilterProps } from "src/components/PageFilter"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { IDateRangeValue } from "src/types/date_range_picker"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { InsightsTable } from "src/views/community-insights/components/InsightsTable"
import { MultiselectAutocomplete } from "src/views/community-insights/components/MultiselectAutocomplete"
import { TopicSingleSelect } from "src/views/community-insights/components/TopicSingleSelect"

const LOADING_KEYS = [
    CommunityInsightsStore.CommunityInsightsKeys.loading,
    CommunityInsightsStore.CommunityInsightsKeys.init,
]

export const View = observer(() => {
    const store = useStore(CommunityInsightsStore)
    const gstore = useStore(GlobalStore)
    const theme = useTheme()

    const tableIsLoading = () =>
        LOADING_KEYS.some((key) => gstore.loading.is(key))

    const header = useMemo(
        () => ({
            header: t`community-view.header`,
            breadcrumbs: [
                t`community-view.header`,
                t`community-insights.community-insights-breadcrumb`,
            ],
        }),
        [],
    )

    useEffect(() => {
        return () => {
            store.dispose()
        }
    }, [store])

    useEffect(() => {
        ;(async () => {
            await store.init(gstore.session.accessGroupId)
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gstore.session.accessGroupId])

    useEffect(() => {
        ;(async () => {
            await store.loadPeriods()
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        store.selectedCommunitiesIds,
        store.dateRangeFilter,
        store.selectedTopic,
    ])

    const handleDateRangeChange = useCallback(
        (value: IDateRangeValue) => {
            value.from !== null &&
                trackModuleEvent("CommunityInsights | From date")
            value.to !== null && trackModuleEvent("CommunityInsights | To date")

            store.setDateRangeFilter(value)
        },
        [store],
    )

    const filter: IPageFilterProps = useMemo(
        () => ({
            actions: (
                <Stack>
                    <MultiselectAutocomplete />
                    <Stack height={21} />
                    <TopicSingleSelect />
                    <Stack height={21} />
                    <DatePickerRangeField
                        minDate={new Date(2015, 0, 0)}
                        maxDate={endOfMonth(new Date())}
                        views={["year", "month"]}
                        inputFormat={"yyyy-MM"}
                        values={store.dateRangeFilter}
                        onChange={handleDateRangeChange}
                        sx={{
                            width: {
                                xs: "100%",
                                sm: "260px",
                            },
                        }}
                        inputSx={{ background: theme.palette.common.white }}
                    />
                </Stack>
            ),
        }),
        [
            handleDateRangeChange,
            store.dateRangeFilter,
            theme.palette.common.white,
        ],
    )

    return (
        <ListPage header={header} filter={filter} loading={tableIsLoading()}>
            <InsightsTable />
        </ListPage>
    )
})

export const CommunityInsightsView = () => (
    <StoreProvider Store={CommunityInsightsStore}>
        <View />
    </StoreProvider>
)
