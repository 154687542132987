import { t } from "@lingui/macro"
import {
    FormControl,
    FormControlLabel,
    Stack,
    Switch,
    Typography,
} from "@mui/material"
import { observer } from "mobx-react"

import { useCallback } from "react"

import { EmbeddedIntegrationStore } from "src/modals/embedded-integration-v2/store"
import { useStore } from "src/store/lib/useStore"
import { formFields } from "src/modals/embedded-integration-v2/formFields"
import { ModalTextField } from "src/components/ModalTextField"
import { IEmbeddedIntegrationFormFields } from "src/modals/embedded-integration-v2/types/embeddedIntegrationFormFields"
import { AccessGroup } from "src/components/ModalAccessGroup/AccessGroup"

export const EmbeddedIntegrationSettingsSection = observer(() => {
    const store = useStore(EmbeddedIntegrationStore)
    const singleSignOn = store.fields.get("singleSignOn")
    const openExternally = store.fields.get("openExternally")
    const useSalesPage = store.fields.get("useSalesPage")

    const handleSingleSignOnChange = useCallback(
        (_: unknown, checked: boolean) => {
            store.fields.set("singleSignOn", checked)
        },
        [store.fields],
    )

    const handleOpenExternallyChange = useCallback(
        (_: unknown, checked: boolean) => {
            store.fields.set("openExternally", checked)
        },
        [store.fields],
    )
    const handleUseSalesPageChange = useCallback(
        (_: unknown, checked: boolean) => {
            store.fields.set("useSalesPage", checked)
        },
        [store.fields],
    )
    const onSelectionChange = (
        value: string,
        dropdownName: keyof IEmbeddedIntegrationFormFields,
    ) => store.fields.set(dropdownName, value)

    const handleTextInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        key: keyof IEmbeddedIntegrationFormFields,
    ) => {
        store.fields.set(key, event.target.value)
    }
    const renderTextInput = (
        label: string,
        key: keyof IEmbeddedIntegrationFormFields,
        rowLength: number,
        disabled?: boolean,
        maxLength?: number,
    ) => {
        return (
            <ModalTextField
                variant="default"
                label={label ?? ""}
                value={store.fields.get(key) as string | number}
                onChange={(event) => handleTextInputChange(event, key)}
                helperText={store.fields.error(key)}
                error={Boolean(store.fields.error(key))}
                maxLength={maxLength ?? 255}
                rows={rowLength}
                disabled={disabled}
            />
        )
    }

    return (
        <Stack spacing={2}>
            <AccessGroup
                accessGroupId={store.fields.get("accessGroupId") as number}
                errorMessage={store.fields.error("accessGroupId") ?? ""}
                disabled={store.isEditMode}
            />

            {renderTextInput(
                t`embed-integrations-modal.settings.internal-name`,
                "internalName",
                1,
                false,
                255,
            )}
            {renderTextInput(
                t`embed-integrations-modal.settings.reference-id`,
                "referenceId",
                1,
                false,
                50,
            )}
            <formFields.EmbeddedIntegrationDropdown
                optionArray={store.fields.get("partnerList") ?? []}
                selectedValue={store.fields.get("partner") ?? ""}
                onSelectionChange={(value) =>
                    onSelectionChange(value, "partner")
                }
                errorMessage={store.fields.error("partner") ?? ""}
                disabled={false}
                inputLabel={t`embed-integrations-modal.settings.dropdown-partner`}
                label={t`embed-integrations-modal.settings.dropdown-partner`}
            />
            <formFields.EmbeddedIntegrationDropdown
                optionArray={store.getTypesList()}
                selectedValue={store.fields.get("type") ?? ""}
                onSelectionChange={(value) => onSelectionChange(value, "type")}
                errorMessage={store.fields.error("type") ?? ""}
                disabled={false}
                inputLabel={t`embed-integrations-modal.settings.dropdown-type`}
                label={t`embed-integrations-modal.settings.dropdown-type`}
            />
            <Typography variant="body1">
                {t`embed-integrations-modal.settings.switch-header`}
            </Typography>
            <FormControl style={{ breakInside: "avoid" }}>
                <FormControlLabel
                    checked={Boolean(singleSignOn)}
                    onChange={handleSingleSignOnChange}
                    control={<Switch color="success" />}
                    label={t`embed-integrations-modal.settings.single-sign-on`}
                />
                <FormControlLabel
                    checked={Boolean(openExternally)}
                    onChange={handleOpenExternallyChange}
                    control={<Switch color="success" />}
                    label={t`embed-integrations-modal.settings.open-externally`}
                />
                <FormControlLabel
                    data-testid="switch-button-sales"
                    checked={Boolean(useSalesPage)}
                    onChange={handleUseSalesPageChange}
                    control={<Switch color="success" />}
                    label={t`embed-integrations-modal.settings.switch-sales-page`}
                />
            </FormControl>
            {renderTextInput(
                t`embed-integrations-modal.settings.entry-url`,
                "entryUrl",
                1,
                store.fields.data.selectedConnectorId !== undefined &&
                    store.fields.data.selectedConnectorId > 0,
                255,
            )}
            <formFields.EmbeddedIntegrationDropdown
                optionArray={store.fields.get("connectorList") ?? []}
                selectedValue={String(store.fields.get("selectedConnectorId"))}
                onSelectionChange={(value) => {
                    store.fields.clearError("entryUrl")
                    onSelectionChange(value, "selectedConnectorId")
                }}
                errorMessage={store.fields.error("selectedConnectorId") ?? ""}
                disabled={
                    store.fields.data.entryUrl !== undefined &&
                    store.fields.data.entryUrl.length > 0
                }
                inputLabel={t`embed-integrations-modal.settings.dropdown-connector`}
                label={t`embed-integrations-modal.settings.dropdown-connector`}
                isNoneOption={true}
            />
            {renderTextInput(
                t`embed-integrations-modal.settings.external-links-regex`,
                "externalLink",
                1,
                false,
                2000,
            )}
        </Stack>
    )
})
