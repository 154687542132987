/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { makeAutoObservable } from "mobx"
import { t } from "@lingui/macro"

import { FormFields } from "src/lib/form-fields"
import { chatbot_UpdateFeedbackRequest } from "src/api/models/chatbot_UpdateFeedbackRequest"
import { ChatbotAdminService } from "src/api/services/ChatbotAdminService"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { createLoadingKeys } from "src/lib/loading"
import { IChatbotFormFields } from "src/modals/chatbot/types/formFields"
import { chatbot_Tags } from "src/api"
import { getTagIds } from "src/modals/chatbot/helpers/getTagIdsFromTag"
import { reportError, reportUnhandledApiError } from "src/lib/report"
import {
    EMessageSender,
    IChatMessage,
} from "src/types/chat-message/chatMessage"

export class ChatbotDetailStore {
    static Context = React.createContext<ChatbotDetailStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "loading")

    messages: IChatMessage[] = []
    formFields = new FormFields<IChatbotFormFields>({})

    constructor() {
        makeAutoObservable(this)
    }

    //#region setters
    setMessages(messages: IChatMessage[]) {
        this.messages = messages
    }
    //#endregion

    //#region store operations
    @loads(() => ChatbotDetailStore.LoadingKeys.init)
    async init(conversationId: string) {
        await this.loadConversationDetails(conversationId)
        await this.loadMessages(conversationId)
    }

    loadMessages = async (conversationId: string) => {
        try {
            const messagesResponse =
                await ChatbotAdminService.getV1AdminChatbotConversationsMessages(
                    {
                        conversationId,
                    },
                )

            const newMessagesFormat = messagesResponse.map(
                (message): IChatMessage => {
                    const sentByUser = message?.role === "HUMAN"
                    return {
                        authorType: message?.role ?? "",
                        authorName: sentByUser
                            ? t`chatbot-view.modal.chat.tenant`
                            : t`chatbot-view.modal.chat.chatbot`,
                        createdAt: message?.created_at ?? "",
                        messageId: message?.message_id ?? "",
                        text: message?.message ?? "",
                        source: message?.sources ?? [],
                        messageSender: sentByUser
                            ? EMessageSender.User
                            : EMessageSender.Chatbot,
                        messageType: message?.message_type ?? undefined,
                        nodeInfo: message?.node_info ?? undefined,
                        sourceEval: message?.source_eval ?? undefined,
                    }
                },
            )

            this.setMessages(newMessagesFormat ?? [])
        } catch (error) {
            reportUnhandledApiError(error)
        }
    }

    loadConversationDetails = async (conversationId: string) => {
        try {
            const response =
                await ChatbotAdminService.getV1AdminChatbotConversations({
                    conversationId,
                })

            this.formFields.init({
                conversation_id: response.conversation_id ?? "",
                tenant_id: response.tenant_id ?? 0,
                tenant_feedback_reason: response.tenant_feedback?.reason ?? "",
                tenant_feedback_thumb_status:
                    response.tenant_feedback?.thumb_status ?? -1,
                admin_feedback_reason: response.admin_feedback?.reason ?? "",
                admin_feedback_thumb_status:
                    response.admin_feedback?.thumb_status ?? -1,
                impersonated_by: response.impersonated_by,
                tags: response.tags,
                created_at:
                    response.created_at !== undefined
                        ? new Date(response.created_at)
                        : undefined,
                resolution: response.resolution ?? "",
                ended_at: response.ended_at,
            })
        } catch (error) {
            reportError(
                t`chatbot-view.modal.error.conversations-messages-load`,
                error,
            )
        }
    }

    @loads(() => ChatbotDetailStore.LoadingKeys.loading)
    async updateFeedback(
        request: chatbot_UpdateFeedbackRequest,
        conversationId: string,
    ) {
        try {
            await ChatbotAdminService.postV1AdminChatbotConversationsUpdateFeedback(
                { request, conversationId },
            )
            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "chatbotConversation",
                    action: "update",
                },
            })
        } catch (error) {
            reportError(
                t`chatbot-view.modal.error.update-admin-feedback`,
                error,
            )
        }
    }

    @loads(() => ChatbotDetailStore.LoadingKeys.loading)
    async updateTag(tag_ids: chatbot_Tags, conversationId: string) {
        try {
            await ChatbotAdminService.putV1AdminChatbotConversationsTag({
                conversationId,
                request: {
                    tag_ids: getTagIds(tag_ids),
                },
            })
            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "chatbotConversation",
                    action: "update",
                },
            })
        } catch (error) {
            reportError(t`chatbot-view.modal.error.update-tags`, error)
        }
    }
    //#endregion

    //#region helpers
    @loads(() => ChatbotDetailStore.LoadingKeys.loading)
    async handleSubmit(conversationId: string) {
        const adminFeedbackThumbStatus = this.formFields.get(
            "admin_feedback_thumb_status",
        )
        const request: chatbot_UpdateFeedbackRequest = {
            reason: this.formFields.get("admin_feedback_reason"),
            thumb_status:
                adminFeedbackThumbStatus === -1
                    ? undefined
                    : (adminFeedbackThumbStatus as chatbot_UpdateFeedbackRequest["thumb_status"]),
        }

        await this.updateFeedback(request, conversationId)
        await this.handleTagsSubmit(conversationId)
    }

    async handleTagsSubmit(conversationId: string) {
        const tags = this.formFields.get("tags")
        const shouldUpdateTags = this.formFields.getIsFieldDirty("tags")

        if (tags !== undefined && shouldUpdateTags) {
            await this.updateTag(tags, conversationId)
        }
    }
    //#endregion
}
