import {
    Accordion,
    AccordionSummary,
    Box,
    IconButton,
    Typography,
    alpha,
    styled,
} from "@mui/material"

import { TAvyChatDirection } from "src/components/Chat/types/direction"

const DivisionLine = styled("div")(({ theme }) => ({
    height: 1,
    flex: 1,
    backgroundColor: theme.palette.grey[400],
}))

const Author = styled("span")(({ theme }) => ({
    fontWeight: 500,
    color: theme.palette.grey[900],
    fontSize: "14px",
    marginTop: 0,
}))

const MessageBubble = styled("div", {
    shouldForwardProp: (prop) =>
        prop !== "direction" && prop !== "backgroundColor",
})<{ direction: TAvyChatDirection; backgroundColor: string }>(
    ({ theme, direction, backgroundColor }) => ({
        background: backgroundColor,
        padding: "10px",
        borderRadius: direction === "left" ? "0 8px 8px 8px" : "8px 0 8px 8px",
        width: "auto",
        minWidth: 0,
        fontSize: "14px",
        color: theme.palette.grey[900],
        lineHeight: 1.43,
        letterSpacing: "0.17px",
    }),
)

const CreatedAt = styled("span")(({ theme }) => ({
    fontSize: "12px",
    color: alpha(theme.palette.common.black, 0.38),
    marginTop: 0,
}))

const IconContainer = styled("span")(() => ({
    paddingTop: "5px",
}))
const Separator = styled("div")(() => ({
    border: "1px solid #EFEFEF",
    marginTop: "10px",
}))

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
    minHeight: 20,
    maxHeight: 20,
    paddingTop: 4,
    marginRight: 3,
    flexDirection: "row",
    alignItems: "center",
}))

const StyledIconButton = styled(IconButton)(() => ({
    borderRadius: 15,
}))

const StyledAccordion = styled(Accordion, {
    shouldForwardProp: (prop) => prop !== "backgroundColor",
})<{ backgroundColor: string }>(({ backgroundColor }) => ({
    boxShadow: "none",
    border: 0,
    "&.Mui-expanded:before": {
        opacity: 1,
    },
    backgroundColor: backgroundColor,
}))

const AttachedImage = styled("img")({
    width: "176px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "4px",
    overflow: "hidden",
})

const AttachedVideo = styled("video")({
    width: "176px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "4px",
    overflow: "hidden",
})

const AttachmentsGrid = styled(Box, {
    shouldForwardProp: (prop) => prop !== "direction",
})<{ direction: "left" | "right" }>(({ direction }) => ({
    display: "flex",
    gap: "10px",
    flexWrap: "wrap",
    paddingTop: "4px",
    flexDirection: direction === "left" ? "row" : "row-reverse",
}))

const ItemContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: "100%",
    alignItems: "center",
}))

const Typography400 = styled(Typography)(() => ({
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
}))
const Typography500 = styled(Typography)(() => ({
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "500",
    color: "#333333",
}))

export {
    DivisionLine,
    Author,
    MessageBubble,
    CreatedAt,
    IconContainer,
    Separator,
    StyledAccordionSummary,
    StyledIconButton,
    StyledAccordion,
    AttachedImage,
    AttachedVideo,
    AttachmentsGrid,
    Typography400,
    Typography500,
    ItemContainer,
}
