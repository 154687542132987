import React from "react"

import {
    navigation_NavigationItemAdminListItem,
    NavigationAdminService,
} from "src/api"

import { createLoadingKeys } from "src/lib/loading"

import { DEFAULT_ACCESS_GROUP } from "src/config"
import { Channel } from "src/channel"

import { Pagination } from "src/lib/pagination"

import { loads } from "src/channel/utils"
import { INavigationItems } from "src/views/navigation-items/types"

import { IAdvanceQueryModel } from "src/types/data-grid-pro"

import { CustomDataHelper } from "src/api/_custom/services/DataHelper"

export class NavigationItemsStore implements IDisposable {
    static Context = React.createContext<NavigationItemsStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "loading", "getAssignees")

    _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    _segments: number[] = []

    private repositoryUpdatesListenerDisposer?: () => void

    navigationItems = new Pagination(
        async (query) => {
            const response =
                await NavigationAdminService.postV1AdminNavigationItemList({
                    request: {
                        page_number: query.page,
                        page_size: query.pageSize,
                        filter: query.advanceQuery,
                        ...CustomDataHelper.PrepareVisibilityData(this),
                    },
                })
            const items: INavigationItems[] =
                response?.navigation_items?.map(
                    (item: navigation_NavigationItemAdminListItem) => ({
                        id: item.navigation_item_id ?? 0,
                        navigation_item_id: item.navigation_item_id ?? 0,
                        icon: item.icon_url ?? "",
                        internal_name: item.internal_name ?? "",
                        entity: item.entity ?? "",
                        location: this.getVisibilityText(
                            item.show_in_main_navigation ?? false,
                            item.show_in_overview ?? false,
                        ),

                        priority_main_navigation:
                            item.priority_main_navigation ?? 0,
                        priority_overview: item.priority_overview ?? 0,
                        published_to:
                            item.segment_ids?.length != null
                                ? `${item.segment_ids?.length} segments`
                                : "",
                        segment_ids:
                            item.segment_ids?.length != null
                                ? item.segment_ids
                                : [],
                        segment_names:
                            item.segment_names?.length != null
                                ? item.segment_names
                                : [],
                        access_type: item?.access_type ?? "WRITE",
                    }),
                ) ?? []

            return {
                items,
                count: response.total_count ?? 0,
            }
        },
        { loadingKey: NavigationItemsStore.LoadingKeys.loading },
    )

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    get segments() {
        return this._segments
    }

    private setSegments(segments: number[]) {
        this._segments = segments
    }

    async loadSegments(segments: number[]) {
        this.setSegments(segments)
        await this.navigationItems.loadInitialPage()
    }

    private setAccessGroupId(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }

    @loads(() => NavigationItemsStore.LoadingKeys.init)
    async init(accessGroupId: number, advanceQuery?: IAdvanceQueryModel) {
        this.listenToMessageRepositoryUpdated()
        this.setAccessGroupId(accessGroupId)
        await this.navigationItems.loadInitialPage(advanceQuery)
    }

    async query(advanceQuery: IAdvanceQueryModel) {
        await this.navigationItems.loadAdvanceQuery(advanceQuery)
    }

    private listenToMessageRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "navigation-items"
                ) {
                    await this.navigationItems.reload()
                }
            },
        )
    }

    deleteItem = async (id: number) => {
        try {
            await NavigationAdminService.deleteV1AdminNavigationItem({
                navigationItemId: id,
            })
        } catch (error) {
            reportError("Failed to delete item")
        } finally {
            await this.navigationItems.reload()
        }
    }

    getVisibilityText(showInMainNavigation: boolean, showInOverview: boolean) {
        let result = []

        if (showInMainNavigation) {
            result.push("Navigation")
        }

        if (showInOverview) {
            result.push("Overview")
        }

        return result.join(", ")
    }
}
